@require 'includes/*'

.stickers-container
  display flex
  align-items flex-start
  column-gap 8px

.sticker
  border-radius 4px

  &__text
    transform none
    order 2
    position relative
    top 0px
    left auto
    padding 4px
    line-height 18.2px
    font-size 14px
    font-weight 700
    font-size 14px

    .sticker--pdp &
      padding 3px 8px

      @media M_and_up
        font-size 16px
        line-height 20.8px
        padding 6px 8px

    .sticker--layer &
      line-height 15.4px

  &--layer
    display inline-block
    @media M_and_up
      margin-bottom 14px

    @media up_to_M
      margin-top 5px

  &--pdp
    display inline-block

    @media M_and_up
      margin-bottom 8px

    .sticker--pdp &
      font-weight 700
      padding 3px 8px

      @media M_and_up
        font-size 16px
        line-height 20.8px
        padding 6px 8px

    .sticker--layer &
      line-height 15.4px

  &--layer
    display inline-block
    @media M_and_up
      margin-bottom 14px

    @media up_to_M
      margin-top 5px

  &--pdp
    display inline-block

    @media M_and_up
      margin-bottom 8px

  &--best
    background-color $witt-dark-blue
    color $witt-white

  &--webonly, &--new
    background-color #EFF0F0
    color $witt-black

  &--reduction
    color $witt-white
    background-color $witt-red2

    &.sticker--pdp
      @media M_and_up
        display none

    &--label
      display inline
      padding-right 3px
      margin 0