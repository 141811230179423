@require 'includes/*'

.installment
  &__container
    display flex
    justify-content center
    align-items flex-start

    &--mobile
      margin-bottom 16px
      grid-row 3

      @media M_and_up
        display none

  &__text
    font-size 15px
    
  &__logo
    margin 0 8px
    width 60px
    
    img
      width 100%
    
  &__info
    span
      font-weight bold
      background black
      color white
      border-radius 10px
      padding 0 6px
      
  &__rates
    font-size 14px
    margin-top 10px
    
    .summary-data__block
      border-bottom none
      margin-bottom 0px
      padding-bottom 0px