@require 'includes/*'
@require 'products-column-switch.styl'

.category
  &__options
    display flex
    position unset
    justify-content space-between
    @media up_to_M
      background-color $witt-white
      padding-top 15px
      padding-bottom 15px
      justify-content center

      &--sticky
        position fixed
        z-index 1000
        padding-top 15px
        padding-bottom 15px
        transition top 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)
        background-color rgba($witt-white, 0.5)

      .dropdown__title,
      .dropdown__title-opened
        text-transform uppercase

        @media up_to_M
          text-transform none

      &--top
        top 0
      &--with-searchheader
        top 105px
      &--with-header
        top 60px

    &__sorting-switch
      order 2
      align-self flex-start
      display flex
      margin-left 5px

    @media M_and_up
      margin-left 0
      margin-right 10px

  &__header
    margin 16px 0
    text-align center
    @media up_to_M
      height auto
    @media M_to_L
      margin-left -15px
      margin-right -15px
      padding-left 15px
      padding-right 15px
    @media L_and_up
      margin-left -30px
      margin-right -30px
      padding-left 30px
      padding-right 30px

    &--no-result
      height auto
      vertical-align baseline

    &__back
      display inline-block
      min-width 40px
      height 40px
      border-radius 50%
      background-color $secondary-navigation-pill-background
      background-image url(../img/app/rwd/arrow-left-333333.svg)
      background-position 13px 11px
      background-repeat no-repeat
      background-size 10px auto
      margin-right 16px
      transition all 0.1s
      vertical-align middle

      @media up_to_M
        min-width 24px
        height 24px
        background-size 7px auto
        background-position 7px 6px
        margin-right 8px

      &:hover
        background-color $witt-very-dark-grey
        background-image url(../img/app/rwd/arrow-left-FFFFFF.svg)

    &__headline
      text-align center


      h1
        font-size 28px
        font-weight 400
        line-height 38px
        color $witt-black
        display inline-block
        margin 0
        vertical-align middle
        @media up_to_M
          font-size 24px !important
          line-height 26.4px !important

        &.search
          color $witt-red
          font-weight 400

      &--no-result
        padding-bottom 20px

        h1
          font-size 18px
          line-height 24px
          color $witt-red
          font-weight 400
          margin-bottom 0
          vertical-align baseline

        h2
          font-size 16px
          line-height 20px
          color $witt-darked-grey
          font-weight 400
          margin-bottom 0
          vertical-align baseline

      &--mobile-left
        @media up_to_M
          text-align left
          display flex

    &__count
      display inline-block
      vertical-align bottom
      font-size 16px
      color $witt-black

    &--bottom
      position relative

  #productList
    margin-right -15px

    @media XL_and_up
      margin-right -30px

  .productList__article-teaser
    padding-right 15px
    padding-bottom 50px
    justify-self stretch
    @media L_and_up
      padding-right 30px

  .article
    width 100%
    vertical-align top
    margin-bottom 20px
    padding-right 15px
    position relative
    z-index 0

    @media L_and_up
      padding-right 30px

    &:hover
      .article__container
        border 1px solid $witt-lighter-grey

    &__grid
      &--no-products
        font-size 16px
        margin-top 0
        margin-bottom 1rem

      &__column
        flex 0 0 25%
        max-width 25%
        -webkit-box-flex 0
        position relative
        width 100%
        min-height 1px

        @media S_to_L
          flex 0 0 50%
          max-width 50%

        @media up_to_S
          flex 0 0 100%
          max-width 100%

      &__subnavigation
        flex 0 0 25%
        max-width 25%
        -webkit-box-flex 0
        position relative
        width 100%
        min-height 1px

        @media up_to_L
          display none

      &--double
        .article__grid__column
          flex 0 0 16.666666%
          max-width 16.666666%

          @media S_to_L
            flex 0 0 33.333333%
            max-width 33.333333%

          @media up_to_S
            flex 0 0 50%
            max-width 50%

        .article__grid__subnavigation
          flex 0 0 33.333333%
          max-width 33.333333%

        .article__picture
          @media up_to_M
            width 100% !important
            height 100% !important

            @supports not (aspect-ratio: 300/399)
              width 100% !important
              height 100% !important
              position relative
              padding-bottom 133.33%
              padding-top 0
              overflow hidden

          img
            @media up_to_M
              @supports not (aspect-ratio: 300/399)
                position absolute
                top 50%
                left 50%
                transform translateX(-50%) translateY(-50%)

        .article__picture-slider
          @media up_to_M
            @supports not (aspect-ratio: 300/399)
              position unset

        .article__info
          padding-left 0
          padding-right 0

          @media up_to_M
            width 100%

        .article__name
          width 100%

        .article__wishlist
          right 5px
          top 5px
          width 30px
          height 30px

        .article__stickers
          left 8px

        .article__colors-container
          order 4
          margin-top 12px
          position relative
          bottom unset

        .article__color
          &:nth-child(n+4)
            display none

        .article__colors__count
          .three-color-display
            display inline

          .six-color-display
            display none

        .article__button
          top auto
          bottom 0

          @media up_to_M
            top auto
            bottom 0

    &__container
      border 1px solid transparent
      transition all 0.2s

    &__picture
      position relative
      cursor pointer
      aspect-ratio 300/399
      height 100%
      width 100%
      overflow hidden
      display flex
      align-items center
      justify-content center

      @media up_to_M
        aspect-ratio unset

      img
        display inline-block
        max-width 100%
        max-height 100%
        position relative

    &__picture-slider
      position relative
      width 100%
      height 100%
      display flex
      align-items center
      justify-content center

      &__prev
        width 40px
        height 40px
        border none
        position absolute
        left 0
        top 50%
        margin-top -20px
        padding 0
        z-index 2
        cursor pointer
        background-color rgba(255, 255, 255, 0.7)
        background-image url(../img/app/rwd/arrow-left-big.png)
        background-position center center
        background-repeat no-repeat
        background-size 18px auto

        @media up_to_M
          display none

      &__next
        width 40px
        height 40px
        border none
        position absolute
        right 0
        top 50%
        margin-top -20px
        padding 0
        z-index 2
        cursor pointer
        background-color rgba(255, 255, 255, 0.7)
        background-image url(../img/app/rwd/arrow-right-big.png)
        background-position center center
        background-repeat no-repeat
        background-size 18px auto

        @media up_to_M
          display none

    &__stickers
      display flex
      position absolute
      top 8px
      left 8px

    &__wishlist
      position absolute
      right 8px
      top 8px
      width 32px
      height 32px
      background-image url(../img/app/rwd/icon-add-wishlist.svg)
      background-repeat no-repeat
      background-position center center
      background-size 100% auto
      cursor pointer

      &.active, &:hover
        background-image url(../img/app/rwd/icon-add-wishlist-active.svg)

    &__info
      padding 8px
      display flex
      justify-content space-between
      flex-wrap wrap
      position relative
      font-size 14px
      line-height 19px

      @media up_to_M
        flex-direction column
        justify-content flex-start
        width 100%

    &__colors-container
      display flex
      width 100%
      margin 2px 0

      @media up_to_M
        order 4
        margin-top 12px

    &__colors
      display flex

      &__count
        vertical-align top
        margin-top -1px
        display inline-block
        font-weight 700
        text-decoration underline

        .three-color-display
          display none

          @media up_to_M
            display inline

        .six-color-display
          @media up_to_M
            display none

        a
          color $witt-very-dark-grey

    &__color
      display inline-block
      vertical-align top
      margin-right 8px
      cursor pointer
      &:focus
        outline none

      @media up_to_M
        &:nth-child(n+4)
          display none

      &.active
        span
          &:after
            display block
            content ''
            border 1px solid $witt-very-dark-grey
            border-radius 50%
            width 16px
            height 16px
            margin-left -3px
            margin-top -3px

      span
        display inline-block
        width 12px
        height 12px
        border-radius 50%
        border 1px solid $witt-light-grey3

    &__name
      display flex
      width 100%
      font-weight 700

      a
        color $witt-very-dark-grey

    &__link
      width 100%
      text-overflow ellipsis
      white-space nowrap
      overflow hidden

    &__brand
      height 19px
      width 100%
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
      color $witt-very-dark-grey

    &__prices
      display flex
      align-items center
      justify-content flex-start
      flex-wrap wrap
      width 100%
      color $witt-very-dark-grey

      .witt-price__teaser
        margin-right 0

    &__oldprice
      text-decoration line-through
      margin-right 10px
      line-height 1em

    &__price
      line-height 1em

      &__nowrap
        display inline-block
        white-space nowrap

      &__amount
        font-weight 700
        margin 0 2px 0 0
        display inline-block

        &--with-reduction
          color $witt-red2
          margin-left 5px

      &_message
        padding-right 5px

        &:empty
          padding-right 0

      &__currency
        font-weight 700

    &__button
      position absolute
      top 8px
      right 8px

      @media up_to_M
        top auto
        bottom 0

  #pagingProducts
    max-width 100%
    flex 0 0 100%

  #paging
    background-image url(../img/app/rwd/chargement-en-cours.gif)
    background-size contain
    display block
    width 90px
    height 90px
    margin 20px auto

  .custom-dropdown
    font-size 12px
    text-align left
    margin-bottom inherit
    border-radius 6px
    border 1px solid $highlight-border

    @media M_and_up
      margin-bottom 2px
    .opener
      background-color $witt-white
      padding 10px
      border-radius 6px
      &.active
        border-radius 6px 6px 0 0
        background-color $witt-white
    ul
      width calc(100% + 2px)
      position absolute
      top 34px
      left -1px
      border-bottom 1px solid $highlight-border
      border-left 1px solid $highlight-border
      border-right 1px solid $highlight-border
      background-color $witt-white
      border-radius 0 0 6px 6px
      padding 0 10px

    li
      background-color transparent
      &:hover
        background-color transparent
        font-weight bold


  &__linkbox
    @media up_to_M
      margin-top 20px

  .article-color-slider
    position relative
    padding 0 10px
    width 136px
    @media L_and_up
      width 200px

    &__slick-prev
    &__slick-next
      content ''
      position absolute
      top 5px
      border none
      padding 0
      background-color transparent
    &__slick-prev
      left 0
      sprite($icon_arrow_left_grey_small)
    &__slick-next
      right 0
      sprite($icon_arrow_right_grey_small)

  &__show-more-articles
    flex-basis calc(100% - 15px)
    text-align center
    margin-bottom 30px

  &__remaining-articles
    font-size 16px
    color $witt-black

  &__remaining-articles-progress-bar
    width 100%
    height 4px
    background-color $highlight-border
    margin 20px auto 30px auto

    @media M_and_up
      width 400px

  &__remaining-articles-progress-bar-inner
    width 0
    height 4px
    background-color $witt-darked-grey
