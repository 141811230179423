@require '../includes/*'

#helline
  .loading-circle
    background-image url(../img/app/rwd/helline/spinner.gif)

  h1
    font-size 32px
    font-weight bold
    line-height 130%
    letter-spacing -2%

    @media (min-width: 768px)
      font-size 24px


  h2
    font-size 20px
    font-weight bold
    line-height 120%
    letter-spacing -1%


  h3
    font-size 20px
    font-weight bold
    line-height 120%
    letter-spacing 0
    @media (min-width: 768px)
      font-size 18px