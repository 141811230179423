@require 'includes/*'

.bread-crumb
  color $witt-darked-grey
  margin-top 16px

  a
    font-size 14px
    line-height 16px
    color $witt-darked-grey

    &.active
      color $witt-darked-grey

  ul
    display flex
    align-items center
    list-style-type none
    padding 0
    margin 0
    li
      font-size 14px
      line-height 16px
      display inline-block
      color $witt-darked-grey
      margin-right 5px
      &:last-child
        color $witt-very-dark-grey

  &__arrow
    width 24px
    height 24px
    background-image url(../img/app/rwd/slider-arrow-right666.svg)
    transform scale(0.67)