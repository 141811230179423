@require 'includes/*'

.witt-price
  text-align center
  margin-top 30px
  margin-bottom 30px
  
  &__top
    display flex
    align-items flex-end
    justify-content center
    width 100%
    
  &__reduction
    font-weight 700
    background-color $witt-sale-background
    color $witt-white
    border-radius 4px
    padding 3px 4px
    margin-right 10px
    font-size 14px
    line-height 18.2px

    @media M_and_up
      font-size 16px
      line-height 20.8px
      padding 6px 8px
    
  &__teaser
    font-size 14px
    font-weight 400
    line-height 1em
    margin-right 15px
    
    strike
      font-size 18px
    
  &__value
    color $witt-sale-background
    font-size 26px
    line-height 1em
    font-weight 700
  
    sup
      font-size 26px
      top auto
      
  &__eco-tax
    font-size 12px
    margin-top 10px
    
    &__total
      font-weight 700
