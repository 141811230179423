@require 'includes/*'

#contest
  position relative
  &__button
      text-align center

.contest__button
  position absolute
  left calc(50% - 58px)
  display block
  bottom 9%
  border-radius 6px
  @media screen and (max-width: $medium)
    bottom 5%

.contest__form
  background $witt-white
  padding-left 15px
  padding-right 15px
  text-align center
  position relative
  margin-left auto
  margin-right auto
  max-width 630px
  padding-top 10px
  padding-bottom 20px
  margin-bottom 20px

  @media screen and (min-width: $large+1)
    position absolute
    top 300px
    left calc(50% - 220px)
    width 450px
    background $witt-white
    padding-left 60px
    padding-right 60px
    padding-top 30px

  &__label
    top -5px!important

  &__confirm
    margin-top 20px
    @media screen and (min-width: $large)
      position absolute
      bottom -100px
      left calc(50% - 96px)

  &__newsletter
    text-align left
    padding-left 20px
    .input-field
      margin-top 20px

  .error
    right 0px