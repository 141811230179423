@require 'includes/*'

.custom-dropdown
  width 100%
  font-size 16px
  text-align center
  position relative
  margin-bottom 15px
  color $witt-black
  
  .opener, .singleModel, .singleVariation
    width 100%
    border none
    background-color $witt-lighter-grey
    padding 12px 0
    
    &.active
      background-color $highlight-border
      
      &:after
        transform rotate(180deg)
        
    .content-color
      position absolute
      left 15px
      top calc(50% - 12px)
      
      span
        display inline-block
        border 1px solid $highlight-border
        border-radius 50%
        height 24px
        width 24px

  &__error
    border 1px solid $witt-red

  .opener
    &:after
      position absolute
      right 17px
      top calc(50% - 3px)
      content ''
      sprite($icon_arrow_down_small)
      transition all 0.7s ease
      
  ul
    display none
    position absolute
    z-index 99
    width 100%
    margin 0
    padding 0
    list-style-type none
    
    li
      width 100%
      background-color $witt-lighter-grey
      padding 12px 0
      cursor pointer
      position relative

      &:hover
        background-color $highlight-border