@require 'includes/*'


.header-container
  background-color $witt-white
  transition top 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)
  &--invisible
    top 0px !important
    @media M_and_up
      top -225px !important
  &--sticky
    position -webkit-sticky
    position sticky
    top 0
    height 100%
    margin-left -15px
    margin-right -15px
    z-index 1000
    padding 0 15px
    background-color $witt-white
    @media M_and_up
      .navigation
        border-bottom 1px solid $highlight-border


  &__shadow--visible
    @media up_to_M
      &:after
        position absolute
        bottom 0
        left 0
        right 0
        height 5px
        content ""
        display block
        box-shadow 0 2px 5px 0 rgba(0,0,0,0.1)

.pageheader
  width 100%

  @media up_to_M
    height 105px
    margin-top 20px
    transition height 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)

  &.condensed
    @media up_to_M
      height auto
      padding 0
      margin 0

  &__small
    @media up_to_M
      height 61px

  &__metanav
    margin-bottom 23px
    padding-top 13px
    display block
    text-align right

    @media up_to_M
      display none

    &__list
      list-style-type none
      margin 0px
      padding 0px

      &-item
        inline-block()
        vertical-align middle
        margin-left 15px

        &first-child
          margin-left 0px

        &--contact
          sprite ($metanav_contact)
          margin-left 5px

  &__container
    margin-top 20px
    display flex
    align-items center
    justify-content space-between

    @media up_to_M
      margin-top 10px
      width 100%
      height 55px
      position absolute
      top -14px
      z-index 100
      background-color $witt-white

  &__search
    inline-block()
    vertical-align top
    z-index 50
    width 100%

    @media L_and_up
      width calc(100% - 560px)
      max-width 375px
    @media up_to_M
      width 100%
      position absolute
      left 0px
      top 20px
      margin 40px 0 0 0
      transition margin-top 0.25s cubic-bezier(0.47, 0, 0.75, 0.72)
    @media M_to_L
      width calc(100% - 380px)

      &--hidden
        margin-top -15px

    &-field
      width 100%
      inline-block()
      vertical-align top
      position relative

      .easy-autocomplete-container
        margin-top 0px

    &--text
      font-size 16px
      line-height 20.8px
      border 1px solid #BABEBF
      display block
      width 100%
      padding 4px 16px 4px 40px
      color $witt-black
      height 50px
      background $witt-white url(/img/app/rwd/icons/search.svg) 16px center no-repeat
      background-size 16px auto

      &::placeholder
        color $witt-black
      &::-webkit-input-placeholder
        color $witt-black
      &::-moz-placeholder
        color $witt-black
      &:-ms-input-placeholder
        color $witt-black
      &:-moz-placeholder
        color $witt-black

      &:hover
        border-color $witt-black

      &:focus
        border-color $witt-black
        outline none
        box-shadow none

      @media L_to_XL
        font-size 12px
        line-height inherit
        padding 4px 8px 4px 32px
        background-position 8px center

    .easy-autocomplete
      width 100% !important

      &-container
        z-index 10000
        position absolute
        width 100%

        ul
          background none repeat scroll 0 0 $witt-white
          display none
          margin-top 0
          padding-bottom 0
          padding-left 0
          position relative
          top -1px
          li
            &:last-child
              border-radius 0 0 2px 2px
              border-width 0 1px 1px
            &.selected
              background none repeat scroll 0 0 $witt-lighter-grey
              cursor pointer
              div
                font-weight normal
            div
              display block
              font-weight normal
              word-break break-all
            b
              font-weight bold
          .eac-category
            font-style italic

    .easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category
      background inherit
      border-color $highlight-border
      border-image none
      border-style solid
      border-width 0 1px
      display block
      font-size 14px
      font-weight normal
      padding 4px 12px

  .header-icon
    &-group
      display flex
      align-items flex-end
      justify-content space-between
      column-gap 16px
      margin-bottom 0px

      @media up_to_S
        column-gap 8px

      @media up_to_XS
        column-gap 4px

    &__search
      display none
      &--visible
        inline-block()
        margin-top 2px
        margin-right 17px
        @media up_to_S
          margin-right 0

  &.condensed
    .shoplogo
      margin 10px 0

.miniBasket-layer-container
  display none
  position absolute
  top 42px
  right 0px
  padding-top 20px
  &--visible
    display block

  @media screen and (max-width: $medium)
    display none

  .miniBasket-layer
    position relative
    z-index 100
    width 250px
    background-color $witt-white
    box-shadow 0 0 20px rgba(0, 0, 0, 0.2)
    padding-bottom 1px

    &:before
    &:after
      content ''
      width 0
      height 0
      position absolute
      right 15px

    &:before
      top -11px
      border-left 12px solid transparent
      border-right 12px solid transparent

    &:after
      top -10px
      border-left 12px solid transparent
      border-right 12px solid transparent
      border-bottom 10px solid $witt-white
      
    &__articles
      max-height 340px
      overflow-y auto

    &__article
      margin 20px
      height 128px
      border-bottom 1px solid $witt-light-grey

    &__image, &__details
      display inline-block
      vertical-align top

    &__image
      width 65px
      margin-right 10px

    &__details
      width calc(100% - 75px)

    img
      border 1px solid $witt-light-grey
      width 100%

    &__content-small
      font-weight bold
      padding-top 5px

    &__price
      padding 10px 20px 10px 20px
      background-color $witt-lighter-grey
      &--total
        color $moda-red
        font-size 18px
      &--note
        font-size 10px

    &__old-price
      margin-right 5px

    &__totaltext
      padding-right 10px

    &__btn
      margin 10px 20px 20px 20px
      
    .ecotax
      font-weight 400
      font-size 11px

#searchfield
  border-radius 8px

  &:focus::placeholder
    color transparent