@require '../includes/*'

.filter

  &__range-value
    width 80px
    height 56px
    text-align right
    border none
    // Firefox
    -moz-appearance textfield

    // Chrome
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance none
      margin 0

    @media up_to_M
      width 46px

  &__range
    @media L_and_up
      margin-bottom: -55px

    @media up_to_M
      display flex
      flex-wrap wrap
      justify-content center

    .filter__label
      margin 8px 0
      font-size 14px

    &__inner
      display flex
      font-size 16px
      margin-bottom 20px
      align-items center

      @media up_to_M
        justify-content center
        display grid
        grid-template-columns 1fr 1fr
        height 110px
        width: 100%;
        min-width: 165px;

      &__input-min
        @media up_to_M
          grid-column 1
          grid-row 1
          justify-self start

      &__input-max
        @media up_to_M
          grid-column 2
          grid-row 1
          justify-self end


      &__label-input-container
        display flex
        justify-content center
        align-items center
        border 1px solid #d9dadb//$witt-light-grey3

        @media up_to_M
          width calc(100% - 15px)
          max-width 100px

      &__label-input-container > label
        display inline-block
        position absolute
        top 20px
        margin-bottom .5rem
        padding: 2px 3px
        background white
        font-size 14px

        @media up_to_M
          top 3px

      &__input-currency
        font-weight 400
        margin 0 10px 0 2px

      &__slider
        width 300px
        @media up_to_M
          grid-area slide
          grid-column span 2
          grid-row 2
          width calc(100% - 25px)
