@require 'includes/*'

input[type=text].error-box
  border 1px solid $witt-red !important
  
input::-ms-reveal, input::-ms-clear
  display none

.input-material
  margin-bottom 0 !important

.material
  position relative
  margin-bottom 20px
  
  .select-wrapper
      span
        &.caret
          z-index 1
          right 5px
          font-size 8px
          
      .select-dropdown
        padding-top 0px !important
        padding-bottom 0px !important
  
  &--no-margin-bottom
    margin-bottom 0px
  
  &--margin-top
    margin-top 30px

  .input-field
    margin-bottom 0px
    margin-top 0px
    
    label
      color $witt-very-dark-grey !important
      font-size 14px !important
      margin-top 2px
      padding 2px 3px
      margin-left 15px
      background-color white
      height auto
      &.active
        transform scale(0.92) translateY(-13px) !important
        color $witt-black !important
      &.disabled
        color #9B9AAC !important
    textarea
        border 1px solid $witt-light-grey2 !important
        background-color white
        font-size 13px !important
        color $witt-black
        margin-bottom 0px !important
        box-shadow none !important
        padding 15px
        width calc(100% - 30px)
        
        &:focus
          border 1px solid $witt-black

    &.input-higher
      margin-top 2rem

  input[type=text],
  input[type=password],
  input[type=email],
  input[type=number]
    padding 15px !important
    font-size 13px !important
    height auto !important
    margin-bottom 0px !important
    box-sizing border-box !important
    border 1px solid $witt-light-grey2 !important
    box-shadow none !important
    background-color white !important
    color $witt-black

    &:disabled
      border 1px solid #9B9AAC !important
      color #9B9AAC !important
    
    &:focus
      border 1px solid $witt-black !important
    
    &.error
        border 1px solid $witt-red2 !important
        background-image url(/img/app/rwd/form-error.svg)
        background-size 16px auto
        background-repeat no-repeat
        background-position right 10px center
        padding-right 30px !important
        
      &.success
        background-image url(/img/app/rwd/form-success.svg)
        background-size 16px auto
        background-repeat no-repeat
        background-position right 10px center
        padding-right 30px !important

    &.select-dropdown
      font-size 14px !important
      width 100%
      overflow hidden
      padding-right 20px

    &::-webkit-input-placeholder
      color transparent

    &::-moz-placeholder
      color transparent

    &:-ms-input-placeholder
      color transparent

    &:-moz-placeholder
      color transparent

    &.active

      &::-webkit-input-placeholder
        color $witt-light-grey

      &::-moz-placeholder
        color $witt-light-grey

      &:-ms-input-placeholder
        color $witt-light-grey

      &:-moz-placeholder
        color $witt-light-grey

    &:not(.browser-default)

      &:disabled
        color rgba($witt-black, 0.6)

        &+label
          color rgba($witt-black, 0.6)

  input[type=number]
    &::-webkit-inner-spin-button, &::-webkit-inner-spin-button
      -webkit-appearance none
      opacity 0
      margin 0

  .radio, .checkbox
    inline-block()

  label

    span
      color $witt-red2
      font-size 14px

      &.bold
        font-weight 700

    &.static
      padding-left 6px
      margin-bottom 0px
      transform scale(0.94) translateY(0px) !important

  .error
    color $witt-red
    font-size 12px
    margin-top 5px

    span
      color $witt-red

  .error-message
    color $witt-red
      
  .togglePassword
    position absolute
    top 0px
    right 20px
    
    &__switch
      display block
      height 38px
      cursor pointer
      padding-top 16px
      padding-right 10px
      
      &.hidden
        display none

  .dropdown-content
    padding-left 0px
    margin-top 1px
    box-shadow 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)

    li
      font-size 14px
      line-height 14px
      min-height 1px

      &:hover
        background-color $witt-light-blue !important

        span
          color $witt-white

      span
        font-size 14px
        line-height 14px
        padding 10px 20px 10px 10px
        color $witt-black

      &.disabled
        background-color $witt-white !important
        border-bottom none

        &:hover
          background-color $witt-white !important

        span
          color $witt-light-grey

      &.active
        background-color $witt-light-blue !important

        span
          color $witt-white

  .radio
    margin-right 15px

    label
      &.dark
        color $witt-black
        
    input[type=radio]:not(:checked)+label:before
      border 2px solid $witt-very-dark-grey
        
  .checkbox
    margin 10px 20px 10px 0
    
    input[type="checkbox"]
      margin-right 0px
      
    label
      display inline

  input[type='radio']:checked + label:after,
  input[type='radio']:checked + label:after
    background-color transparent

  input[type='radio']:checked + label:before,
  input[type='radio']:checked + label:after
    border 6px solid $witt-black

  select, select:required
    display inline
    position absolute
    float left
    padding 0
    margin 0
    border 1px solid rgba(255, 255, 255, 0)
    height 0
    top 2em
    left -9999px
    opacity 0
    width 1px !important
    height 1px !important

.easy-autocomplete-container
  margin-top -8px
  position absolute
  width 100%
  background-color white
  z-index 999

  ul
    box-shadow 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)
    padding-left 0px
    margin 0px
    list-style-type none

    li
      font-size 14px
      line-height 14px
      min-height 1px

      &.selected
        background-color $witt-light-blue

      .eac-item
        padding 10px 20px 10px 10px
        color $witt-very-dark-grey
        
.easy-autocomplete
  max-width 100%

.autocomplete
  &--double-width
    .easy-autocomplete-container
      width calc(100% * 2 + 30px)
      
      @media screen and (max-width: $medium)
        width 100%
    
									
  //new styles
.material
  &__radio
    margin-right 15px
    inline-block()
    input[type=radio]+label:after
      width 13px
      height 13px

    input[type='radio']:checked + .material__label:after
      background-color $witt-reduction-red
      border 2px solid $witt-white
      border-radius 50%

    input[type='radio'] + .material__label:before
      border 2px solid #ccc !important
      border-radius 50%
      width 17px
      height 17px
      left -2px
      top -2px

    input[type='radio'] + .material__label
      &:before, &:after
        margin-top 6px

    &--medium
      .material__label
        padding-left 32px !important

      input[type='radio'] + .material__label:after
        width 16px
        height 16px

      input[type='radio'] + .material__label:before
        width 20px
        height 20px

      input[type='radio'] + .material__label
        &:before, &:after
          margin-top 4px

    &--large
      .material__label
        padding-left 35px !important

      input[type=radio] + .material__label:after
        width 21px
        height 21px

      input[type='radio'] + .material__label:before
        width 25px
        height 25px
        left -2px
        top -2px

      input[type='radio'] + .material__label
        &:before, &:after
          margin-top 2px


  &__label
    font-size 14px !important
    padding-left 10px
    margin-top -6px

    &--dark
      color $witt-black

    &:before
      color #ccc
      border 2px solid $highlight-border
      margin-top 4px

    span
      color $witt-black !important //delete when old red span deleted or in new styled css
      font-weight bold
      font-size 14px
      vertical-align top

    &.active
      color $witt-light-blue
      margin-top 0px
      transform scale(0.94) translateY(-14px) !important

    &.static
      padding-left 6px
      margin-bottom 0px
      transform scale(0.94) translateY(0px) !important