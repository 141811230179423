@require '../../styles/includes/*'

.cookie
  background-color $witt-cookie-background
  color $witt-black
  line-height 24px
  text-align center
  
  a
    color $witt-black
    
  .icon
    display inline-block
    width 24px
    height 24px
    vertical-align top
    sprite($header_close)