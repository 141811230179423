@require 'includes/*'

.shipping
  margin-bottom 60px

  @media screen and (max-width: $medium)
    margin-bottom 20px
    
  &__top
    display flex
    align-items center
    @media M_and_up
      margin-top 40px

  &__headline
    font-size 24px
    font-weight bold
    line-height 38px
    color $witt-very-dark-grey
    margin-left 16px

  &__subheadline
    margin 30px 0 8px 0

    @media up_to_M
      margin 24px 0 16px 0

  &__card
    box-shadow 0 8px 20px rgba(0, 0, 0, 0.2)
    background-color white

  .account__addresses__box__selection
    position absolute
    right 10px

  .account__addresses__box__selection input[type="radio"]
    opacity 0
    width 0
    height 0
    margin 0
    padding 0

  .account__addresses__box__selection input[type="radio"] + label
    width 18px
    height 18px
    border 1px solid #666
    display block
    cursor pointer

  .account__addresses__box__selection input[type="radio"]:checked + label
    background-color $witt-very-dark-grey

  .account__card
    box-shadow 0 8px 20px rgba(0,0,0,.2)
    background-color #fff

  &__detail
    &__head
      padding 20px
      
      &__inner
        display flex
        align-items center
        padding-bottom 20px
        border-bottom 1px solid $witt-light-grey
      
      &__icon
        width 40px
        height 40px
        background-size contain
        background-repeat no-repeat
        background-position center center
        margin-right 30px
        
        &--carrier
          background-image url(../img/app/rwd/icons/truck.svg)
          
        &--colissimo
          background-image url(../img/app/rwd/icon-shipping-colissimo.png)
        
      &__text
        font-size 14px
        flex-grow 1
        
        span
          display block
          font-weight 400
      
      &__price
        font-size 16px
        font-weight 700
        
    &__addresses
      padding 0 20px 20px 20px
      
      .shipping__block__hidden
        display block
        
      .shipping__address__button
        display none
        
    &__phone-selection
      background-color $witt-lighter-grey
      padding 20px 40px
      font-size 14px
      
      &__headline
        margin-bottom 20px
        
      &__radio
        &--inner
          width 30px
          display inline-block
          vertical-align top
          margin-top 23px
          margin-right 10px
          
          input
            &[type=radio]
              width 20px
              height 20px

        .material
          display inline-block
          width calc(100% - 40px)
          
      &__error
        display none
          
    &__submit
      padding 40px
      text-align center
    
    
  &__address
    
    &__button
      text-align center
      cursor default
      @media up_to_S
        width 100%

      &--relais
        font-size 16px
        line-height 20.8px
        margin-top 16px

    &__new
      display none
      padding-bottom 20px
      border-bottom 1px solid $highlight-border
      
      &__headline
        h3
          font-size 18px
          font-weight 700
          margin-bottom 20px
          
      &__button
        font-weight 700
        text-decoration underline
        cursor pointer
        margin-bottom 30px
        display inline-block
        
      &__form
        padding-bottom 40px
        display none

    &--border
      border-top 1px solid $highlight-border
      padding-top 20px

    &__premium-hint
      display none
      
  &__hint
      padding 15px
      background-color $witt-lighter-blue
      margin 30px 0
      font-size 14px
      display flex
      
      span
        font-weight 400
        
      &__icon
        background-image url(../img/app/rwd/icons/info.svg)
        background-size contain
        background-repeat no-repeat
        background-position center center
        width 40px
        height 40px
        margin-right 10px
        
      &__text
        &--bold
          font-weight 700

  &__block

    &__headline
      padding 10px
      width 100%
      background-color $witt-headline-background
      color $witt-darked-grey
      font-size 16px
      font-weight bold

    &__inner
      margin-bottom 15px
      background-color white
      cursor pointer
      box-shadow 0 8px 20px rgba(0, 0, 0, 0.2)

      &:first-child
        border-bottom 1px solid $witt-light-grey
        margin-bottom 0px
        position relative

      &:nth-child(2)
        margin-top -2px
        &.active
          margin-top 10px

      &.active
        border 2px solid $witt-very-dark-grey

        .shipping__block__hidden
          display block

      &__content
        padding 16px
        
        &__head
          display flex
          align-items flex-start
          justify-content flex-start
          column-gap 8px
          width 100%

        .radiobutton
          margin-right 0

      &__wrap-field
        display flex
        align-items center
        column-gap 10px

    &__hidden
      display none
      cursor default
      font-size 14px
      padding-top 16px

    &__price
      font-size 16px
      font-weight 700
      margin-left auto
      text-align right
      
      &__text
        font-size 12px
        font-weight 400
        line-height 1.2em
        margin-top 5px
        
      &--old
        display inline-block
        font-weight 400
        text-decoration line-through
        margin-right 20px

    &__title
      inline-block()
      vertical-align top
      font-size 16px

      @media screen and (max-width: $large)
        width 100%

    &__sans-supplement
      inline-block()
      vertical-align top
      width 155px
      height 31px
      background-image url(../img/app/rwd/sans-supplement.png)
      background-repeat no-repeat
      margin-left 12px

      @media screen and (max-width: $large)
        margin-left 0px
        height 27px
        width 100%
        background-size contain

    &__description
      font-size 14px
      margin-top 8px
      padding-left 28px

    &__postal-code
      margin-top 16px

      label
        margin 0 !important

      input
        height 36px
        margin 0 10px 0
        font-weight bold
        padding 0 10px
        width 100px
        vertical-align middle

        @media screen and (max-width: $large)
          width calc(100% - 84px)

      .btn
        margin 0 !important
        padding 6px 10px !important

        .icon
          margin-top 4px

      #btnPostalCodePointRelaisList
        margin-right 8px !important

    &__preselected-relais-wrapper
      border-top 1px solid $border-color-secondary
      margin-top 16px
      padding-top 16px
      cursor default

    &__open-panel
      reset-button-style()
      font-size 16px
      text-align left
      text-decoration underline

      &:hover, &:focus
        text-decoration none

      @media up_to_M
        margin-top 8px

  &__modal
    .shipping__hint
      margin-top 0px

  .icon
    &__arrow-left
      margin-right 20px
      margin-top 6px

  .btn-desktop

    @media screen and (max-width: $medium)
      display none

  .btn-mobile
    display none

    @media screen and (max-width: $medium)
      display block

  .btn-address
    text-align right

    .btn
      position absolute
      bottom 0px
      right 15px

    @media screen and (max-width: $medium)
      margin-top 15px

  &__colissimo-widget
    padding-top 0

    @media screen and (min-width: 999px)
      height calc(100% - 40px)

      .shipping__block__relais-result
        height 100%

  &__widget-error
    margin-top 16px
    background-color $color-info-background-warning
    font-size 14px
    line-height 18.2px
    padding 8px 16px
    display none

    .widget-loading-error &
      display flex

    @media up_to_S
      flex-direction column

    i.icon
      margin-left -2px
      @media S_and_up
        margin-left 0
        margin-right 4px
        flex 0 0 auto
        align-self flex-start

.btn-send
  margin 20px auto 0px auto
  display block

  @media screen and (max-width: $medium)
    width 100%

.widget-loading-error
  padding-top 0

  strong, .shipping__block__postal-code, .shipping__block__submit-relais
    display none

#relaisInformation
  border-top 1px solid $highlight-border
  margin-top 14px
  padding-top 14px

  .openingHours
    display: block

  p
    font-weight bold

#relaisList
  inline-block()
  vertical-align top
  width 50%

  @media screen and (max-width: $medium)
    width 100%

  ul
    border 1px solid $highlight-border
    height 290px
    list-style-type none
    padding 0px
    margin 0px
    overflow auto

    @media screen and (max-width: $medium)
      height auto

    li
      cursor pointer
      padding 5px 15px
      color $witt-darked-grey

      &:nth-child(odd)
        background-color $witt-lighter-grey

      &.selected
        background-color $witt-light-blue
        color $witt-white

#relaisDetails, .relaisDetails
  width calc(50% - 30px)
  inline-block()
  vertical-align top
  margin 35px 0 0 30px

  @media screen and (max-width: $medium)
    width 100%
    margin 20px 0 0 0
    font-size 12px

  span
    color $witt-red

#relaisInfo
  margin-top 8px

.relaisAddress
  color $witt-red
  font-weight bold
  margin-bottom 10px

  @media screen and (max-width: $medium)
    margin-bottom 20px

  span
    color $witt-black !important

.ouverture
  font-size 12px

  dt
    inline-block()
    vertical-align top
    font-weight normal
    width 80px

  dd
    inline-block()
    vertical-align top
    font-weight bold
    width calc(100% - 80px)

    @media screen and (max-width: $medium)
      font-weight normal

.relaisDetails, .relaisInfo
  display none

#MondialWidget
  border none

  .MR-Widget
    border none !important
    width 100%

  .MRW-Search
    @media screen and (max-width: $medium)
      display none

  .MRW-Map
    display block
    width 70%

    @media screen and (max-width: $medium)
      width 100% !important

  .MRW-RList
    font-size 12px
    width 40% !important

    @media screen and (max-width: $large)
      margin-top 20px

    @media screen and (max-width: $medium)
      width 100% !important

    .PR-List-Item
      margin-bottom 30px

    .PR-Name
      color $witt-red
      font-weight bold

.panelModalRight--relais

  .shipping__block__hidden
    display block

  .modal-body
    padding-top 0

  #ColissimoWidget
    @media screen and (min-width: 999px)
      height 100%

    @media M_and_up
      margin-left -40px
      margin-right -40px

    .shipping__widget-error
      @media M_and_up
        margin-left 40px
        margin-right 40px

    p
      color $helline-dark-blue !important

    .widget_colissimo_close
      display none !important

    .widget_colissimo_text_bouton, .widget_colissimo_text_normal_blanc
      color white !important

    .widget_colissimo_w100
      width 95%

      @media screen and (max-width: 999px)
        width 90%
        margin-left auto
        margin-right auto

    .widget_colissimo_w95
      @media screen and (max-width: 999px)
        width 95% !important

    table.widget_colissimo_table_adresse
      @media up_to_S
        margin 15px 15px 0
        width 100%

    .widget_colissimo_logo
      img
        width 120px

    #widget_colissimo_table_adresse_loop_click
      @media up_to_S
        width 60px

    .widget_colissimo_mode_transport
      cursor pointer

    .widget_colissimo_contenu
      width 100%
      @media screen and (min-width: 999px)
        height 100%

    .widget_colissimo_liste
      @media screen and (min-width: 999px)
        height calc(100% - 144px)

    .widget_colissimo_map
      @media screen and (min-width: 999px)
        width 60%
        height calc(100% - 88px)

      @media up_to_M
        min-width 100%

    .widget_colissimo_table_filtres
      width 100%

    .widget_colissimo_filtres, .widget_colissimo_liste
      @media screen and (min-width: 999px)
        width 40%
      @media up_to_M
        min-width 100%

    .widget_colissimo_autocomplete
      margin-top 7px
      @media screen and (min-width: 999px)
        position absolute
        left 220px
        top 63px
        margin-top 0
      @media screen and (max-width: 499px)
        margin-top -12px

    .widget_colissimo_icone_coche
      top 0

    .widget_colissimo_bouton_validation
      height 42px
      width 265px
      padding-left 43px !important
      padding-right 43px !important

    .widget_colissimo_map_liste, .widget_colissimo_map_icone
      position fixed
      right 30px
      left unset
      top unset
      bottom 10px

    .couleur1
      color #EE7F2E

    .police
      font-family $helline-font-primary

.shipping__relais
  background-color $helline-grey-light2
  border-radius 8px
  padding 16px
  display flex
  justify-content space-between
  column-gap 16px
  row-gap 16px

  @media up_to_M
    flex-direction column

  &-details
    flex 1 1 42%
    display flex
    flex-direction column
    justify-content space-between
    align-items flex-start

  &-address
    &__name
      font-size 16px
      font-weight 800
      line-height 20.8px

    &__address
      font-size 14px

  &-openingHours
    flex 0 1 58%
    background-color white
    padding 8px 16px 4px
    border-radius 8px
    line-height 18.2px
    font-size 14px

    @media up_to_S
      padding-left 8px
      padding-right 8px

    table
      width 100%
      max-width 400px

      td
        padding-bottom 4px

    &__day
      text-transform capitalize
      font-weight 800
      padding-right 12px
      @media up_to_S
        padding-right 6px

    &__hour
      padding-right 10px
      @media up_to_S
        padding-right 4px

  &-offending-days
    margin-top 8px
    background-color $color-info-background-error
    padding 8px 16px
    color #D60033
    font-size 14px
    line-height 18.2px
    text-align left
    display flex
    align-items center

    &__icon
      margin-right 4px
      flex 0 0 auto
      align-self flex-start
