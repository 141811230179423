@require 'includes/*'

.addToBasketLayer,.modifyBasketLayer,.gift-popup
  color $witt-black
  margin -1rem
  display flex

  &__modal
    width 100%
    max-width unset

    @media screen and (min-width: $medium)
      width 90%
      max-width 800px

    @media screen and (max-width: $medium)
      margin 0
      position fixed
      bottom 0

      .modal-content
        border-radius 0

    .product
      &__selection
        &__add-to-basket
          display flex !important
          align-items center
          justify-content center
          font-size 16px
          line-height 32px
          font-weight 700
          color #fff
          margin-top 10px
          padding 8px 12px
          border-radius 5px
          background-color #130103
          border 1px solid #130103
          cursor pointer
          width 100%

        &__buttons
          text-align center
          display block

          &--modify
            text-align center

            .btn
              background-color $witt-darked-grey3
              border-color $witt-darked-grey3
              color $witt-white

              &:hover
                color $witt-darked-grey3
                background-color $witt-beige
                border-color $witt-beige

              &:focus
                color $witt-darked-grey3
                background-color $witt-beige

              &:active
                color $witt-darked-grey3
                background-color $witt-beige


        &__witt-price
          margin: 0 0 16px 0;

  #main-images
    margin-top -56px
    margin-bottom -4.5px
    width 48%

    @media screen and (max-width: $medium)
      display none

  .main-picture
    aspect-ratio 3/4
    overflow hidden
    border none
    border-radius 3px
    display none

    &:first-child
      display: block;

    img
      object-fit cover
      width 100%
      height 100%

  .main-pictures
    height 100%

    img.productImage
      cursor default

    &__stickers
      display none
    &__zoom
      display none

    &__carousel
      height 100%
      //display flex
      //align-items center

      .slick-prev, .slick-next
        width 56px
        height 56px
        background-color rgba(255, 255, 255, 0.5)
        background-size: 20px auto;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        padding: 0;
        z-index: 1;
        cursor: pointer;

      .slick-prev
        left: 0;
        background-image  url(../img/app/rwd/slider-arrow-left.svg) !important;

      .slick-next
        right: 0
        background-image  url(../img/app/rwd/slider-arrow-right.svg) !important;

      .slick-list
        height 100%

      .slick-track
        height 100%

  &__headline
    font-size 35px
    line-height 38px
    margin-bottom 20px
    text-align center
    font-weight 700
    color $witt-darked-grey

    @media screen and (max-width: $medium)
      font-size 24px
      line-height 30px
      
  .product
    &__reassurance
      display none

    &__summary
      padding 0 24px 24px
      width 52%
      margin-top -20px

      @media screen and (max-width: $medium)
        width 100%
        margin-top -56px
        padding 16px 8px
        margin-bottom 0

      &__reference-reviews
        display flex
        justify-content space-between
        align-items center
        margin-bottom 6px

        @media up_to_S
          flex-direction column
          align-items flex-start
          row-gap 6px

        @media screen and (max-width: $medium)
          margin-bottom 8px

      &__mobile-image
        display none

        @media screen and (max-width: $medium)
          width 70px
          margin-right 16px
          display inline-block
          vertical-align top

      &__top-information
        @media screen and (max-width: $medium)
          width calc(100% - 115px)
          display inline-block
          vertical-align top

      .product__title
        @media screen and (max-width: $medium)
          margin-top 8px

    &__short-reviews
      margin-left 0
      @media screen and (min-width: $medium)
        .netreviews_review_rate_and_stars
          height 20px
          min-height 20px

      &__link
        display none

      //override skeepers styling
      .review__header
        margin 0
      .stars-cursor
        pointer-events none
      //END skeepers

      &__stars
        @media up_to_S
          margin 0

        .rating
          display flex
          width 65px
          height 13px
          position relative

        .rating progress.rating-bg
          -webkit-appearance none
          -moz-appearence none
          appearance none
          border none
          display inline-block
          height 12px
          width 100%
          color orange

        .rating progress.rating-bg::-webkit-progress-value
          background-color orange

        .rating progress.rating-bg::-moz-progress-bar
          background-color orange

        .rating svg
          position absolute
          top 0
          left 0
          width 100%
          height 100%

    &__title
      font-size 20px
      line-height 24px
      text-align left
      margin-bottom 8px
      font-weight 700
      @media screen and (max-width: $medium)
        font-size 16px
        line-height 19px
        margin-top 5px

    &__reference
      font-size 12px
      line-height 14px
      color $helline-grey
      margin-bottom 6px
      @media screen and (max-width: $medium)
        margin-bottom 0 !important

    &__link-description
      text-align left
      background-image url(../img/app/rwd/externalLinkArrow.svg)
      background-position right
      background-repeat no-repeat
      display inline-block
      margin-bottom 20px

      a
        color $helline-grey
        display block
        padding-right 20px
        text-decoration underline
        font-size 12px

        &:hover, &:active
          color $witt-very-dark-grey

    &__selection
      &__error-message, &__error-message--availability
        text-align center
        color #d30028
        font-size 14px
        margin-bottom 18px
        display none

      &__sizes-link
        display none

      &__label
        display none

    &__wishlist-add
      display none !important

  .custom-dropdown
    cursor pointer
    margin-bottom 16px
    color $witt-very-dark-grey

    ul
      max-height 120px
      overflow-y auto

    .available
      color $witt-green

    .notAvailable
      color $witt-orange

    .soldOut
      color $witt-red

    .material
      .select-wrapper
        background-color $witt-lighter-grey

        span.caret
          right 13px

      input[type=text]
        &.select-dropdown
          font-size 16px !important
          text-align center
          border none
          padding 24px 0

      .dropdown-content
        background-color $witt-lighter-grey

        li
          &.disabled
            background-color $witt-lighter-grey !important

            &:hover
              background-color $witt-lighter-grey !important

      .input-field
        &.required
          .select-wrapper
            input[type=text]
              border 1px solid $witt-red

  &__details
    font-size 14px
    padding 20px

    @media screen and (max-width: $medium)
      width 100%
      
  &__brand
    font-size 18px
    line-height 20px
    color $witt-darked-grey

  &__title
    font-weight 700
    font-size 18px
    line-height 20px
    margin-bottom 10px
    
  &__color
    display flex
    
    &__title
      flex-shrink 0
    
    .color-tile
      margin 0 6px
      
      span
        display inline-block
        border 1px solid $highlight-border
        border-radius 50%
        height 12px
        width 12px
    
  &__size
    margin-top 10px

  .witt-price__eco-tax
    display none

  &__action
    
    @media screen and (max-width: $medium)
      margin-bottom 10px
      padding-bottom 5px
      text-align center
      
    .btn
      font-weight bold
      padding 4px 3px
      margin 0 0 10px 0 !important
      
      @media screen and (max-width: $medium)
        width auto
        display inline-block !important
        float none
        margin-left auto
        margin-right auto
        padding-left 10px
        padding-right 10px
        
      &-ghost
        border none
        text-decoration underline
        font-weight 400

  &__button-right
    svg { color : "red"; }

  &__button-left
    @media screen and (min-width: $medium)
      padding-left 10px!important

  .product__additional-box
    margin-top 35px
    border none

  .product__additional-box__header
    background-color initial
    border none
    text-align center
    margin-top 0px
    margin-bottom 0
    padding-bottom 0

    @media screen and (max-width: $medium)
      margin-top 10px

  .color-selection
    text-align center
    width 100%
    margin 0
    height 100px

    &__inner
      height auto

    &__selected
      font-size 16px
      text-align center
      line-height 19px
      margin-bottom 32px
      height auto

    &__list
      padding 0 30px
      margin-bottom 8px

      &.nopad-center
        padding 0
        display flex
        justify-content center


      .owl-nav
        position absolute
        top 50%
        width calc(100% - 60px)
        margin-top -14px

        @media screen and (max-width: $medium)
          margin-top -10px

      .owl-prev
        position absolute
        left 0
        margin-left -30px
        display block
        width 16px
        height 16px
        background-color transparent
        background-image url(../img/app/rwd/slider-arrow-left.svg) !important
        background-repeat no-repeat !important
        background-position center center !important
        background-size 16px auto !important

        &.disabled
          background-image url(../img/app/rwd/slider-arrow-left-disabled.svg) !important

        span
          display none

      .owl-next
        position absolute
        right 0
        margin-right -30px
        display block
        width 16px
        height 16px
        background-color transparent
        background-image url(../img/app/rwd/slider-arrow-right.svg) !important
        background-repeat no-repeat !important
        background-position center center !important
        background-size 16px auto !important

        &.disabled
          background-image url(../img/app/rwd/slider-arrow-right-disabled.svg) !important

        span
          display none

    &__item
      position relative
      display inline-block
      cursor pointer
      padding 5px
      border 1px solid transparent
      transition all 0.1s ease-in-out
      border-radius 50%

      &.selected
        border-color $witt-very-dark-grey

        .color-tile
          > span
            transform scale(1.167)

      &:hover
        @media screen and (min-width: $xlarge)
          border-color $witt-very-dark-grey

        .color-tile
          > span
            @media screen and (min-width: $xlarge)
              transform scale(1.167)

    .color-tile
      > span
        display block
        border 1px solid $highlight-border
        border-radius 50%
        height 24px
        width 24px
        transition all 0.1s ease-in-out

        @media screen and (max-width: $medium)
          height 22px
          width 22px
