@require 'includes/*'

.panelModalRight--title
  text-align left !important
  color $witt-black
  font-weight bold

.right-panel-ATB-info
  display grid
  margin-bottom 150px
  width 100%
  grid-template-columns 1fr
  @media screen and (max-width: $medium)
    margin-bottom 0

  &__product-attributes
    display flex

  &__title
    width 62px

  &__attribute
    font-weight bold

  &__infobox
    display flex
    align-items center
    background-color $helline-green-background
    padding 8px 16px
    margin-bottom 24px
    grid-row 1

    @media screen and (max-width: $medium)
      margin-bottom 0

    &--icon
      margin-right 8px

  &__product-info
    display flex
    margin-bottom 24px
    grid-row 2
    @media screen and (max-width: $medium)
      display none

  &__image
    width 100px
    margin-right 28px

  &__content
    display flex
    flex-direction column
    justify-content space-between

    &--name
      font-weight bold
      font-size 20px
      line-height 26px
      margin-bottom 16px

    &--features
      font-size 14px
      line-height 18.2px

  &__slider
    background-color $helline-grey-light2
    margin-left -40px
    position relative
    grid-row 3
    width calc(100% + 80px);
    overflow auto
    @media screen and (max-width: $medium)
      grid-row 4

      .owl-nav
        display flex
        width 100%
        left 0

    .recommendations__headline
      font-size 18px !important
      line-height 21.6px !important
      margin-bottom 16px !important
      padding-top 24px

    .recommendations
      width calc(100% - 40px)
      left 20px
      position relative

  &__buttons
    position fixed
    bottom 0
    width calc(100% - 2px)
    display revert
    background-color: white
    padding 0 40px 16px 40px
    left 1px
    z-index 20
    grid-row 4
    @media screen and (max-width: $medium)
      grid-row 3
      position relative
      padding 0 0 16px 0

    &--view-basket-button
      margin-top 16px !important

    &--close-layer-button
      margin-top 8px !important
      @media screen and (max-width: $medium)
        margin-top 16px !important


  &__buttons::before
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    @media screen and (max-width: $medium)
      display none
      background: none

.disableScrollOnShopPage
  overflow hidden
