@require 'includes/*'

.payment
  margin-bottom 60px

  .left-column
    padding-left 12px !important
    padding-right 12px !important

  &__tab_ingenico_infotext
    font-size 14px
    margin-left 48px
    max-width 504px
    color #130103
    @media up_to_M
      margin-left 5px
      grid-row 3
      margin-bottom 24px

  @media screen and (max-width: $medium)
    margin-bottom 20px

  &__top
    display flex
    align-items center
    @media M_and_up
      margin-top 40px

  &__headline
    font-size 24px
    line-height 29px
    color $witt-very-dark-grey
    margin-left 8px
    @media M_and_up
      margin-left 16px
      font-weight bold

    @media up_to_M
      margin-left 8px


  &__subheadline
    font-size 26px
    line-height 26px
    margin 30px 0 10px 0
    width 100%

  &__errormessage
    margin-bottom 24px
    margin-top 24px
    display none
    font-weight bold

  &__legal-text
    font-size 14px !important
    line-height 20px
    margin-bottom 20px
    margin-top 10px
    color $witt-darked-grey
    a
      color $witt-darked-grey
      text-decoration underline

  &__content
    padding-bottom 60px
    margin-top 16px

  &__card
    box-shadow 0 8px 20px rgba(0, 0, 0, 0.2)
    background-color white

  &__tab
    margin-bottom 11px
    width 100%
    background-color $witt-white
    border: 1px solid #fff
    transition border 0.5s
    display block
    box-shadow 0 0 8px 0 rgba(18, 16, 55, 0.24)
    padding 16px

    @media up_to_M
      padding 0

    &:hover
      border 1px solid #333333

    &.active
      border 1px solid #333333

      .authError
        font-weight bold

    &.disabled
      opacity 0.5
      pointer-events none
      cursor default

    &__slide-open_area
      display flex
      align-items center
      cursor pointer

    &__headline
      width 100%
      padding-bottom 4px
      display flex
      align-items center
      flex-wrap wrap
      justify-content space-between

      @media screen and (max-width: $medium)
        padding 16px 16px

      &--no-icon
        padding 26px 30px

        @media screen and (max-width: $small)
          padding 18px 12px

          @media screen and (max-width: $small)
            margin-right 116px

          @media screen and (max-width: $small)
            margin-right 110px

    &__icon
      flex-shrink 0
      background-size contain
      background-position center left
      background-repeat no-repeat
      display inline-block
      width 48px
      height 32px
      margin-left 2px
      margin-right 2px

      &__container
        flex-direction row

      &__container-V
        flex-direction column

      &__container, &__container-V
        margin-left 10px
        margin-top 0px
        display flex


        @media up_to_S
          margin-top 10px

        @media screen and (max-width: $small)
          margin-top 8px

    &__title
      inline-block()

      font-size 16px
      line-height 16px
      font-weight 700

      @media screen and (max-width: $medium)
        line-height 18px

      @media up_to_M
        flex-grow 1

    &__gratuit, &__gratuit-M
      .label
        width fit-content
        background-color #E8E7F8
        padding 4px
        border-radius 4px

        display flex
        justify-content center
        align-items center

        color #121037
        text-align center
        font-family Mulish
        font-size 14px
        font-style normal
        font-weight 700

    &__gratuit
      flex-grow 1
      margin-left 12px
      .label
        @media up_to_M
          display none

    &__gratuit-M
      display inline-flex
      margin-top 8px
      @media M_and_up
        display none



    &__subtitle
      color $witt-darked-grey
      font-weight 400
      font-size 14px
      margin-bottom 10px

    &__content
      display none

      @media screen and (max-width: $medium)
        padding 0 10px

      &__headline
        font-size 16px
        color $witt-darked-grey

      &__subheadline
        font-size 10px
        vertical-align bottom
        margin-bottom 24px

      &__container
        display grid

      &__link
        font-size 16px
        margin-bottom 20px

        a
          color $witt-light-grey

      &__checkbox
        inline-block()
        vertical-align top
        margin-right 10px
        margin-bottom 20px
        margin-top 3px
        padding 15px 22px
        background-color $witt-lighter-grey

      &__checkbox__label
        font-size 14px
        inline-block()
        vertical-align middle
        width calc(100% - 72px)

        @media screen and (min-width: $medium)
          margin-top 5px

        @media screen and (min-width: $large)
          margin-top 15px

        @media screen and (max-width: $large)
          width calc(100% - 72px)

      &__indication
        color $witt-red
        font-size 12px
        margin-bottom 20px

      &__button
        @media screen and (max-width: $medium)
          padding 0px 3px

          .btn
            width 100%

      &__info
        font-size 14px
        color $witt-darked-grey
        margin 15px 0 10px 0

        .padding-top
          padding-top 30px
      .error
        margin -10px 0 10px 70px
      &__selection
        margin 0 16px 0 48px

        @media screen and (max-width: $medium)
          margin 0 0 10px 0

        &__headline
          font-weight 700
          margin-bottom 10px
          font-size 14px

        &__entries
          display flex
          align-items center

          @media screen and (max-width: $large)
            flex-wrap wrap

        &__entry
          margin-right 25px

          @media screen and (max-width: $large)
            margin-right 15px

          &:last-child
            @media screen and (max-width: $large)
              border-bottom none

          label
            height 30px
            cursor pointer

            @media screen and (max-width: $small)
              margin-bottom 0px
              padding 0 0 5px 0
              height 24px

            img
              height 100%
              width auto
              margin-left 10px

              @media screen and (max-width: $small)
                margin-left 5px

          input[type=radio]
            cursor pointer

    &__card-type
      height 50px

      &--info
        inline-block()
        margin-right 25px
        padding-left 10px
        font-size 14px

        @media screen and (max-width: $small)
          margin-bottom 5px
          padding 0

      &--icons
        inline-block()

        .selected
          border 2px solid $witt-black

        span
          inline-block()
          border 2px solid transparent
          margin-right 14px
          padding 2px

          img
            height 40px
            cursor pointer

  &__submit
    margin-bottom 16px
    margin-left 0
    margin-right 0

    @media M_and_up
      margin 16px 0 0
      margin-left 48px
      margin-right 16px



  .summary-data

    &__big-headline
      font-size 20px
      font-weight bold

    &__block
      padding-bottom 20px
      border-bottom 1px solid $witt-light-grey

      &--no-border
        margin-bottom 0px
        border none

    &__headline
      color $witt-darked-grey

    &__headline-small
      font-size 14px
      font-weight bold
      color $witt-darked-grey

    &__content
      font-size 14px

    &__content-small
      font-size 12px

    &__open-link
      font-size 14px
      text-decoration underline
      margin-bottom 10px
      cursor pointer

      &__arrow
        inline-block()
        background-image url(../img/app/rwd/icon-arrow-right-small.png)
        width 6px
        height 12px
        margin-left 10px
        transform rotate(90deg)

      &.active
        .summary-data__open-link__arrow
          transform rotate(270deg)

    &__articles
      margin-top 10px

  &__icon

    &--carte-bancaire-set1
      background-image url(../img/app/rwd/checkout-payment-icons/bleu_32px.svg)


    &--carte-bancaire-set2
      background-image url(../img/app/rwd/checkout-payment-icons/visa_32px.svg)


    &--carte-bancaire-set3
      background-image url(../img/app/rwd/checkout-payment-icons/master.svg)


    &--carte-bancaire-set4
      background-image url(../img/app/rwd/checkout-payment-icons/maestro.svg)


    &--carte-bancaire
      background-image url(../img/app/rwd/payment-cb.png)

    &--carte-4-etoiles
      background-image url(../img/app/rwd/checkout-payment-icons/moyen_paiement-4.svg)


    &--paypal
      background-image url(../img/app/rwd/checkout-payment-icons/moyen_paiement-paypal.svg)


    &--3xcofidis
      background-image url(../img/app/rwd/checkout-payment-icons/moyen_paiement-cb.svg)


    &--cofidis-dfpay
      background-image url(../img/app/rwd/checkout-payment-icons/moyen_paiement-confdis.svg)

    &--lock
      sprite($carte_bancaire_lock_icon)


  .icon
    &__arrow-left
      margin-right 20px
      margin-top 6px

  .btn-desktop
    margin-top 10px

    @media screen and (max-width: $medium)
      display none

  .btn-mobile
    display none

    @media screen and (max-width: $medium)
      display block

  #paymentFrame
    height 635px


  .btn-primary-checkout
    background-color #be2766
    border-color #be2766
    color white
    display flex
    align-items center
    justify-content center
    max-width 316px
    @media up_to_M
      max-width 100%

    &:hover
      background-color white
      .btn-primary-checkout__label
        color #be2766

      .btn-primary-checkout__icon
        background-image: url(../img/app/rwd/checkout-payment-icons/security_24px_primary.svg);

    &__icon
      background-image url(../img/app/rwd/checkout-payment-icons/security_24px_white.svg)
      background-size 24px 24px
      background-position center
      background-repeat no-repeat
      width 24px
      height 24px

    &__label
      font-size 16px
      color white
      margin-left 8px
      font-weight bold
    @media up_to_M
      width 100%

  .btn-primary-checkout-paypal
    @media up_to_M
      width 100%

    background: #FFC439
    border-color #FFC439
    color black
    @media M_and_up
      background-image url(../img/app/rwd/checkout-payment-icons/security_24px.svg)
      background-size 24px 24px
      background-position 10px center
      background-repeat no-repeat
      width fit-content
    &__label
      color black
      font-weight bold
      @media M_and_up
        margin-left 30px

  &__info_very_security
    display flex
    height 32px
    width fit-content
    align-items center
    margin-left 16px
    padding 8px
    border 0 solid
    border-radius 4px
    background-color #EAE8E1

    @media up_to_M
      margin-left 8px

    &__icon
      background-image url(../img/app/rwd/checkout-payment-icons/security_24px.svg)
      height 16px
      width 16px
      background-position center
      background-repeat no-repeat


    &__label
      margin-left 4px
      font-size 14px
      font-weight 700
      color #121037
      line-height 14px
