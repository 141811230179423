@require 'includes/*'
  
.general-slider
  @media screen and (min-width: $large) and (max-width: $xlarge)
    padding 0 20px

  .slick-list
    @media screen and (max-width: $large)
      padding 0 60px 0 0 !important

    .slick-track
      max-width: max-content;

  .slick-slide
    position relative
    margin 0 5px

    @media screen and (min-width: $xxlarge)
      width 178px !important

    @media screen and (max-width: $xxlarge)
      width 144px !important

    @media screen and (max-width: $large)
      width 131px !important

    @media screen and (max-width: $medium)
      width 148px !important

    @media screen and (max-width: $small)
      width 148px !important

    @media screen and (max-width: $supersmall)
      width 173px !important

  .slick-prev
    background-color transparent
    position absolute
    left 0px
    margin-left -30px
    top 0
    height 100%
    width 30px
    padding 0px
    z-index 99
    cursor pointer
    border none
    outline none
    
    &.slick-disabled
      display none !important
    
    &:before
      content ''
      display block
      position absolute
      margin-left -3px
      top 50%
      margin-top -15px
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/arrow-left-big.png)
      background-repeat no-repeat
      background-position center center
      background-size 26px auto
    
  .slick-next
    background-color transparent
    position absolute
    right 0px
    top 0
    height 100%
    width 30px
    margin-right -30px
    padding 0px
    z-index 99
    cursor pointer
    border none
    outline none
    
    &.slick-disabled
      display none !important
    
    &:before
      content ''
      display block
      position absolute
      margin-right -3px
      top 50%
      margin-top -15px
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/arrow-right-big.png)
      background-repeat no-repeat
      background-position center center
      background-size 26px auto
      
  &__product
    position relative
    text-align center
    color $witt-very-dark-grey
    font-size 14px
    line-height 19px

    &__picture
      position relative
      width 100%
      aspect-ratio 3 / 4
      overflow hidden

      img
        object-fit contain
        width 100%
        height 100%
        
    &__colors
      height 16px
      font-size 12px
      display inline-block
      bottom 0
      background-color $witt-white
      padding 1px

      &__count
        vertical-align top
        margin-top -1px
        margin-left 3px
        display none
        
        &:last-child
          display inline-block

    &__stickers
      display flex
      position absolute
      top 10px
      left 10px

    &__color
      display inline-block
      vertical-align top
      margin 0 2px
      cursor pointer
      &:focus
        outline none

      span
        display inline-block
        width 11px
        height 11px
        border-radius 50%
        border 1px solid $witt-lighter-grey

        &:hover
          border-color $highlight-border

    &__wishlist
      reset-button-style()
      position absolute
      right 10px
      top 10px
      width 32px
      height 32px
      background-image url(../img/app/rwd/icon-add-wishlist.svg)
      background-repeat no-repeat
      background-position center center
      background-size 100% auto
      
      &.active, &:hover
        background-image url(../img/app/rwd/icon-add-wishlist-active.svg)

    &__info
      padding 8px
      display flex
      justify-content space-between
      flex-wrap wrap

    &__left
      text-align left
      width calc(100% - 41px)

      &--full-width
        width 100%

    &__right
      width 36px
      flex-shrink 0

    &__name
      width 100%
      font-weight 700
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
      color $witt-darked-grey3
      margin-bottom 2px

      a
        color $witt-very-dark-grey

    &__brand
      margin-bottom 3px
      height 19px
      width 100%
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
      text-align left
      
    &__prices
      display flex
      align-items center
      justify-content flex-start
      flex-wrap wrap
      width 100%
      margin-bottom 2px

      .witt-price__teaser
        margin-right 0px;

    &__oldprice
      text-decoration line-through
      margin-right 10px
      line-height 1em

    &__price
      line-height 1em
      font-weight bold

      &--highlight
        font-size 14px
        font-weight bold
        color $witt-red2

    &__eco-tax
      flex-basis 100%
      font-size 12px
      line-height 15px
      color $witt-darked-grey
      margin-top 2px
      text-align left

    &__add-to-basket
      width 34px
      height 34px
      border-radius 50%
      background-color $witt-darked-grey3
      border 2px solid $witt-darked-grey3
      background-image url(../img/app/rwd/add-to-basket.svg)
      background-position center center
      background-repeat no-repeat
      cursor pointer

      &:hover
        background-color $witt-beige
        background-image url(../img/app/rwd/add-to-basket-active.svg)
        border-color $witt-beige

      &:focus
        background-color $witt-beige
        background-image url(../img/app/rwd/add-to-basket-active.svg)
        border-color $witt-darked-grey3

  .article
    &__reduction
      position absolute
      top 10px
      left 10px
      inline-block()
      color $witt-white
      font-size 17px
      background-color $witt-reduction-red
      width 60px
      height 60px
      margin-right 10px
      padding-top 8px
      text-align center
      border-radius 50%
      font-weight 400

      &--label
        font-size 10px
        margin 0

.additional-box
  &__header
    font-size 24px
    line-height 28px
    text-align center
    margin-bottom 24px
    margin-top 0
    font-weight 700
    background-color transparent
    border-bottom none
    padding 0
    color $witt-darked-grey

    @media screen and (max-width: $medium)
      font-size 16px
      line-height 20px
      font-weight 500
      margin-bottom 16px
      color #130102

    span
      font-weight normal

  &__content
    margin-bottom 24px
    @media screen and (max-width: $medium)
      margin-bottom 16px

.general-slider__carousel
  .owl-stage-outer
    z-index 2

  .owl-nav
    position absolute
    top 40%
    width calc(100% + 60px)
    display flex
    justify-content space-between
    left -30px

    @media screen and (max-width: $medium)
      display none

  .owl-prev, .owl-next
    width: 30px
    height: 30px
    background-repeat: no-repeat !important
    background-position: center center !important
    background-size: 26px auto
    background-image url(../img/app/rwd/slider-arrow-right.svg) !important
    transform scale(1.5)

    > span
      display: none

  .owl-prev
    transform scale(1.5) rotate(180deg)

  .owl-prev.disabled, .owl-next.disabled
    opacity  0
    pointer-events none
