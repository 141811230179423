@require 'includes/*'

.basket
  width 100%

  &__top
    display flex
    align-items center
    margin-top 40px
    margin-bottom 10px

    @media screen and (max-width: $medium)
      flex-wrap wrap

  &__advantages
    display flex
    padding 15px

    @media screen and (max-width: $medium)
      margin-top 20px
      flex-wrap wrap

    &__entry
      padding-right 25px
      padding-left 25px
      border-right 1px solid $witt-light-grey
      flex-grow 1
      display flex

      @media screen and (max-width: $medium)
        border none
        padding-left 0
        padding-right 0
        margin-bottom 5px
        width 100%

      &:first-child
        padding-left 0

      &:last-child
        padding-right 0
        border-right none

        @media screen and (max-width: $medium)
          margin-bottom 0

      &__icon
        width 20px
        height 20px
        background-size contain
        margin-right 10px

        @media screen and (max-width: $medium)
          width 15px
          height 15px

  &__headline
    font-size 28px
    line-height 38px
    color $witt-very-dark-grey
    margin-left 16px

  &__empty
    border-top 1px solid $witt-lighter-grey
    border-bottom 1px solid $witt-lighter-grey
    padding 40px 0
    text-align center

    &__icon
      display none

    &__headline
      font-size 26px
      font-weight 700

    &__cta
      padding 30px

      &__text
        font-size 18px
        line-height 22px
        font-weight 700
        margin-bottom 20px

  .line-items

    &__head
      border-bottom 1px solid $witt-light-grey

    &__headline
      font-size 20px
      font-weight bold
      color $witt-very-dark-grey
      padding-bottom 8px
      padding-left 10px

      span
        margin-left 5px

      &--no-border
        border none

      &--smaller
        font-size 14px
        line-height 22px
        display block
        border none
        padding-left 0
        margin 20px 0

      span
        font-size 16px
        line-height 16px
        font-weight normal
        color $witt-black

    &__legend
      color $witt-black
      font-size 12px

  .line-item
    position relative
    border-bottom 1px solid $witt-light-grey
    padding 30px 30px 30px 0

    &:after
      content ''
      display block
      width 1px
      height 1px
      clear both

    @media screen and (max-width: $medium)
      padding 20px 10px
      display flex
      flex-wrap wrap

    &:first-child
      border-top 1px solid $witt-light-grey

    &__picture
      margin-right 20px
      float left
      width 93px

      img
        width 93px
        height auto
        display block
        border 1px solid $witt-light-grey

        @media screen and (max-width: $medium)
          width 100%

    &__top
      float left
      width calc(100% - 93px - 20px)
      display flex
      justify-content space-between

    &__headline
      font-size 17px
      line-height 20px

    &__ref
      font-size 12px

    &__bottom
      float left
      width calc(100% - 93px - 20px)
      display flex
      justify-content space-between

      @media screen and (max-width: $medium)
        width 100%

    &__attributes
      font-size 12px
      line-height 1em

      &__attribute
        margin-bottom 2px
        display flex

        &-selected
          font-weight bold
          color $witt-black

      &__label
        width 60px

      &__value
        color $witt-black
        font-weight bold

      &__availibility
        &--green
          color $availability-green
        &--orange
          color $availability-orange
        &--red
          color $availability-red

    &__prices
      text-align right

    &__container
      position relative

      &__pricebox_desktop
        position absolute
        width 100%
        bottom 0

    &__row
      margin-top 10px
      width 100%
      height 95px

      @media screen and (max-width: $large)
        height 70px

      &--second
        width 100%
        height 30px

      &--labels
        display none
        text-align center

        .line-item__column--four
          text-align right
          padding-right 15px

    &__quantity
      font-weight 500

    &__modify, &__delete
      margin-right 24px

      @media screen and (max-width: $small)
        margin-right 15px

      a
        color $witt-darked-grey

        &:hover
          text-decoration underline

        &:before
          display inline-block
          vertical-align middle
          content ''
          width 18px
          height 18px
          background-image url(/img/app/rwd/icon-line-item-edit.svg)
          background-size contain
          background-repeat no-repeat
          margin-right 8px

    &__delete
      a
        color $witt-darked-grey

        &:hover
          text-decoration underline

        &:before
          display inline-block
          vertical-align middle
          content ''
          width 18px
          height 18px
          background-image url(/img/app/rwd/icon-line-item-delete.svg)
          background-size contain
          background-repeat no-repeat

    &__wishlist

      a
        color $witt-darked-grey

        &:hover
          text-decoration underline

        &:before
          display inline-block
          vertical-align middle
          content ''
          width 18px
          height 18px
          background-image url(/img/app/rwd/icon-add-wishlist.svg)
          background-size contain
          background-repeat no-repeat
          margin-right 8px

        &[data-remove-from-wishlist]
          &:before
            background-image url(/img/app/rwd/icon-add-wishlist-active.svg)

      &.active, &:hover
        a
          &:before
            background-image url(/img/app/rwd/icon-add-wishlist-active.svg)


    &__price
      &--reduction
        margin-bottom: 2px

    &__price-reduction
      display inline-block
      padding 4px
      background-color $witt-red
      margin-left 5px
      vertical-align middle
      font-size 12px
      line-height 12px
      color $witt-white
      flex-grow 0

      &--percent
        background-color $witt-red

      &--promotion
        background-color $witt-blue

    &__old-price
      font-size 14px
      line-height 14px
      font-weight 700
      color $witt-darked-grey
      text-decoration line-through
      display inline-block
      vertical-align middle

    &__unit-price
      font-size 12px
      color $witt-darked-grey
      font-weight 700

    &__price
      font-size 16px
      line-height 16px
      font-weight 700
      color $witt-sale-background
      text-transform uppercase
      text-align right

      @media screen and (max-width: $medium)
        font-size 14px
        line-height 14px

    &__eco-tax
      font-weight 700
      line-height 1.2em
      padding-top 2px

      @media screen and (max-width: $medium)
        padding-left 20px

    &__price-reduction
      display inline-block
      color $witt-white
      padding 4px
      font-weight 700
      background-color $witt-red
      font-size 12px
      line-height 12px

      &--percent
        background-color $witt-red

      &--promotion
        background-color $witt-dark-blue

      @media screen and (max-width: $medium)
        align-self flex-start

    &__avalibility
      &--mobile
        display none
        width 91px
        text-align center

        @media screen and (max-width: $large)
          display block

    &__duplicate-line-item
      display inline-block
      height 13px
      margin-left 4px
      vertical-align middle
      margin-top -2px
      cursor pointer

      img
        height 100%

    &__tax
      font-weight 700
      color $witt-mid-grey

    &__action
      display flex
      padding-top 8px
      width calc(100% - 113px)
      margin-left 113px

      @media screen and (max-width: $small)
        width 100%
        margin-left 0

  &__promo-code
    display flex
    justify-content center
    align-items center
    padding 20px
    background-color $witt-footer-background
    margin 20px 0
    flex-wrap wrap

    @media screen and (max-width: $medium)
      justify-content flex-start

    &__icon
      width 45px
      height 45px
      margin-right 15px
      background-image url(/img/app/rwd/icons/basket.svg)
      background-size contain
      background-position center center
      background-repeat no-repeat
      flex-shrink 0
      align-self center

    &__text
      font-size 14px
      margin-right 20px
      color $witt-very-dark-grey

    &__input
      width 240px

      form
        width 100%
        display flex

      @media screen and (max-width: $medium)
        margin-top 10px

    &__feedback
      width 100%
      display flex
      justify-content space-between
      padding 0 10px

    &__info
      display flex
      align-items center
      flex-grow 1
      flex-wrap wrap

      &__icon
        margin-right 10px
        width 24px
        height 24px
        background-image url(/img/app/rwd/check-circle.svg)
        background-size contain
        background-position center center
        background-repeat no-repeat
        flex-shrink 0
        align-self flex-start
        margin-top 3px

        @media screen and (max-width: $medium)
          flex-basis 24px

      &__text
        font-size 22px
        line-height 1.2em
        color $witt-very-dark-grey

        @media screen and (max-width: $medium)
          flex-basis calc(100% - 38px)

        span
          color $witt-red2
          font-weight 700

      &__description
        font-size 16px
        margin-top 5px
        font-weight 700
        color $witt-very-dark-grey
        flex-basis 100%

        @media screen and (max-width: $medium)
          margin-left 34px

      &__description
        width 100%

    &__delete
      width 18px
      height 18px
      flex-shrink 0

      a
        background-image url(/img/app/rwd/icon-line-item-delete.svg)
        background-size contain
        background-repeat no-repeat
        display block
        width 100%
        height 100%

  &__teaser
    @media screen and (max-width: $medium)
      margin-bottom 20px


  .direct-order
    background-color $witt-footer-background
    padding 10px 10px 20px 10px
    margin 10px 0 20px 0
    position relative

    @media screen and (max-width: $medium)
      background-color $witt-lighter-grey

    &__headline
      font-size 17px
      line-height 21px
      padding-right 25px
      font-weight bold
      color $witt-darked-grey

      &__inner
        inline-block()
        width calc(100% - 27px)
        vertical-align top
        margin-bottom 30px

    &__icon
      display inline-block


    &__model
      @media screen and (min-width: $medium)
        max-width 34%

    &__color
      @media screen and (min-width: $medium)
        max-width 20%

    &__size
      @media screen and (min-width: $medium)
        max-width 38%
        flex 0 0 28%

    &__quantity
      @media screen and (min-width: $medium)
        max-width 13%

    &__reference

      input
        padding 10px 12px
        border none
        width 100%
        background-color $witt-white

        @media screen and (max-width: $medium)
          border 1px solid $highlight-border
          padding 8px 12px 9px 12px

      label
        inline-block()
        font-size 14px
        line-height 14px
        color $witt-black
        margin-bottom 7px
        margin-top 20px
        margin-right 10px

        &.bold
          font-weight bold

          @media screen and (max-width: $medium)
            margin-top 0

      .message
        margin-top 10px

      &__input
        width 100%
        inline-block()

        @media screen and (max-width: $medium)
          width 100%

        input
          width 95px

          &::-webkit-input-placeholder
            font-style italic
            color $witt-light-grey

          &::-moz-placeholder
            font-style italic
            color $witt-light-grey

          &:-ms-input-placeholder
            font-style italic
            color $witt-light-grey

          &:-moz-placeholder
            font-style italic
            color $witt-light-grey

      &__text
        inline-block()
        margin-top 5px
        font-size 14px
        color $witt-darked-grey

        @media screen and (max-width: $medium)
          display none

    &__product

      &__name
        font-size 17px

        @media screen and (max-width: $medium)
          padding-top 30px
          font-size 14px

      &__data
        margin-top 20px
        width 100%

        @media screen and (max-width: $medium)
          display none

      &__details
        inline-block()
        vertical-align top
        width calc(100% - 115px)

      &__picture
        margin-right 20px
        inline-block()
        width 93px
        height 125px
        background-color $witt-white

        img
          max-width 100%
          max-height 100%
          width auto
          height auto
          margin 0 auto

      &__ref
        color $witt-darked-grey
        font-size 12px

      &__availibility
        margin-top 5px
        font-size 12px

      &__order
        height 125px
        margin-top 20px
        text-align center
        float right

        @media screen and (max-width: $large)
          margin-top 25px
          height auto
          float none

        &-inner-bottom
          display table-cell
          height 125px
          vertical-align bottom

          @media screen and (max-width: $large)
            display block
            text-align center
            height auto

      &__price
        font-size 21px
        line-height 21px
        font-weight bold
        color $witt-black
        margin-bottom 20px

        .ecotax
          font-size 12px
          font-weight 400
          color $witt-darked-grey
          margin-bottom 5px

    .icon__direct-order
      sprite($icon_direct_order)
      inline-block()
      vertical-align top
      margin-right 11px

    .line-item
      &__delete
        position absolute
        top 10px
        right 8px
        margin-right -3px

      &__attributes
        &__attribute
          &-selected
            padding-top 8px

    .material
      label
        font-size 12px !important
        padding-left 0

      .select-dropdown
        font-size 12px !important
        margin-left 0 !important
        padding-left 0 !important

      .dropdown-content
        top 32px !important

        li
          span
            font-size 12px !important

    &__model, &__size
      .material
        .dropdown-content
          min-width 260px

  .recommendations
    margin-bottom 30px

  .summary

    &__table
      width 100%
      border none
      margin 0 0 25px 0
      padding 0

      tr

        td
          padding 0 5px 20px 0
          vertical-align bottom

          &.right
            text-align right
            padding-right 0
            white-space nowrap

        td.summary__table-cell-premium
          padding-right 0

    &__total
      td
        color $witt-red
        font-size 24px
        line-height 24px
        padding-top 5px
        padding-bottom 0

        @media screen and (max-width: $large)
          font-size 13px

        &.right
          font-weight bold
          white-space nowrap

    &__savings
      margin-top -30px !important

    &__promocode

      form
        position relative

        .btn
          position absolute
          right 0
          top 0
          height 39px

        .error
          display block
          margin-top 15px

      &__headline
        font-weight bold
        padding-right 40px
        margin-bottom 18px

      &__code
        inline-block()
        background-color $witt-blue
        color $witt-white
        padding 12px 8px
        font-weight normal
        margin-left 10px

      &.error
        display block
        padding-top 10px

      &__info
        font-size 12px
        margin-top 15px

      &--active
        background-color $witt-white
        padding 0 0 20px 0
        position relative

      &__delete
        position absolute
        top 8px
        right 15px

      &__input
        width calc(100% - 145px)

        padding 10px 12px
        border 1px solid $highlight-border

        &:focus::placeholder
          color transparent


    .question
      border none
      background-color transparent
      inline-block()
      sprite($icon_question)
      margin-left 8px
      cursor pointer

      &:focus
        outline none

  &__contact-info
    background-color $witt-lighter-grey
    padding 8px 16px
    font-size 14px

    &__container
      padding 0 20px
      position relative
      margin-bottom 20px

      @media screen and (max-width: $medium)
        padding 0 10px

    &__headline
      font-weight bold
      font-size 16px

    &__phone
      margin-top 10px

    &__phone-icon
      display inline-block
      vertical-align top
      width 28px
      height 28px
      margin-right 10px
      background-position center center
      background-size contain
      background-image url(../img/app/rwd/icon-phone.png)

    &__phone-text
      display inline-block
      vertical-align top
      span
        display block
        font-size 12px

    &__phone-image
      background-image url(/img/app/rwd/sprite.png)
      background-position 0 -25px
      width 189px
      height 24px
      display block

    &__mail
      margin-top 10px
      vertical-align top

    &__mail-icon
      display inline-block
      vertical-align top
      width 22px
      height 22px
      margin-right 10px
      background-position center center
      background-size contain
      background-image url(../img/app/rwd/icon-envelope.png)

    &__mail-text
      display inline-block

  &__buttons
    @media screen and (max-width: $medium)
      padding 0 10px

    &--mobile
      position fixed
      bottom 0
      left 0
      display none
      width 100%
      z-index 9999

      @media screen and (max-width: $medium)
        display block

  .btn__checkout

    .icon
      margin-top 11px
      margin-left 20px

  .btn__continue
    .icon
      margin-right 20px
      margin-top 8px

  &__delete-confirm
    position absolute
    top 0
    bottom 0
    left 0
    right 0
    background-color $witt-white
    z-index 1
    display none

    &__popup
      padding 30px 40px
      font-size 18px
      text-align center

      &__text
        font-weight 700

      &__buttons
        margin-top 20px

      &__link
        display inline-block
        padding 12px 25px
        margin 0 10px 20px
        background-color $witt-red
        border 1px solid $witt-red
        color $witt-white
        cursor pointer

      &__close
        display inline-block
        padding 12px 25px
        margin 0 10px 20px
        border 1px solid $witt-darked-grey
        cursor pointer

  &__wishlist-confirm
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba($witt-black, 0.4)
    z-index 999

    &__popup
      position absolute
      top 100px
      right 100px
      padding 30px
      background-color $witt-white
      z-index 9999
      box-shadow 0 10px 40px rgba($witt-black, 0.3)
      font-size 18px

      @media screen and (max-width: $medium)
        right 20px
        left 20px
        width calc(100% - 40px)

      &__link
        text-decoration underline
        cursor pointer

      &__close
        width 15px
        height 15px
        cursor pointer
        background-image url(../img/app/rwd/icon-close-big.png)
        background-size contain
        background-position center center
        background-repeat no-repeat
        position absolute
        top 10px
        right 10px

  &__installment
    margin-top 20px

.gifts

  @media screen and (max-width: $medium)
    border-right none
    border-left none

  &__headline
    margin 20px 0 10px 0
    font-size 18px
    color $witt-red
    font-weight bold

.gift-container
  border 1px solid $witt-light-grey
  padding 0 10px 0 10px
  margin-top 20px

.gift
  width 100%
  position relative
  padding 20px 0 30px 0
  border-bottom 1px solid $witt-light-grey

  &:last-child
    border none

  @media screen and (max-width: $medium)
    padding-bottom 25px

  .bonus-line-item__picture-and-price, .legacy-logic-gift-items__picture-and-price
    display flex
    justify-content space-between
    width 100%

  .line-item
    &__delete
      @media screen and (max-width: $medium)
        margin-top 0

  &__headline
    font-size 17px
    line-height 19px
    color $witt-very-dark-grey
    font-weight bold
    margin-bottom 10px
    @media M_and_up
      margin-bottom 3px

    span
      font-weight normal
      color $witt-black

  &__attributes
    margin-top 10px

    .line-item
      &__description
        display none

        &__headline
          text-decoration underline
          cursor pointer

  .mobile-price
    position absolute
    bottom 0
    right 0

  &__picture
    inline-block()
    vertical-align top
    width 100%
    padding-right 20px
    padding-left 0

    img
      max-width 100%
      max-height 100%
      width auto
      height auto

  &__right-container
    text-align right

    @media screen and (min-width: $medium)
      padding-left 10px !important
      display flex
      align-items flex-end
      margin-bottom 15px

    .gift__addtocart
      width 100%
      display flex
      align-items center
      justify-content center
      height 46.85px

  &__addtocart
    margin-top 10px

  .line-item__row
    inline-block()

  &.popup

    .gifts__headline
      color $witt-black
      font-weight 400
      font-size 20px
      line-height 24px
      margin-top -20px
      padding 0 15px 15px 15px
      border-bottom 1px solid $witt-darked-grey

    .gift

      &__container
        inline-block()
        vertical-align top
        margin 0 15px 20px 15px
        width 27%

        @media screen and (max-width: $medium)
          width 100%
          display flex

        .gift__radio
          @media screen and (max-width: $medium)
            padding-top 40px
            order 1
            margin-right 20px

      &__picture
        display block
        vertical-align top
        width 93px
        height 125px
        margin-top 10px

        @media screen and (max-width: $medium)
          order 2

        img
          max-width 100%
          max-height 100%
          width auto
          height auto

        label
          margin 0
          width 100%
          max-width 70px
          height 100%
          cursor pointer

      &__radio
        margin-right 10px
        inline-block()
        vertical-align top

      &__attributes
        inline-block()
        vertical-align top
        width calc(100% - 30px)
        margin-top 0

        @media screen and (max-width: $medium)
          order 3

        select
          margin-left 5px

      &__headline
        font-size 13px
        padding 0
        line-height 15px
        font-weight 700

      &__price
        margin 5px 0
        font-style italic

      &__bottom
        display flex
        justify-content flex-end
        margin-bottom -40px

        @media screen and (max-width: $medium)
          flex-wrap wrap
          justify-content center

      &__no-selection
        margin-right 10px
        align-self center

        @media screen and (max-width: $medium)
          order 2
          width 100%
          margin-top 20px

      &__addtocart
        margin 0 !important

    span
      font-size 20px
      font-weight 400 !important
      color $witt-light-grey

