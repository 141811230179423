@require 'includes/*'

::-webkit-scrollbar
  height: 2px
  -moz-appearance:none;

::-webkit-scrollbar-track
  background-color #f1f1f1
  border-radius 5px

::-webkit-scrollbar-thumb
  background-color #888
  border-radius 5px

.category__sub-navigation
  &__pills
    margin-bottom  16px
    padding 0 30px
    display block

    @media up_to_M
      padding 0px

    &.nopad-center
      padding 0px

      .owl-stage-outer
        display flex
        justify-content center

    &__slider
      white-space nowrap

      @media up_to_S
        width 100%

      @media S_to_M
        width calc(100% - 30px)
        position relative
        left 0
        right 0
        margin auto

    .owl-nav
      position absolute
      top 0px
      width 100%

    .owl-prev
      position absolute
      left 0px
      margin-left -30px
      display block
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/slider-arrow-left.svg) !important
      background-repeat no-repeat !important
      background-position center center !important
      background-size 24px auto !important

      &.disabled
        background-image url(../img/app/rwd/slider-arrow-left-disabled.svg) !important

      span
        display none

    .owl-next
      position absolute
      right 0px
      margin-right -30px
      display block
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/slider-arrow-right.svg) !important
      background-repeat no-repeat !important
      background-position center center !important
      background-size 24px auto !important

      &.disabled
        background-image url(../img/app/rwd/slider-arrow-right-disabled.svg) !important

      span
        display none

  &__pill
    display inline-block
    border-radius 100px
    padding 5px 17px
    font-size 14px
    background-color $secondary-navigation-pill-background
    color $witt-very-dark-grey !important
    flex-grow 0
    flex-shrink 0

    &:hover,
    &.active
      text-decoration none
      background-color $witt-very-dark-grey
      color $witt-white !important
      
    &.active
      padding-right 35px
      background-image url(../img/app/rwd/icons/close-x-white.svg)
      background-repeat no-repeat
      background-size 9px auto
      background-position center right 15px

  &__slider
    @media up_to_L
      display flex
      width 100%
      height auto
      overflow-x scroll
      padding-bottom 10px
      scrollbar-height thin
      scrollbar-width thin
      scrollbar-color #888 #f1f1f1
      height -moz-available
      height fill-available
      max-width: min-content;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

    @media L_and_up
      display none

    .category__sub-navigation__bubble
      @media up_to_M
        display flex
        flex-direction column
        align-items center
        text-align center

    :not(:hover)::-webkit-scrollbar
      width 0


  &__bubbles
    text-align center
    display none
    padding 0 20px
    justify-content center

    .owl-loaded
      display flex

    .owl-stage
      padding-left 0 !important
      padding-right 0 !important

    @media up_to_L
      display none !important
    
    &__container
      min-height 128px
      padding-bottom 16px
      z-index 2
      position relative

      .owl-item
        justify-content center
        display flex

      &.center
        .owl-stage-outer
          display flex
          justify-content center

    &__divider
      border-bottom 1px solid $witt-light-grey5
      width 100%
      display none

      @media up_to_M
        margin-top -10px


    .owl-nav
      position absolute
      left 0px
      top 50%
      margin-top -15px
      width 100%

    .owl-prev
      position absolute
      left 0px
      display block
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/slider-arrow-left.svg) !important
      background-repeat no-repeat !important
      background-position center center !important
      background-size 24px auto !important

      &.disabled
        background-image url(../img/app/rwd/slider-arrow-left-disabled.svg) !important

      span
        display none

    .owl-next
      position absolute
      right 0px
      display block
      width 30px
      height 30px
      background-color transparent
      background-image url(../img/app/rwd/slider-arrow-right.svg) !important
      background-repeat no-repeat !important
      background-position center center !important
      background-size 24px auto !important

      &.disabled
        background-image url(../img/app/rwd/slider-arrow-right-disabled.svg) !important

      span
        display none

  &__bubble
    display block
    float left
    width 105px
    padding 0px 15px
    outline none !important

    @media L_and_up
      width 115px

    &:hover
      text-decoration none

    &.active &__frame
      border 1px solid $witt-very-dark-grey

    &__frame
      display inline-block
      padding 2px
      border-radius 50%
      border 1px solid transparent
      transition all 0.3s

      &:hover
        @media L_and_up
          border 1px solid $witt-very-dark-grey

    &__image
      width 64px !important
      height 64px
      margin auto
      border-radius 50%
      border 1px solid $witt-light-grey5
      object-fit contain

      @media L_and_up
        width 78px !important
        height 78px

    &__label
      font-size 14px
      line-height 19px
      color $witt-very-dark-grey
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      text-align center
        
      @supports (-webkit-line-clamp: 2)
        overflow hidden
        text-overflow ellipsis
        white-space initial
        display -webkit-box
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        min-width fit-content
