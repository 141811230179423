@require '../includes/*'

#helline
  .category__sub-navigation

    &__pills
      .owl-prev
        background-image url(../img/app/rwd/helline/slider-arrow-left.svg) !important

        &.disabled
          background-image url(../img/app/rwd/helline/slider-arrow-left-disabled.svg) !important

      .owl-next
        background-image url(../img/app/rwd/helline/slider-arrow-right.svg) !important

        &.disabled
          background-image url(../img/app/rwd/helline/slider-arrow-right-disabled.svg) !important

    &__pill
      background-color $secondary-navigation-pill-background
      color $helline-dark-blue !important

      &:hover,
      &.active
        background-color $helline-dark-blue
        color $helline-white !important

    &__bubble
      &.active &__frame
        border 1px solid $helline-dark-blue

      &__frame
        &:hover
          @media L_and_up
            border 1px solid $helline-dark-blue

      &__label
        color $helline-dark-blue
        
    &__bubbles
      
      .slick-prev
        background-image url(../img/app/rwd/helline/slider-arrow-left.svg) !important
        
        &.slick-disabled
          background-image url(../img/app/rwd/helline/slider-arrow-left-disabled.svg) !important
        
      .slick-next
        background-image url(../img/app/rwd/helline/slider-arrow-right.svg) !important
        
        &.slick-disabled
          background-image url(../img/app/rwd/helline/slider-arrow-right-disabled.svg) !important