@require 'includes/*'

.colorTile_autre
  background-color $tile-autre
.colorTile_blanc, .colorTile_white
  background-color $tile-blanc
.colorTile_bleu, .colorTile_blue
  background-color $tile-bleu
.colorTile_piano_white, .colorTile_beige, .colorTile_couleur_ivoire, .colorTile_ahorn
  background-color $tile-beige
.colorTile_couleur_m_tallis_e, .colorTile_couleur_metallisee, .colorTile_couleur_argente
  background -webkit-gradient(linear, left top, left bottom, color-stop(0, $tile-metallisee-start), color-stop(100%, $tile-metallisee-stop))
  background -webkit-linear-gradient(top, $tile-metallisee-start 0, $tile-metallisee-stop 100%)
  background -o-linear-gradient(top, $tile-metallisee-start 0, $tile-metallisee-stop 100%)
  background -ms-linear-gradient(top, $tile-metallisee-start 0, $tile-metallisee-stop 100%)
  background -moz-linear-gradient(top, $tile-metallisee-start 0, $tile-metallisee-stop 100%)
  background linear-gradient(top, $tile-metallisee-start 0, $tile-metallisee-stop 100%)
.colorTile_couleur_dore
  $tile-dore-start
  background -webkit-gradient(linear, left top, left bottom, color-stop(0, $tile-dore-start), color-stop(100%, $tile-dore-stop))
  background -webkit-linear-gradient(top, $tile-dore-start 0, $tile-dore-stop 100%)
  background -o-linear-gradient(top, $tile-dore-start 0, $tile-dore-stop 100%)
  background -ms-linear-gradient(top, $tile-dore-start 0, $tile-dore-stop 100%)
  background -moz-linear-gradient(top, $tile-dore-start 0, $tile-dore-stop 100%)
  background linear-gradient(top, $tile-dore-start 0, $tile-dore-stop 100%)
.colorTile_emballage_multicolor, .colorTile_multicolore, .colorTile_multi
  sprite ($multicolor)
.colorTile_gris, .colorTile_grey
  background-color $tile-gris
.colorTile_jaune, .colorTile_yellow
  background-color $tile-jaune
.colorTile_lilas, .colorTile_violet, .colorTile_purple
  background-color $tile-lilas
.colorTile_marron, .colorTile_brown, .colorTile_oak, .colorTile_wenge
  background-color $tile-marron
.colorTile_noir, .colorTile_black, .colorTile_ebony
  background-color $tile-noir
.colorTile_olive
  background-color $tile-olive
.colorTile_orange
  background-color $tile-orange
.colorTile_rose_fuchsia, .colorTile_rose
  background-color $tile-rose
.colorTile_rouge, .colorTile_red
  background-color $tile-rouge
.colorTile_turquoise
  background-color $tile-turquoise
.colorTile_vert_olive
  background-color $tile-vert-olive
.colorTile_vert, .colorTile_green
  background-color $tile-vert
.colorTile_sans_couleur
  background-color #f9f9f9



