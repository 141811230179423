@require 'includes/*'

#mv
  .shoplogo
    background-image url(/img/app/rwd/logo-mv.png) !important

    @media screen and (max-width: $medium)
      width 50px

  .pageheader__metanav__list-item--contact
    sprite ($metanav_contact_mv) !important

  .header-icon-group
    .miniBasket, .wishlist, .plus-disc, .logged-in
      background #F22238

  .icon
    &__wishlist
      background-image url(/img/app/rwd/moda/icon-add-wishlist.svg)

  .navigation
    &__list
      &__item

        &-text
          &.is-active
            @media L_and_up
              padding-bottom 6px
              border 2px solid #F22238
              //the following is necessary since radial-gradient is used for border-color in witt
              border-top 0
              border-left 0
              border-right 0

        &--mainCategory
          a, span
            @media up_to_M
              color $moda-grey

            &.scheme1
              color $moda-red !important
              background-color transparent

              &:hover
                color $witt-white !important
                background-color $moda-red !important

        &--mobileMetanav
          a, span
            @media up_to_M
              color $moda-grey

  .sticker
      &--reduction
        background-color $moda-red2
        color $moda-white

  .category
    .article
      margin-bottom 30px
      &__name
        font-size 15px
        height auto
      &__price
        font-size 15px

        &__currency
          font-size 15px

        &__amount
          &--with-reduction
            color $moda-red4

      &__wishlist
        background-image url(../img/app/rwd/moda/icon-add-wishlist.svg)

        &.active, &:hover
          background-image url(../img/app/rwd/moda/icon-add-wishlist-active.svg)

  .footer
    .callUs
      sprite ($metanav_contact_mv) !important

  #footer
    height auto
    overflow hidden
    margin-bottom 0
    margin-top 0
    background-color $witt-white
    color $witt-black
    border-top 2px solid $moda-border

    .logos
      width 150px
      padding-top 30px
      margin-right 20px
      text-align center
      zoom 1
      display inline
      vertical-align top

      a
        display block
        margin 0 auto 5px

    .box
      zoom 1
      display inline
      vertical-align top
      margin-right 20px
      margin-left 10px
      width 120px

      &.last
        margin-right 0
        width 180px

    .headline
      height 32px

      .icon
        zoom 1
        display inline
        vertical-align top
        margin-right 10px
        margin-top 5px

      span
        zoom 1
        display inline
        vertical-align top
        font-size 15px
        margin-top 20px
        color $moda-red

    ul
      list-style-type none
      padding-left 0

    li
      margin-top 3px
      line-height 16px

      a
        font-size 12px
        color $witt-black


    .call_us
      width 189px
      height 25px
      display block
      background url(../img/app/sprites/global-4e19d.png) 0 -463px

    #footerAdvantages-no-rwd
      text-align left
      background-color $witt-footer-background
      clear both

      .advantage
        width 244px
        height 140px
        padding-right 0
        float left
        margin-top 10px
        background-color $moda-footer-bg
        border-right 0
        margin-bottom 0

        &:last-child
          margin-right 0

        &.delivery
          width 212px

        &.quality
          width 280px

          .call_us
            margin-left 90px

      #advantagePayment, #advantageHappiness, #advantageDelivery, #advantageQuality
        display block
        margin auto
        margin-bottom 10px
        margin-top 23px

      #advantagePayment
        background url(../img/app/sprites/global-4e19d.png) 0 -1412px
        width 22px
        height 32px

      #advantageHappiness
        background url(../img/app/sprites/global-4e19d.png) 0 -1253px
        width 33px
        height 32px

      #advantageDelivery
        background url(../img/app/sprites/global-4e19d.png) 0 -1061px
        width 65px
        height 33px
        margin-left -4px

      #advantageQuality
        background url(../img/app/sprites/global-4e19d.png) 0 -1498px
        width 27px
        height 34px

      .advantageCircle
        width 79px
        height 80px
        -webkit-border-radius 80px
        border-radius 80px
        background-color $witt-black
        display block
        margin-top 28px
        margin-left 5px
        margin-right 8px
        float left

      .advantageHeadline
        text-transform uppercase
        font-size 15px
        color $witt-sale-background
        line-height 18px
        margin-top 38px
        margin-right 5px

      .advantageText
        font-size 11px
        line-height 15px
        margin-right 5px

      li
        font-size 13px
        line-height 18px

        a
          font-size 13px
          line-height 18px

    #footerNavigation-no-rwd
      background-color $witt-footer-background
      clear both

      li
        font-size 13px
        line-height 18px

        a
          font-size 13px
          line-height 18px

      .footerNavigation-no-rwdFirstRow
        background-color $moda-footer-bg-2
        border-top 1px solid $moda-border-2
        border-bottom 1px solid $moda-border-2
        margin 0
        padding 0
        min-height 0
        text-align center

        .footerNewsletterHeadline
          font-size 20px
          line-height 32px
          margin-top 20px

          a
            text-decoration underline
            color $witt-black

            &:hover
              text-decoration none

        .footerNewsletterText
          font-size 14px
          line-height 24px

        .footerNewsletterHint
          font-size 11px
          line-height 20px
          margin-bottom 24px

        .element
          border-right 1px $moda-border-3 solid

          &:last-child
            border-right 0 $moda-border-3 solid

        .elementHeadline
          color $witt-black

        li
          padding-bottom 8px

      .footerNavigation-no-rwdSecondRow
        margin-bottom 20px
        margin-left 10px
        margin-right 0
        min-height 220px

        .element
          border-right 1px $witt-footer-background solid

          &:last-child
            border-right 0 $witt-footer-background solid

        .elementHeadline
          color $witt-sale-background

      .footerNavigationSecondRow
        background-color $moda-footer-bg
        margin 0
        padding-left 10px
        padding-right 10px
        padding-top 0
        padding-bottom 0
        min-height 220px

        .element
          border none solid $witt-black
          position relative
          box-sizing initial

          ul
            margin  1em 0

          .elementHeadline
            color $witt-black

            a
              color $witt-navigation-color

        .trusted_shop
          background url(../img/app/sprites/global-4e19d.png) 0 -1753px
          width 50px
          height 50px
          position absolute
          bottom 25px
          right 105px

      .element
        width 288px
        min-height 200px
        padding-left 15px
        padding-right 15px
        margin-top 20px
        margin-bottom 20px
        float left

        &:last-child
          margin-right 0

      .elementHeadline
        text-transform uppercase
        font-size 15px
        line-height 15px
        padding-bottom 5px
        padding-top 5px

        .smallCaps
          text-transform none

      ul
        padding 0

      li
        list-style-type none
        line-height 16px
        font-size 14px
        color $witt-black
        margin-bottom 6px

        &.small
          font-size 12px
          line-height 16px

      .red
        color $witt-sale-background

      .credit_cards
        background url(../img/app/sprites/global-4e19d.png) 0 -987px
        width 147px
        height 20px

      .footer_facebook
        background url(../img/app/sprites/global-4e19d.png) 0 -1096px
        width 30px
        height 30px
        display block

      .footer_blog
        background url(../img/app/sprites/global-4e19d.png) 0 -885px
        width 58px
        height 27px
        display block

  #subFooter
    background-color $witt-white
    text-align center
    font-size 12px
    padding-top 0
    padding-bottom  15px

    .links
      margin-bottom 8px

      a
        margin-right 8px

        &:after
          margin-left 8px
          content '|'

        &:last-child
          margin-right 0

          &:after
            margin-left 0
            content ''

    p
      margin  5px 0
      font-size  12px
  .account
    &__navigation
      &__icon
        &--personal
          background-image url(../img/app/rwd/account-icon-personal-mv.png)

        &--shipping
          background-image url(../img/app/rwd/account-icon-shipping-mv.png)

        &--orders
          background-image url(../img/app/rwd/account-icon-orders-mv.png)

        &--newsletter
          background-image url(../img/app/rwd/account-icon-newsletter-mv.png)

        &--katalog
          background-image url(../img/app/rwd/account-icon-catalog-mv.png)

        &--returns
          background-image url(../img/app/rwd/account-icon-returns-mv.png)


  .account-navigation-layer
    &__btn
      .btn-standard
        background-color $moda-red3
        border-color $moda-red3

  #product
    .pswp
      &__top-bar
        background-image url(/img/app/rwd/logo-mv.png)

  .summary
    &__savings
      background-color $moda-blue

      &:before
        border-bottom 5px solid $moda-blue

  .basket
    &__promo-code
      &__info
        &__text
          span
            color $moda-red2

    &__delete-confirm
      &__popup
        &__link
          color $moda-white
          background-color $moda-red2
          border 1px solid $moda-red2
        &__close
          color $witt-darked-grey

    .line-item
      &__wishlist
        a
          &:before
            background-image url(/img/app/rwd/moda/icon-add-wishlist.svg)

          &[data-remove-from-wishlist]
            &:before
              background-image url(/img/app/rwd/moda/icon-add-wishlist-active.svg)

        &.active, &:hover
          a
            &:before
              background-image url(/img/app/rwd/moda/icon-add-wishlist-active.svg)


  .material
    input[type=text],
    input[type=password],
    input[type=email],
    input[type=number]
      &:focus
        border 1px solid $moda-very-dark-grey !important


  .product

    &__similar-items

      &__wishlist
        background-image url(../img/app/rwd/moda/icon-add-wishlist.svg)

        &.active, &:hover
          background-image url(../img/app/rwd/moda/icon-add-wishlist-active.svg)

    &__carousel
      &__wishlist
        background-image url(../img/app/rwd/moda/icon-add-wishlist.svg)

        &.active, &:hover
          background-image url(../img/app/rwd/moda/icon-add-wishlist-active.svg)

    &__wishlist-add
      &.active, &:hover
        .icon__wishlist
          background-image url(/img/app/rwd/moda/icon-add-wishlist-active.svg)


  .general-slider
    &__product
      &__wishlist
        background-image url(../img/app/rwd/moda/icon-add-wishlist.svg)

        &.active, &:hover
          background-image url(../img/app/rwd/moda/icon-add-wishlist-active.svg)

  .btn
    &-addwishlist
      &:hover, &:active, &.active:focus
        .icon__wishlist
          background-image url(../img/app/rwd/moda/icon-add-wishlist-active.svg)

      &__price
        &--highlight
          color $moda-red

      &__add-to-basket
        background-image url(../img/app/rwd/moda/add-to-basket.svg)

        &:hover
          background-color $moda-grey2
          background-image url(../img/app/rwd/moda/add-to-basket-active.svg)
          border-color $moda-grey2

        &:focus
          background-color $moda-grey2
          background-image url(../img/app/rwd/moda/add-to-basket-active.svg)
          border-color $witt-darked-grey3

  .wishlist-article
    &__price
      .witt-price
        &__eco-tax
          color $moda-light-grey2

  .filter-list
    &__mobile-dropdown
      @media up_to_M
        border-radius 4px

  .sorting
    .dropdown
      &__header
        @media up_to_M
          border-radius 4px

  .products-column-switch
    @media up_to_M
      border-radius 4px

  .btn-primary-checkout
    color #fff
    background-color #130103
    border 1px solid #130103

    &:hover
      background-color #f0e7de
      border 1px solid #f0e7de

      .btn-primary-checkout__label
        color #130103

      .btn-primary-checkout__icon
        background-image url(../img/app/rwd/checkout-payment-icons/security_24px_moda.svg)