@require 'includes/*'
.btn
  border-radius 0px
  max-width 100%
  display inline-block
  vertical-align middle
  cursor pointer
  font-size 14px
  line-height 1.6
  border 1px solid transparent
  padding 6px 12px
  text-align center
  white-space normal
  border-radius 5px

  &:hover
    & .btn-icon__arrow-right
      sprite($icon_arrow_right_white_small)
    & .btn-icon__arrow-down
      sprite($icon_arrow_down_white_small)
    & .btn-icon__arrow-up
      sprite($icon_arrow_up_white_small)

  &:focus
    outline none
    box-shadow none !important

  &:active
    outline none
    box-shadow none !important

  &-primary
    background-color $witt-red
    border-color $witt-red
    color $witt-white !important

    &:hover
      background-color $witt-red-highlight
      border-color $witt-red-highlight
      color $witt-white
      text-decoration none

    &:active
      background-color $witt-red !important
      border-color $witt-red !important
      color $witt-white !important

    &:disabled, &-softdisabled
      background-color $witt-light-grey
      border-color $witt-light-grey
      
  &:disabled
    cursor default

  &-secondary
    background-color $witt-blue
    border-color $witt-blue
    color $witt-white

    &:hover
      text-decoration none

  &-tertiary
    background-color $witt-light-grey
    border-color $witt-light-grey
    color $witt-white

    &:hover
      background-color $witt-darked-grey
      color $witt-white
      
  &-quatenary
    background-color $witt-lighter-grey
    border-color $witt-lighter-grey
    color $witt-black

    &:hover
      background-color $witt-darked-grey
      color $witt-white

    &--light
      background-color $witt-very-light-grey
      color $witt-black
      border-color $witt-highlight-border

    &--dark
      background-color $witt-darked-grey
      color $witt-white
      border-color $witt-darked-grey
      font-weight 300
      
  &-white
    background-color $witt-white
    border-color $witt-white
    color $witt-black
    font-weight 700
    
    &:hover
      background-color $witt-light-grey
      border-color $witt-light-grey
      color $witt-white

  &-small
    font-size 12px
    padding 8px 10px 6px 10px

  &-standard
    font-size 16px
    padding 8px 12px

  &--medium
    font-size 16px
    line-height 20.8px
    padding 8px 24px
    height 53px
    display flex
    justify-content center
    align-items center
    cursor pointer
    white-space normal

  &-big
    font-size 20px
    padding 8px 12px

  &-ghost
    border 1px solid $witt-darked-grey
    color $witt-black

  &-black
    background-color $witt-black
    color $witt-white

  &-full-width
    width 100%
    
  &-uppercase
    text-transform uppercase

  &-with-icon
    vertical-align middle
    .btn-text,
    .btn-icon__arrow-right--white,
    .btn-icon__arrow-right,
    .btn-icon__arrow-down,
    .btn-icon__arrow-down--white,
    .btn-icon__arrow-up,
    .btn-icon__arrow-up--white,
    .btn-icon__arrow-left
      inline-block()
      vertical-align middle

    .btn-text
      padding-top 2px
    &.btn-full-width .btn-text
      width calc(100% - 22px)

    .btn-icon__arrow-right--white
      sprite($icon_arrow_right_white_small)
      margin-left 10px
    .btn-icon__arrow-right
      sprite($icon_arrow_right_small)
      margin-left 10px
    .btn-icon__arrow-down
      sprite($icon_arrow_down_small)
    .btn-icon__arrow-down--white
      sprite($icon_arrow_down_white_small)
    .btn-icon__arrow-up
      sprite($icon_arrow_up_small)
    .btn-icon__arrow-up--white
      sprite($icon_arrow_up_white_small)
    .btn-icon__arrow-down,
    .btn-icon__arrow-up
      margin-right 7px
      margin-bottom 3px
    .btn-icon__arrow-down--white,
    .btn-icon__arrow-up--white
      margin-right 5px
      
    &--first
      .btn-icon__arrow-left
        sprite($icon_arrow_left_white)
        margin-right 10px

  &-more-articles
    padding 13px 70px
    font-size 15px
    width 100%

    @media M_and_up
      width auto
      
  &-addwishlist
    background-color transparent
    border-color transparent
    color $witt-black
    text-transform none
    font-size 16px

    &:hover, &:active, &.active:focus
      
      .icon__wishlist
        background-image url(../img/app/rwd/icon-add-wishlist-active.svg)      

  &-empty
    text-decoration underline !important
    font-weight 700

.action
  &__plus
    border 1px solid $witt-light-grey
    width 24px
    height 24px
    border-radius 50%
    position relative
    inline-block()
    vertical-align middle
    margin-left 34px
    cursor pointer

    &:before
      display block
      content ''
      width 10px
      height 2px
      background-color $witt-light-grey
      position absolute
      left 6px
      top 10px

    &:after
      display block
      content ''
      width 10px
      height 2px
      background-color $witt-light-grey
      position absolute
      left 6px
      top 10px
      transform rotate(90deg)

    &.open
      &:after
        display none
			
				
			