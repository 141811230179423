@require '../includes/*'

.filter__size-button
    padding 5px 11px
    margin 5px 5px
    display inline-block
    border 1px solid $witt-light-grey
    border-radius 5px
    cursor pointer

    &.selected
        border 2px solid $witt-black
        font-weight bold
        color $witt-black

    &.disabled
        display none