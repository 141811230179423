/*
Stylus variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon_home_width;
}

The large array-like variables contain all information about a single icon
$icon_home = x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet = width height image $spritesheet_sprites;
*/
$advantage_delivery_name = 'advantage-delivery';
$advantage_delivery_x = 50px;
$advantage_delivery_y = 69px;
$advantage_delivery_offset_x = -50px;
$advantage_delivery_offset_y = -69px;
$advantage_delivery_width = 49px;
$advantage_delivery_height = 50px;
$advantage_delivery_total_width = 219px;
$advantage_delivery_total_height = 214px;
$advantage_delivery_image = '/img/app/rwd/sprite.png';
$advantage_delivery = 50px 69px -50px -69px 49px 50px 219px 214px '/img/app/rwd/sprite.png' 'advantage-delivery';
$advantage_happiness_name = 'advantage-happiness';
$advantage_happiness_x = 0px;
$advantage_happiness_y = 119px;
$advantage_happiness_offset_x = 0px;
$advantage_happiness_offset_y = -119px;
$advantage_happiness_width = 34px;
$advantage_happiness_height = 50px;
$advantage_happiness_total_width = 219px;
$advantage_happiness_total_height = 214px;
$advantage_happiness_image = '/img/app/rwd/sprite.png';
$advantage_happiness = 0px 119px 0px -119px 34px 50px 219px 214px '/img/app/rwd/sprite.png' 'advantage-happiness';
$advantage_payment_name = 'advantage-payment';
$advantage_payment_x = 189px;
$advantage_payment_y = 50px;
$advantage_payment_offset_x = -189px;
$advantage_payment_offset_y = -50px;
$advantage_payment_width = 27px;
$advantage_payment_height = 50px;
$advantage_payment_total_width = 219px;
$advantage_payment_total_height = 214px;
$advantage_payment_image = '/img/app/rwd/sprite.png';
$advantage_payment = 189px 50px -189px -50px 27px 50px 219px 214px '/img/app/rwd/sprite.png' 'advantage-payment';
$advantage_quality_name = 'advantage-quality';
$advantage_quality_x = 189px;
$advantage_quality_y = 0px;
$advantage_quality_offset_x = -189px;
$advantage_quality_offset_y = 0px;
$advantage_quality_width = 30px;
$advantage_quality_height = 50px;
$advantage_quality_total_width = 219px;
$advantage_quality_total_height = 214px;
$advantage_quality_image = '/img/app/rwd/sprite.png';
$advantage_quality = 189px 0px -189px 0px 30px 50px 219px 214px '/img/app/rwd/sprite.png' 'advantage-quality';
$carte_4_etoiles_icon_name = 'carte-4-etoiles-icon';
$carte_4_etoiles_icon_x = 99px;
$carte_4_etoiles_icon_y = 69px;
$carte_4_etoiles_icon_offset_x = -99px;
$carte_4_etoiles_icon_offset_y = -69px;
$carte_4_etoiles_icon_width = 55px;
$carte_4_etoiles_icon_height = 40px;
$carte_4_etoiles_icon_total_width = 219px;
$carte_4_etoiles_icon_total_height = 214px;
$carte_4_etoiles_icon_image = '/img/app/rwd/sprite.png';
$carte_4_etoiles_icon = 99px 69px -99px -69px 55px 40px 219px 214px '/img/app/rwd/sprite.png' 'carte-4-etoiles-icon';
$carte_bancaire_lock_icon_name = 'carte-bancaire-lock-icon';
$carte_bancaire_lock_icon_x = 48px;
$carte_bancaire_lock_icon_y = 169px;
$carte_bancaire_lock_icon_offset_x = -48px;
$carte_bancaire_lock_icon_offset_y = -169px;
$carte_bancaire_lock_icon_width = 13px;
$carte_bancaire_lock_icon_height = 14px;
$carte_bancaire_lock_icon_total_width = 219px;
$carte_bancaire_lock_icon_total_height = 214px;
$carte_bancaire_lock_icon_image = '/img/app/rwd/sprite.png';
$carte_bancaire_lock_icon = 48px 169px -48px -169px 13px 14px 219px 214px '/img/app/rwd/sprite.png' 'carte-bancaire-lock-icon';
$credit_cards_name = 'credit-cards';
$credit_cards_x = 0px;
$credit_cards_y = 49px;
$credit_cards_offset_x = 0px;
$credit_cards_offset_y = -49px;
$credit_cards_width = 147px;
$credit_cards_height = 20px;
$credit_cards_total_width = 219px;
$credit_cards_total_height = 214px;
$credit_cards_image = '/img/app/rwd/sprite.png';
$credit_cards = 0px 49px 0px -49px 147px 20px 219px 214px '/img/app/rwd/sprite.png' 'credit-cards';
$header_close_name = 'header-close';
$header_close_x = 116px;
$header_close_y = 119px;
$header_close_offset_x = -116px;
$header_close_offset_y = -119px;
$header_close_width = 24px;
$header_close_height = 24px;
$header_close_total_width = 219px;
$header_close_total_height = 214px;
$header_close_image = '/img/app/rwd/sprite.png';
$header_close = 116px 119px -116px -119px 24px 24px 219px 214px '/img/app/rwd/sprite.png' 'header-close';
$icon_addtocart_small_name = 'icon-addtocart-small';
$icon_addtocart_small_x = 34px;
$icon_addtocart_small_y = 146px;
$icon_addtocart_small_offset_x = -34px;
$icon_addtocart_small_offset_y = -146px;
$icon_addtocart_small_width = 16px;
$icon_addtocart_small_height = 21px;
$icon_addtocart_small_total_width = 219px;
$icon_addtocart_small_total_height = 214px;
$icon_addtocart_small_image = '/img/app/rwd/sprite.png';
$icon_addtocart_small = 34px 146px -34px -146px 16px 21px 219px 214px '/img/app/rwd/sprite.png' 'icon-addtocart-small';
$icon_addtocart_name = 'icon-addtocart';
$icon_addtocart_x = 0px;
$icon_addtocart_y = 169px;
$icon_addtocart_offset_x = 0px;
$icon_addtocart_offset_y = -169px;
$icon_addtocart_width = 20px;
$icon_addtocart_height = 26px;
$icon_addtocart_total_width = 219px;
$icon_addtocart_total_height = 214px;
$icon_addtocart_image = '/img/app/rwd/sprite.png';
$icon_addtocart = 0px 169px 0px -169px 20px 26px 219px 214px '/img/app/rwd/sprite.png' 'icon-addtocart';
$icon_arrow_down_medium_name = 'icon-arrow-down-medium';
$icon_arrow_down_medium_x = 99px;
$icon_arrow_down_medium_y = 109px;
$icon_arrow_down_medium_offset_x = -99px;
$icon_arrow_down_medium_offset_y = -109px;
$icon_arrow_down_medium_width = 16px;
$icon_arrow_down_medium_height = 9px;
$icon_arrow_down_medium_total_width = 219px;
$icon_arrow_down_medium_total_height = 214px;
$icon_arrow_down_medium_image = '/img/app/rwd/sprite.png';
$icon_arrow_down_medium = 99px 109px -99px -109px 16px 9px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-down-medium';
$icon_arrow_down_small_name = 'icon-arrow-down-small';
$icon_arrow_down_small_x = 189px;
$icon_arrow_down_small_y = 161px;
$icon_arrow_down_small_offset_x = -189px;
$icon_arrow_down_small_offset_y = -161px;
$icon_arrow_down_small_width = 10px;
$icon_arrow_down_small_height = 5px;
$icon_arrow_down_small_total_width = 219px;
$icon_arrow_down_small_total_height = 214px;
$icon_arrow_down_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_down_small = 189px 161px -189px -161px 10px 5px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-down-small';
$icon_arrow_down_white_small_name = 'icon-arrow-down-white-small';
$icon_arrow_down_white_small_x = 199px;
$icon_arrow_down_white_small_y = 161px;
$icon_arrow_down_white_small_offset_x = -199px;
$icon_arrow_down_white_small_offset_y = -161px;
$icon_arrow_down_white_small_width = 10px;
$icon_arrow_down_white_small_height = 5px;
$icon_arrow_down_white_small_total_width = 219px;
$icon_arrow_down_white_small_total_height = 214px;
$icon_arrow_down_white_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_down_white_small = 199px 161px -199px -161px 10px 5px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-down-white-small';
$icon_arrow_down_name = 'icon-arrow-down';
$icon_arrow_down_x = 50px;
$icon_arrow_down_y = 146px;
$icon_arrow_down_offset_x = -50px;
$icon_arrow_down_offset_y = -146px;
$icon_arrow_down_width = 23px;
$icon_arrow_down_height = 12px;
$icon_arrow_down_total_width = 219px;
$icon_arrow_down_total_height = 214px;
$icon_arrow_down_image = '/img/app/rwd/sprite.png';
$icon_arrow_down = 50px 146px -50px -146px 23px 12px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-down';
$icon_arrow_left_grey_small_name = 'icon-arrow-left-grey-small';
$icon_arrow_left_grey_small_x = 31px;
$icon_arrow_left_grey_small_y = 195px;
$icon_arrow_left_grey_small_offset_x = -31px;
$icon_arrow_left_grey_small_offset_y = -195px;
$icon_arrow_left_grey_small_width = 8px;
$icon_arrow_left_grey_small_height = 16px;
$icon_arrow_left_grey_small_total_width = 219px;
$icon_arrow_left_grey_small_total_height = 214px;
$icon_arrow_left_grey_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_left_grey_small = 31px 195px -31px -195px 8px 16px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-left-grey-small';
$icon_arrow_left_helline_name = 'icon-arrow-left-helline';
$icon_arrow_left_helline_x = 147px;
$icon_arrow_left_helline_y = 49px;
$icon_arrow_left_helline_offset_x = -147px;
$icon_arrow_left_helline_offset_y = -49px;
$icon_arrow_left_helline_width = 20px;
$icon_arrow_left_helline_height = 20px;
$icon_arrow_left_helline_total_width = 219px;
$icon_arrow_left_helline_total_height = 214px;
$icon_arrow_left_helline_image = '/img/app/rwd/sprite.png';
$icon_arrow_left_helline = 147px 49px -147px -49px 20px 20px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-left-helline';
$icon_arrow_left_small_name = 'icon-arrow-left-small';
$icon_arrow_left_small_x = 214px;
$icon_arrow_left_small_y = 135px;
$icon_arrow_left_small_offset_x = -214px;
$icon_arrow_left_small_offset_y = -135px;
$icon_arrow_left_small_width = 5px;
$icon_arrow_left_small_height = 10px;
$icon_arrow_left_small_total_width = 219px;
$icon_arrow_left_small_total_height = 214px;
$icon_arrow_left_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_left_small = 214px 135px -214px -135px 5px 10px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-left-small';
$icon_arrow_left_white_name = 'icon-arrow-left-white';
$icon_arrow_left_white_x = 179px;
$icon_arrow_left_white_y = 119px;
$icon_arrow_left_white_offset_x = -179px;
$icon_arrow_left_white_offset_y = -119px;
$icon_arrow_left_white_width = 9px;
$icon_arrow_left_white_height = 16px;
$icon_arrow_left_white_total_width = 219px;
$icon_arrow_left_white_total_height = 214px;
$icon_arrow_left_white_image = '/img/app/rwd/sprite.png';
$icon_arrow_left_white = 179px 119px -179px -119px 9px 16px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-left-white';
$icon_arrow_left_name = 'icon-arrow-left';
$icon_arrow_left_x = 0px;
$icon_arrow_left_y = 195px;
$icon_arrow_left_offset_x = 0px;
$icon_arrow_left_offset_y = -195px;
$icon_arrow_left_width = 11px;
$icon_arrow_left_height = 19px;
$icon_arrow_left_total_width = 219px;
$icon_arrow_left_total_height = 214px;
$icon_arrow_left_image = '/img/app/rwd/sprite.png';
$icon_arrow_left = 0px 195px 0px -195px 11px 19px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-left';
$icon_arrow_right_grey_small_name = 'icon-arrow-right-grey-small';
$icon_arrow_right_grey_small_x = 39px;
$icon_arrow_right_grey_small_y = 195px;
$icon_arrow_right_grey_small_offset_x = -39px;
$icon_arrow_right_grey_small_offset_y = -195px;
$icon_arrow_right_grey_small_width = 8px;
$icon_arrow_right_grey_small_height = 16px;
$icon_arrow_right_grey_small_total_width = 219px;
$icon_arrow_right_grey_small_total_height = 214px;
$icon_arrow_right_grey_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_right_grey_small = 39px 195px -39px -195px 8px 16px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-right-grey-small';
$icon_arrow_right_small_name = 'icon-arrow-right-small';
$icon_arrow_right_small_x = 214px;
$icon_arrow_right_small_y = 145px;
$icon_arrow_right_small_offset_x = -214px;
$icon_arrow_right_small_offset_y = -145px;
$icon_arrow_right_small_width = 5px;
$icon_arrow_right_small_height = 10px;
$icon_arrow_right_small_total_width = 219px;
$icon_arrow_right_small_total_height = 214px;
$icon_arrow_right_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_right_small = 214px 145px -214px -145px 5px 10px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-right-small';
$icon_arrow_right_white_small_name = 'icon-arrow-right-white-small';
$icon_arrow_right_white_small_x = 182px;
$icon_arrow_right_white_small_y = 49px;
$icon_arrow_right_white_small_offset_x = -182px;
$icon_arrow_right_white_small_offset_y = -49px;
$icon_arrow_right_white_small_width = 5px;
$icon_arrow_right_white_small_height = 10px;
$icon_arrow_right_white_small_total_width = 219px;
$icon_arrow_right_white_small_total_height = 214px;
$icon_arrow_right_white_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_right_white_small = 182px 49px -182px -49px 5px 10px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-right-white-small';
$icon_arrow_right_white_name = 'icon-arrow-right-white';
$icon_arrow_right_white_x = 22px;
$icon_arrow_right_white_y = 195px;
$icon_arrow_right_white_offset_x = -22px;
$icon_arrow_right_white_offset_y = -195px;
$icon_arrow_right_white_width = 9px;
$icon_arrow_right_white_height = 16px;
$icon_arrow_right_white_total_width = 219px;
$icon_arrow_right_white_total_height = 214px;
$icon_arrow_right_white_image = '/img/app/rwd/sprite.png';
$icon_arrow_right_white = 22px 195px -22px -195px 9px 16px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-right-white';
$icon_arrow_right_name = 'icon-arrow-right';
$icon_arrow_right_x = 11px;
$icon_arrow_right_y = 195px;
$icon_arrow_right_offset_x = -11px;
$icon_arrow_right_offset_y = -195px;
$icon_arrow_right_width = 11px;
$icon_arrow_right_height = 19px;
$icon_arrow_right_total_width = 219px;
$icon_arrow_right_total_height = 214px;
$icon_arrow_right_image = '/img/app/rwd/sprite.png';
$icon_arrow_right = 11px 195px -11px -195px 11px 19px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-right';
$icon_arrow_up_grey_name = 'icon-arrow-up-grey';
$icon_arrow_up_grey_x = 73px;
$icon_arrow_up_grey_y = 146px;
$icon_arrow_up_grey_offset_x = -73px;
$icon_arrow_up_grey_offset_y = -146px;
$icon_arrow_up_grey_width = 23px;
$icon_arrow_up_grey_height = 12px;
$icon_arrow_up_grey_total_width = 219px;
$icon_arrow_up_grey_total_height = 214px;
$icon_arrow_up_grey_image = '/img/app/rwd/sprite.png';
$icon_arrow_up_grey = 73px 146px -73px -146px 23px 12px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-up-grey';
$icon_arrow_up_medium_name = 'icon-arrow-up-medium';
$icon_arrow_up_medium_x = 115px;
$icon_arrow_up_medium_y = 109px;
$icon_arrow_up_medium_offset_x = -115px;
$icon_arrow_up_medium_offset_y = -109px;
$icon_arrow_up_medium_width = 16px;
$icon_arrow_up_medium_height = 9px;
$icon_arrow_up_medium_total_width = 219px;
$icon_arrow_up_medium_total_height = 214px;
$icon_arrow_up_medium_image = '/img/app/rwd/sprite.png';
$icon_arrow_up_medium = 115px 109px -115px -109px 16px 9px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-up-medium';
$icon_arrow_up_small_name = 'icon-arrow-up-small';
$icon_arrow_up_small_x = 209px;
$icon_arrow_up_small_y = 161px;
$icon_arrow_up_small_offset_x = -209px;
$icon_arrow_up_small_offset_y = -161px;
$icon_arrow_up_small_width = 10px;
$icon_arrow_up_small_height = 5px;
$icon_arrow_up_small_total_width = 219px;
$icon_arrow_up_small_total_height = 214px;
$icon_arrow_up_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_up_small = 209px 161px -209px -161px 10px 5px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-up-small';
$icon_arrow_up_white_small_name = 'icon-arrow-up-white-small';
$icon_arrow_up_white_small_x = 167px;
$icon_arrow_up_white_small_y = 64px;
$icon_arrow_up_white_small_offset_x = -167px;
$icon_arrow_up_white_small_offset_y = -64px;
$icon_arrow_up_white_small_width = 10px;
$icon_arrow_up_white_small_height = 5px;
$icon_arrow_up_white_small_total_width = 219px;
$icon_arrow_up_white_small_total_height = 214px;
$icon_arrow_up_white_small_image = '/img/app/rwd/sprite.png';
$icon_arrow_up_white_small = 167px 64px -167px -64px 10px 5px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-up-white-small';
$icon_arrow_up_name = 'icon-arrow-up';
$icon_arrow_up_x = 96px;
$icon_arrow_up_y = 146px;
$icon_arrow_up_offset_x = -96px;
$icon_arrow_up_offset_y = -146px;
$icon_arrow_up_width = 23px;
$icon_arrow_up_height = 12px;
$icon_arrow_up_total_width = 219px;
$icon_arrow_up_total_height = 214px;
$icon_arrow_up_image = '/img/app/rwd/sprite.png';
$icon_arrow_up = 96px 146px -96px -146px 23px 12px 219px 214px '/img/app/rwd/sprite.png' 'icon-arrow-up';
$icon_blog_name = 'icon-blog';
$icon_blog_x = 34px;
$icon_blog_y = 119px;
$icon_blog_offset_x = -34px;
$icon_blog_offset_y = -119px;
$icon_blog_width = 57px;
$icon_blog_height = 27px;
$icon_blog_total_width = 219px;
$icon_blog_total_height = 214px;
$icon_blog_image = '/img/app/rwd/sprite.png';
$icon_blog = 34px 119px -34px -119px 57px 27px 219px 214px '/img/app/rwd/sprite.png' 'icon-blog';
$icon_close_white_name = 'icon-close-white';
$icon_close_white_x = 20px;
$icon_close_white_y = 169px;
$icon_close_white_offset_x = -20px;
$icon_close_white_offset_y = -169px;
$icon_close_white_width = 14px;
$icon_close_white_height = 15px;
$icon_close_white_total_width = 219px;
$icon_close_white_total_height = 214px;
$icon_close_white_image = '/img/app/rwd/sprite.png';
$icon_close_white = 20px 169px -20px -169px 14px 15px 219px 214px '/img/app/rwd/sprite.png' 'icon-close-white';
$icon_direct_order_name = 'icon-direct-order';
$icon_direct_order_x = 163px;
$icon_direct_order_y = 119px;
$icon_direct_order_offset_x = -163px;
$icon_direct_order_offset_y = -119px;
$icon_direct_order_width = 16px;
$icon_direct_order_height = 22px;
$icon_direct_order_total_width = 219px;
$icon_direct_order_total_height = 214px;
$icon_direct_order_image = '/img/app/rwd/sprite.png';
$icon_direct_order = 163px 119px -163px -119px 16px 22px 219px 214px '/img/app/rwd/sprite.png' 'icon-direct-order';
$icon_facebook_name = 'icon-facebook';
$icon_facebook_x = 140px;
$icon_facebook_y = 119px;
$icon_facebook_offset_x = -140px;
$icon_facebook_offset_y = -119px;
$icon_facebook_width = 23px;
$icon_facebook_height = 23px;
$icon_facebook_total_width = 219px;
$icon_facebook_total_height = 214px;
$icon_facebook_image = '/img/app/rwd/sprite.png';
$icon_facebook = 140px 119px -140px -119px 23px 23px 219px 214px '/img/app/rwd/sprite.png' 'icon-facebook';
$icon_question_name = 'icon-question';
$icon_question_x = 167px;
$icon_question_y = 49px;
$icon_question_offset_x = -167px;
$icon_question_offset_y = -49px;
$icon_question_width = 15px;
$icon_question_height = 15px;
$icon_question_total_width = 219px;
$icon_question_total_height = 214px;
$icon_question_image = '/img/app/rwd/sprite.png';
$icon_question = 167px 49px -167px -49px 15px 15px 219px 214px '/img/app/rwd/sprite.png' 'icon-question';
$icon_search_black_name = 'icon-search-black';
$icon_search_black_x = 74px;
$icon_search_black_y = 169px;
$icon_search_black_offset_x = -74px;
$icon_search_black_offset_y = -169px;
$icon_search_black_width = 13px;
$icon_search_black_height = 13px;
$icon_search_black_total_width = 219px;
$icon_search_black_total_height = 214px;
$icon_search_black_image = '/img/app/rwd/sprite.png';
$icon_search_black = 74px 169px -74px -169px 13px 13px 219px 214px '/img/app/rwd/sprite.png' 'icon-search-black';
$icon_search_white_name = 'icon-search-white';
$icon_search_white_x = 87px;
$icon_search_white_y = 169px;
$icon_search_white_offset_x = -87px;
$icon_search_white_offset_y = -169px;
$icon_search_white_width = 13px;
$icon_search_white_height = 13px;
$icon_search_white_total_width = 219px;
$icon_search_white_total_height = 214px;
$icon_search_white_image = '/img/app/rwd/sprite.png';
$icon_search_white = 87px 169px -87px -169px 13px 13px 219px 214px '/img/app/rwd/sprite.png' 'icon-search-white';
$icon_zoom_name = 'icon-zoom';
$icon_zoom_x = 189px;
$icon_zoom_y = 135px;
$icon_zoom_offset_x = -189px;
$icon_zoom_offset_y = -135px;
$icon_zoom_width = 25px;
$icon_zoom_height = 26px;
$icon_zoom_total_width = 219px;
$icon_zoom_total_height = 214px;
$icon_zoom_image = '/img/app/rwd/sprite.png';
$icon_zoom = 189px 135px -189px -135px 25px 26px 219px 214px '/img/app/rwd/sprite.png' 'icon-zoom';
$icon_close_name = 'icon_close';
$icon_close_x = 189px;
$icon_close_y = 100px;
$icon_close_offset_x = -189px;
$icon_close_offset_y = -100px;
$icon_close_width = 30px;
$icon_close_height = 35px;
$icon_close_total_width = 219px;
$icon_close_total_height = 214px;
$icon_close_image = '/img/app/rwd/sprite.png';
$icon_close = 189px 100px -189px -100px 30px 35px 219px 214px '/img/app/rwd/sprite.png' 'icon_close';
$icon_close_green_name = 'icon_close_green';
$icon_close_green_x = 154px;
$icon_close_green_y = 69px;
$icon_close_green_offset_x = -154px;
$icon_close_green_offset_y = -69px;
$icon_close_green_width = 30px;
$icon_close_green_height = 35px;
$icon_close_green_total_width = 219px;
$icon_close_green_total_height = 214px;
$icon_close_green_image = '/img/app/rwd/sprite.png';
$icon_close_green = 154px 69px -154px -69px 30px 35px 219px 214px '/img/app/rwd/sprite.png' 'icon_close_green';
$icon_close_red_name = 'icon_close_red';
$icon_close_red_x = 0px;
$icon_close_red_y = 69px;
$icon_close_red_offset_x = 0px;
$icon_close_red_offset_y = -69px;
$icon_close_red_width = 50px;
$icon_close_red_height = 50px;
$icon_close_red_total_width = 219px;
$icon_close_red_total_height = 214px;
$icon_close_red_image = '/img/app/rwd/sprite.png';
$icon_close_red = 0px 69px 0px -69px 50px 50px 219px 214px '/img/app/rwd/sprite.png' 'icon_close_red';
$icon_close_small_name = 'icon_close_small';
$icon_close_small_x = 34px;
$icon_close_small_y = 169px;
$icon_close_small_offset_x = -34px;
$icon_close_small_offset_y = -169px;
$icon_close_small_width = 14px;
$icon_close_small_height = 15px;
$icon_close_small_total_width = 219px;
$icon_close_small_total_height = 214px;
$icon_close_small_image = '/img/app/rwd/sprite.png';
$icon_close_small = 34px 169px -34px -169px 14px 15px 219px 214px '/img/app/rwd/sprite.png' 'icon_close_small';
$icon_close_small_green_name = 'icon_close_small_green';
$icon_close_small_green_x = 61px;
$icon_close_small_green_y = 169px;
$icon_close_small_green_offset_x = -61px;
$icon_close_small_green_offset_y = -169px;
$icon_close_small_green_width = 13px;
$icon_close_small_green_height = 14px;
$icon_close_small_green_total_width = 219px;
$icon_close_small_green_total_height = 214px;
$icon_close_small_green_image = '/img/app/rwd/sprite.png';
$icon_close_small_green = 61px 169px -61px -169px 13px 14px 219px 214px '/img/app/rwd/sprite.png' 'icon_close_small_green';
$metanav_contact_mv_name = 'metanav-contact-mv';
$metanav_contact_mv_x = 0px;
$metanav_contact_mv_y = 0px;
$metanav_contact_mv_offset_x = 0px;
$metanav_contact_mv_offset_y = 0px;
$metanav_contact_mv_width = 189px;
$metanav_contact_mv_height = 25px;
$metanav_contact_mv_total_width = 219px;
$metanav_contact_mv_total_height = 214px;
$metanav_contact_mv_image = '/img/app/rwd/sprite.png';
$metanav_contact_mv = 0px 0px 0px 0px 189px 25px 219px 214px '/img/app/rwd/sprite.png' 'metanav-contact-mv';
$metanav_contact_name = 'metanav-contact';
$metanav_contact_x = 0px;
$metanav_contact_y = 25px;
$metanav_contact_offset_x = 0px;
$metanav_contact_offset_y = -25px;
$metanav_contact_width = 189px;
$metanav_contact_height = 24px;
$metanav_contact_total_width = 219px;
$metanav_contact_total_height = 214px;
$metanav_contact_image = '/img/app/rwd/sprite.png';
$metanav_contact = 0px 25px 0px -25px 189px 24px 219px 214px '/img/app/rwd/sprite.png' 'metanav-contact';
$multicolor_name = 'multicolor';
$multicolor_x = 91px;
$multicolor_y = 119px;
$multicolor_offset_x = -91px;
$multicolor_offset_y = -119px;
$multicolor_width = 25px;
$multicolor_height = 25px;
$multicolor_total_width = 219px;
$multicolor_total_height = 214px;
$multicolor_image = '/img/app/rwd/sprite.png';
$multicolor = 91px 119px -91px -119px 25px 25px 219px 214px '/img/app/rwd/sprite.png' 'multicolor';
$spritesheet_width = 219px;
$spritesheet_height = 214px;
$spritesheet_image = '/img/app/rwd/sprite.png';
$spritesheet_sprites = $advantage_delivery $advantage_happiness $advantage_payment $advantage_quality $carte_4_etoiles_icon $carte_bancaire_lock_icon $credit_cards $header_close $icon_addtocart_small $icon_addtocart $icon_arrow_down_medium $icon_arrow_down_small $icon_arrow_down_white_small $icon_arrow_down $icon_arrow_left_grey_small $icon_arrow_left_helline $icon_arrow_left_small $icon_arrow_left_white $icon_arrow_left $icon_arrow_right_grey_small $icon_arrow_right_small $icon_arrow_right_white_small $icon_arrow_right_white $icon_arrow_right $icon_arrow_up_grey $icon_arrow_up_medium $icon_arrow_up_small $icon_arrow_up_white_small $icon_arrow_up $icon_blog $icon_close_white $icon_direct_order $icon_facebook $icon_question $icon_search_black $icon_search_white $icon_zoom $icon_close $icon_close_green $icon_close_red $icon_close_small $icon_close_small_green $metanav_contact_mv $metanav_contact $multicolor;
$spritesheet = 219px 214px '/img/app/rwd/sprite.png' $spritesheet_sprites;

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  spriteWidth($icon_home)
}

.icon-email {
  sprite($icon_email)
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
spriteWidth($sprite) {
  width: $sprite[4];
}

spriteHeight($sprite) {
  height: $sprite[5];
}

spritePosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spriteImage($sprite) {
  background-image: url($sprite[8]);
}

sprite($sprite) {
  spriteImage($sprite)
  spritePosition($sprite)
  spriteWidth($sprite)
  spriteHeight($sprite)
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of Stylus

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

sprites($spritesheet_sprites);
*/
sprites($sprites) {
  for $sprite in $sprites {
    $sprite_name = $sprite[9];
    .{$sprite_name} {
      sprite($sprite);
    }
  }
}
