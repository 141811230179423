.material {
    /* Style Placeholders */
    /* Text inputs */
    /* Validation Sass Placeholders */
    /* Search Field */
    /* Textarea */
    /* Autocomplete */
}
.material::-webkit-input-placeholder {
    color: #d1d1d1;
}
.material:-ms-input-placeholder {
    color: #d1d1d1;
}
.material::placeholder {
    color: #d1d1d1;
}
.material input:not([type]),
.material input[type=text]:not(.browser-default),
.material input[type=password]:not(.browser-default),
.material input[type=email]:not(.browser-default),
.material input[type=url]:not(.browser-default),
.material input[type=time]:not(.browser-default),
.material input[type=date]:not(.browser-default),
.material input[type=datetime]:not(.browser-default),
.material input[type=datetime-local]:not(.browser-default),
.material input[type=tel]:not(.browser-default),
.material input[type=number]:not(.browser-default),
.material input[type=search]:not(.browser-default),
.material textarea.materialize-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #a9a9a9;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 20px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.material input:not([type]):disabled,
.material input:not([type])[readonly="readonly"],
.material input[type=text]:not(.browser-default):disabled,
.material input[type=text]:not(.browser-default)[readonly="readonly"],
.material input[type=password]:not(.browser-default):disabled,
.material input[type=password]:not(.browser-default)[readonly="readonly"],
.material input[type=email]:not(.browser-default):disabled,
.material input[type=email]:not(.browser-default)[readonly="readonly"],
.material input[type=url]:not(.browser-default):disabled,
.material input[type=url]:not(.browser-default)[readonly="readonly"],
.material input[type=time]:not(.browser-default):disabled,
.material input[type=time]:not(.browser-default)[readonly="readonly"],
.material input[type=date]:not(.browser-default):disabled,
.material input[type=date]:not(.browser-default)[readonly="readonly"],
.material input[type=datetime]:not(.browser-default):disabled,
.material input[type=datetime]:not(.browser-default)[readonly="readonly"],
.material input[type=datetime-local]:not(.browser-default):disabled,
.material input[type=datetime-local]:not(.browser-default)[readonly="readonly"],
.material input[type=tel]:not(.browser-default):disabled,
.material input[type=tel]:not(.browser-default)[readonly="readonly"],
.material input[type=number]:not(.browser-default):disabled,
.material input[type=number]:not(.browser-default)[readonly="readonly"],
.material input[type=search]:not(.browser-default):disabled,
.material input[type=search]:not(.browser-default)[readonly="readonly"],
.material textarea.materialize-textarea:disabled,
.material textarea.materialize-textarea[readonly="readonly"] {
    color: rgba(0, 0, 0, 0.42);
    border-bottom: 1px dotted #eeeeee;
}
.material input:not([type]):disabled + label,
.material input:not([type])[readonly="readonly"] + label,
.material input[type=text]:not(.browser-default):disabled + label,
.material input[type=text]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=password]:not(.browser-default):disabled + label,
.material input[type=password]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=email]:not(.browser-default):disabled + label,
.material input[type=email]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=url]:not(.browser-default):disabled + label,
.material input[type=url]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=time]:not(.browser-default):disabled + label,
.material input[type=time]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=date]:not(.browser-default):disabled + label,
.material input[type=date]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=datetime]:not(.browser-default):disabled + label,
.material input[type=datetime]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=datetime-local]:not(.browser-default):disabled + label,
.material input[type=datetime-local]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=tel]:not(.browser-default):disabled + label,
.material input[type=tel]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=number]:not(.browser-default):disabled + label,
.material input[type=number]:not(.browser-default)[readonly="readonly"] + label,
.material input[type=search]:not(.browser-default):disabled + label,
.material input[type=search]:not(.browser-default)[readonly="readonly"] + label,
.material textarea.materialize-textarea:disabled + label,
.material textarea.materialize-textarea[readonly="readonly"] + label {
    color: rgba(0, 0, 0, 0.42);
}
.material input:not([type]):focus:not([readonly]),
.material input[type=text]:not(.browser-default):focus:not([readonly]),
.material input[type=password]:not(.browser-default):focus:not([readonly]),
.material input[type=email]:not(.browser-default):focus:not([readonly]),
.material input[type=url]:not(.browser-default):focus:not([readonly]),
.material input[type=time]:not(.browser-default):focus:not([readonly]),
.material input[type=date]:not(.browser-default):focus:not([readonly]),
.material input[type=datetime]:not(.browser-default):focus:not([readonly]),
.material input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
.material input[type=tel]:not(.browser-default):focus:not([readonly]),
.material input[type=number]:not(.browser-default):focus:not([readonly]),
.material input[type=search]:not(.browser-default):focus:not([readonly]),
.material textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #7db9f6;
    -webkit-box-shadow: 0 1px 0 0 #7db9f6;
    box-shadow: 0 1px 0 0 #7db9f6;
}
.material input:not([type]):focus:not([readonly]) + label,
.material input[type=text]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=password]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=email]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=url]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=time]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=date]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=number]:not(.browser-default):focus:not([readonly]) + label,
.material input[type=search]:not(.browser-default):focus:not([readonly]) + label,
.material textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #7db9f6;
}
.material input:not([type]).validate + label,
.material input[type=text]:not(.browser-default).validate + label,
.material input[type=password]:not(.browser-default).validate + label,
.material input[type=email]:not(.browser-default).validate + label,
.material input[type=url]:not(.browser-default).validate + label,
.material input[type=time]:not(.browser-default).validate + label,
.material input[type=date]:not(.browser-default).validate + label,
.material input[type=datetime]:not(.browser-default).validate + label,
.material input[type=datetime-local]:not(.browser-default).validate + label,
.material input[type=tel]:not(.browser-default).validate + label,
.material input[type=number]:not(.browser-default).validate + label,
.material input[type=search]:not(.browser-default).validate + label,
.material textarea.materialize-textarea.validate + label {
    width: 100%;
}
.material input:not([type]).invalid + label:after,
.material input:not([type]).valid + label:after,
.material input[type=text]:not(.browser-default).invalid + label:after,
.material input[type=text]:not(.browser-default).valid + label:after,
.material input[type=password]:not(.browser-default).invalid + label:after,
.material input[type=password]:not(.browser-default).valid + label:after,
.material input[type=email]:not(.browser-default).invalid + label:after,
.material input[type=email]:not(.browser-default).valid + label:after,
.material input[type=url]:not(.browser-default).invalid + label:after,
.material input[type=url]:not(.browser-default).valid + label:after,
.material input[type=time]:not(.browser-default).invalid + label:after,
.material input[type=time]:not(.browser-default).valid + label:after,
.material input[type=date]:not(.browser-default).invalid + label:after,
.material input[type=date]:not(.browser-default).valid + label:after,
.material input[type=datetime]:not(.browser-default).invalid + label:after,
.material input[type=datetime]:not(.browser-default).valid + label:after,
.material input[type=datetime-local]:not(.browser-default).invalid + label:after,
.material input[type=datetime-local]:not(.browser-default).valid + label:after,
.material input[type=tel]:not(.browser-default).invalid + label:after,
.material input[type=tel]:not(.browser-default).valid + label:after,
.material input[type=number]:not(.browser-default).invalid + label:after,
.material input[type=number]:not(.browser-default).valid + label:after,
.material input[type=search]:not(.browser-default).invalid + label:after,
.material input[type=search]:not(.browser-default).valid + label:after,
.material textarea.materialize-textarea.invalid + label:after,
.material textarea.materialize-textarea.valid + label:after {
    display: none;
}
.material input:not([type]).invalid + label.active:after,
.material input:not([type]).valid + label.active:after,
.material input[type=text]:not(.browser-default).invalid + label.active:after,
.material input[type=text]:not(.browser-default).valid + label.active:after,
.material input[type=password]:not(.browser-default).invalid + label.active:after,
.material input[type=password]:not(.browser-default).valid + label.active:after,
.material input[type=email]:not(.browser-default).invalid + label.active:after,
.material input[type=email]:not(.browser-default).valid + label.active:after,
.material input[type=url]:not(.browser-default).invalid + label.active:after,
.material input[type=url]:not(.browser-default).valid + label.active:after,
.material input[type=time]:not(.browser-default).invalid + label.active:after,
.material input[type=time]:not(.browser-default).valid + label.active:after,
.material input[type=date]:not(.browser-default).invalid + label.active:after,
.material input[type=date]:not(.browser-default).valid + label.active:after,
.material input[type=datetime]:not(.browser-default).invalid + label.active:after,
.material input[type=datetime]:not(.browser-default).valid + label.active:after,
.material input[type=datetime-local]:not(.browser-default).invalid + label.active:after,
.material input[type=datetime-local]:not(.browser-default).valid + label.active:after,
.material input[type=tel]:not(.browser-default).invalid + label.active:after,
.material input[type=tel]:not(.browser-default).valid + label.active:after,
.material input[type=number]:not(.browser-default).invalid + label.active:after,
.material input[type=number]:not(.browser-default).valid + label.active:after,
.material input[type=search]:not(.browser-default).invalid + label.active:after,
.material input[type=search]:not(.browser-default).valid + label.active:after,
.material textarea.materialize-textarea.invalid + label.active:after,
.material textarea.materialize-textarea.valid + label.active:after {
    display: block;
}
.material input.valid:not([type]),
.material input.valid:not([type]):focus,
.material input[type=text].valid:not(.browser-default),
.material input[type=text].valid:not(.browser-default):focus,
.material input[type=password].valid:not(.browser-default),
.material input[type=password].valid:not(.browser-default):focus,
.material input[type=email].valid:not(.browser-default),
.material input[type=email].valid:not(.browser-default):focus,
.material input[type=url].valid:not(.browser-default),
.material input[type=url].valid:not(.browser-default):focus,
.material input[type=time].valid:not(.browser-default),
.material input[type=time].valid:not(.browser-default):focus,
.material input[type=date].valid:not(.browser-default),
.material input[type=date].valid:not(.browser-default):focus,
.material input[type=datetime].valid:not(.browser-default),
.material input[type=datetime].valid:not(.browser-default):focus,
.material input[type=datetime-local].valid:not(.browser-default),
.material input[type=datetime-local].valid:not(.browser-default):focus,
.material input[type=tel].valid:not(.browser-default),
.material input[type=tel].valid:not(.browser-default):focus,
.material input[type=number].valid:not(.browser-default),
.material input[type=number].valid:not(.browser-default):focus,
.material input[type=search].valid:not(.browser-default),
.material input[type=search].valid:not(.browser-default):focus,
.material textarea.materialize-textarea.valid,
.material textarea.materialize-textarea.valid:focus,
.material .select-wrapper.valid > input.select-dropdown {
    border-bottom: 1px solid #4CAF50;
    -webkit-box-shadow: 0 1px 0 0 #4CAF50;
    box-shadow: 0 1px 0 0 #4CAF50;
}
.material input.invalid:not([type]),
.material input.invalid:not([type]):focus,
.material input[type=text].invalid:not(.browser-default),
.material input[type=text].invalid:not(.browser-default):focus,
.material input[type=password].invalid:not(.browser-default),
.material input[type=password].invalid:not(.browser-default):focus,
.material input[type=email].invalid:not(.browser-default),
.material input[type=email].invalid:not(.browser-default):focus,
.material input[type=url].invalid:not(.browser-default),
.material input[type=url].invalid:not(.browser-default):focus,
.material input[type=time].invalid:not(.browser-default),
.material input[type=time].invalid:not(.browser-default):focus,
.material input[type=date].invalid:not(.browser-default),
.material input[type=date].invalid:not(.browser-default):focus,
.material input[type=datetime].invalid:not(.browser-default),
.material input[type=datetime].invalid:not(.browser-default):focus,
.material input[type=datetime-local].invalid:not(.browser-default),
.material input[type=datetime-local].invalid:not(.browser-default):focus,
.material input[type=tel].invalid:not(.browser-default),
.material input[type=tel].invalid:not(.browser-default):focus,
.material input[type=number].invalid:not(.browser-default),
.material input[type=number].invalid:not(.browser-default):focus,
.material input[type=search].invalid:not(.browser-default),
.material input[type=search].invalid:not(.browser-default):focus,
.material textarea.materialize-textarea.invalid,
.material textarea.materialize-textarea.invalid:focus,
.material .select-wrapper.invalid > input.select-dropdown {
    border-bottom: 1px solid #F44336;
    -webkit-box-shadow: 0 1px 0 0 #F44336;
    box-shadow: 0 1px 0 0 #F44336;
}
.material input:not([type]).valid + label:after,
.material input:not([type]):focus.valid + label:after,
.material input[type=text]:not(.browser-default).valid + label:after,
.material input[type=text]:not(.browser-default):focus.valid + label:after,
.material input[type=password]:not(.browser-default).valid + label:after,
.material input[type=password]:not(.browser-default):focus.valid + label:after,
.material input[type=email]:not(.browser-default).valid + label:after,
.material input[type=email]:not(.browser-default):focus.valid + label:after,
.material input[type=url]:not(.browser-default).valid + label:after,
.material input[type=url]:not(.browser-default):focus.valid + label:after,
.material input[type=time]:not(.browser-default).valid + label:after,
.material input[type=time]:not(.browser-default):focus.valid + label:after,
.material input[type=date]:not(.browser-default).valid + label:after,
.material input[type=date]:not(.browser-default):focus.valid + label:after,
.material input[type=datetime]:not(.browser-default).valid + label:after,
.material input[type=datetime]:not(.browser-default):focus.valid + label:after,
.material input[type=datetime-local]:not(.browser-default).valid + label:after,
.material input[type=datetime-local]:not(.browser-default):focus.valid + label:after,
.material input[type=tel]:not(.browser-default).valid + label:after,
.material input[type=tel]:not(.browser-default):focus.valid + label:after,
.material input[type=number]:not(.browser-default).valid + label:after,
.material input[type=number]:not(.browser-default):focus.valid + label:after,
.material input[type=search]:not(.browser-default).valid + label:after,
.material input[type=search]:not(.browser-default):focus.valid + label:after,
.material textarea.materialize-textarea.valid + label:after,
.material textarea.materialize-textarea:focus.valid + label:after,
.material .select-wrapper.valid + label:after {
    content: attr(data-success);
    color: #4CAF50;
    opacity: 1;
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
}
.material input:not([type]).invalid + label:after,
.material input:not([type]):focus.invalid + label:after,
.material input[type=text]:not(.browser-default).invalid + label:after,
.material input[type=text]:not(.browser-default):focus.invalid + label:after,
.material input[type=password]:not(.browser-default).invalid + label:after,
.material input[type=password]:not(.browser-default):focus.invalid + label:after,
.material input[type=email]:not(.browser-default).invalid + label:after,
.material input[type=email]:not(.browser-default):focus.invalid + label:after,
.material input[type=url]:not(.browser-default).invalid + label:after,
.material input[type=url]:not(.browser-default):focus.invalid + label:after,
.material input[type=time]:not(.browser-default).invalid + label:after,
.material input[type=time]:not(.browser-default):focus.invalid + label:after,
.material input[type=date]:not(.browser-default).invalid + label:after,
.material input[type=date]:not(.browser-default):focus.invalid + label:after,
.material input[type=datetime]:not(.browser-default).invalid + label:after,
.material input[type=datetime]:not(.browser-default):focus.invalid + label:after,
.material input[type=datetime-local]:not(.browser-default).invalid + label:after,
.material input[type=datetime-local]:not(.browser-default):focus.invalid + label:after,
.material input[type=tel]:not(.browser-default).invalid + label:after,
.material input[type=tel]:not(.browser-default):focus.invalid + label:after,
.material input[type=number]:not(.browser-default).invalid + label:after,
.material input[type=number]:not(.browser-default):focus.invalid + label:after,
.material input[type=search]:not(.browser-default).invalid + label:after,
.material input[type=search]:not(.browser-default):focus.invalid + label:after,
.material textarea.materialize-textarea.invalid + label:after,
.material textarea.materialize-textarea:focus.invalid + label:after,
.material .select-wrapper.invalid + label:after {
    content: attr(data-error);
    color: #F44336;
    opacity: 1;
    -webkit-transform: translateY(9px);
    transform: translateY(9px);
}
.material input:not([type]) + label:after,
.material input[type=text]:not(.browser-default) + label:after,
.material input[type=password]:not(.browser-default) + label:after,
.material input[type=email]:not(.browser-default) + label:after,
.material input[type=url]:not(.browser-default) + label:after,
.material input[type=time]:not(.browser-default) + label:after,
.material input[type=date]:not(.browser-default) + label:after,
.material input[type=datetime]:not(.browser-default) + label:after,
.material input[type=datetime-local]:not(.browser-default) + label:after,
.material input[type=tel]:not(.browser-default) + label:after,
.material input[type=number]:not(.browser-default) + label:after,
.material input[type=search]:not(.browser-default) + label:after,
.material textarea.materialize-textarea + label:after,
.material .select-wrapper + label:after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    -webkit-transition: .2s opacity ease-out, .2s color ease-out;
    transition: .2s opacity ease-out, .2s color ease-out;
}
.material .input-field {
    position: relative;
    margin-top: 1rem;
}
.material .input-field.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}
.material .input-field.inline input,
.material .input-field.inline .select-dropdown {
    margin-bottom: 1rem;
}
.material .input-field.col label {
    left: 0.75rem;
}
.material .input-field.col .prefix ~ label,
.material .input-field.col .prefix ~ .validate ~ label {
    width: calc(100% - 3rem - 1.5rem);
}
.material .input-field label {
    color: #9e9e9e;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 1rem;
    cursor: text;
    -webkit-transition: -webkit-transform .2s ease-out;
    transition: -webkit-transform .2s ease-out;
    transition: transform .2s ease-out;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    text-align: initial;
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
    pointer-events: none;
}
.material .input-field label:not(.label-icon).active {
    -webkit-transform: translateY(-14px) scale(0.8);
    transform: translateY(-14px) scale(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
.material .input-field .prefix {
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    -webkit-transition: color .2s;
    transition: color .2s;
}
.material .input-field .prefix.active {
    color: #26a69a;
}
.material .input-field .prefix ~ input,
.material .input-field .prefix ~ textarea,
.material .input-field .prefix ~ label,
.material .input-field .prefix ~ .validate ~ label,
.material .input-field .prefix ~ .autocomplete-content {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
}
.material .input-field .prefix ~ label {
    margin-left: 3rem;
}
@media only screen and (max-width: 992px) {
    .material .input-field .prefix ~ input {
        width: 86%;
        width: calc(100% - 3rem);
    }
}
@media only screen and (max-width: 600px) {
    .material .input-field .prefix ~ input {
        width: 80%;
        width: calc(100% - 3rem);
    }
}
.material .input-field input[type=search] {
    display: block;
    line-height: inherit;
}
.nav-wrapper .material .input-field input[type=search] {
    height: inherit;
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.material .input-field input[type=search]:focus {
    background-color: #fff;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #444;
}
.material .input-field input[type=search]:focus + label i,
.material .input-field input[type=search]:focus ~ .mdi-navigation-close,
.material .input-field input[type=search]:focus ~ .material-icons {
    color: #444;
}
.material .input-field input[type=search] + label {
    left: 1rem;
}
.material .input-field input[type=search] ~ .mdi-navigation-close,
.material .input-field input[type=search] ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    -webkit-transition: .3s color;
    transition: .3s color;
}
.material textarea {
    width: 100%;
    height: 3rem;
    background-color: transparent;
}
.material textarea.materialize-textarea {
    overflow-y: hidden;
    /* prevents scroll bar flash */
    
    padding: .8rem 0 1.6rem 0;
    /* prevents text jump on Enter keypress */
    
    resize: none;
    min-height: 3rem;
}
.material textarea.materialize-textarea.validate + label {
    height: 100%;
}
.material textarea.materialize-textarea.validate + label::after {
    top: calc(100% - 12px);
}
.material textarea.materialize-textarea.validate + label:not(.label-icon).active {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
}
.material .hiddendiv {
    display: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /* future version of deprecated 'word-wrap' */
    
    padding-top: 1.2rem;
    /* prevents text jump on Enter keypress */
    
    position: absolute;
    top: 0;
}
.material .autocomplete-content {
    margin-top: -20px;
    margin-bottom: 20px;
    display: block;
    opacity: 1;
    position: static;
}
.material .autocomplete-content li .highlight {
    color: #444;
}
.material .autocomplete-content li img {
    height: 40px;
    width: 40px;
    margin: 5px 15px;
}
.material select {
    display: none;
}
.material select.browser-default {
    display: block;
}
.material select {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
}
.material .input-field > select {
    display: block;
    position: absolute;
    width: 0;
    pointer-events: none;
    height: 0;
    top: 0;
    left: 0;
    opacity: 0;
}
.material .select-label {
    position: absolute;
}
.material .select-wrapper {
    position: relative;
}
.material .select-wrapper.valid + label,
.material .select-wrapper.invalid + label {
    width: 100%;
    pointer-events: none;
}
.material .select-wrapper input.select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 20px 0;
    padding: 0;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.material .select-wrapper span.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 10px;
    margin: auto 0;
    font-size: 10px;
    line-height: 10px;
}
.material .select-wrapper + label {
    position: absolute;
    top: -26px;
    font-size: 0.8rem;
}
.material select:disabled {
    color: rgba(0, 0, 0, 0.42);
}
.material .select-wrapper.disabled span.caret,
.material .select-wrapper.disabled + label {
    color: rgba(0, 0, 0, 0.42);
}
.material .select-wrapper input.select-dropdown:disabled {
    color: rgba(0, 0, 0, 0.42);
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.material .select-wrapper i {
    color: rgba(0, 0, 0, 0.3);
}
.material .select-dropdown li.disabled,
.material .select-dropdown li.disabled > span,
.material .select-dropdown li.optgroup {
    color: rgba(0, 0, 0, 0.3);
    background-color: transparent;
}
.material .select-dropdown.dropdown-content li.active {
    background-color: transparent;
}
.material .select-dropdown.dropdown-content li:hover {
    background-color: rgba(0, 0, 0, 0.06);
}
.material .select-dropdown.dropdown-content li.selected {
    background-color: rgba(0, 0, 0, 0.03);
}
.material .prefix ~ .select-wrapper {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
}
.material .prefix ~ label {
    margin-left: 3rem;
}
.material .select-dropdown li img {
    height: 40px;
    width: 40px;
    margin: 5px 15px;
    float: right;
}
.material .select-dropdown li.optgroup {
    border-top: 1px solid #eee;
}
.material .select-dropdown li.optgroup.selected > span {
    color: rgba(0, 0, 0, 0.7);
}
.material .select-dropdown li.optgroup > span {
    color: rgba(0, 0, 0, 0.4);
}
.material .select-dropdown li.optgroup ~ li.optgroup-option {
    padding-left: 1rem;
}
.material .dropdown-content {
    background-color: #fff;
    margin: 0;
    display: none;
    min-width: 100px;
    max-height: 650px;
    overflow-y: auto;
    opacity: 0;
    position: absolute;
    z-index: 999;
    will-change: width, height;
}
.material .dropdown-content li {
    list-style-type: none;
    clear: both;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    min-height: 50px;
    line-height: 1.5rem;
    width: 100%;
    text-align: left;
    text-transform: none;
}
.material .dropdown-content li:hover,
.material .dropdown-content li.active,
.material .dropdown-content li.selected {
    background-color: #eee;
}
.material .dropdown-content li.active.selected {
    background-color: #e1e1e1;
}
.material .dropdown-content li.divider {
    min-height: 0;
    height: 1px;
}
.material .dropdown-content li > a,
.material .dropdown-content li > span {
    font-size: 16px;
    color: #26a69a;
    display: block;
    line-height: 22px;
    padding: 14px 16px;
}
.material .dropdown-content li > span > label {
    top: 1px;
    left: 0;
    height: 18px;
}
.material .dropdown-content li > a > i {
    height: inherit;
    line-height: inherit;
    float: left;
    margin: 0 24px 0 0;
    width: 24px;
}
.material .input-field.col .dropdown-content [type="checkbox"] + label {
    top: 1px;
    left: 0;
    height: 18px;
}
.material input[type="radio"]:not(:checked),
.material input[type="radio"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.material input[type="radio"]:not(:checked) + label,
.material input[type="radio"]:checked + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    transition: .28s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.material input[type="radio"] + label:before,
.material input[type="radio"] + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: .28s ease;
}
.material input[type="radio"]:not(:checked) + label:before,
.material input[type="radio"]:not(:checked) + label:after,
.material input[type="radio"]:checked + label:before,
.material input[type="radio"]:checked + label:after,
.material input[type="radio"].with-gap:checked + label:before,
.material input[type="radio"].with-gap:checked + label:after {
    border-radius: 50%;
}
.material input[type="radio"]:not(:checked) + label:before,
.material input[type="radio"]:not(:checked) + label:after {
    border: 2px solid #5a5a5a;
}
.material input[type="radio"]:not(:checked) + label:after {
    -webkit-transform: scale(0);
    transform: scale(0);
}
.material input[type="radio"]:checked + label:before {
    border: 2px solid transparent;
}
.material input[type="radio"]:checked + label:after,
.material input[type="radio"].with-gap:checked + label:before,
.material input[type="radio"].with-gap:checked + label:after {
    border: 2px solid #26a69a;
}
.material input[type="radio"]:checked + label:after,
.material input[type="radio"].with-gap:checked + label:after {
    background-color: #26a69a;
}
.material input[type="radio"]:checked + label:after {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}
.material input[type="radio"].with-gap:checked + label:after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}
.material input[type="radio"].tabbed:focus + label:before {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}
.material input[type="radio"].with-gap:disabled:checked + label:before {
    border: 2px solid rgba(0, 0, 0, 0.42);
}
.material input[type="radio"].with-gap:disabled:checked + label:after {
    border: none;
    background-color: rgba(0, 0, 0, 0.42);
}
.material input[type="radio"]:disabled:not(:checked) + label:before,
.material input[type="radio"]:disabled:checked + label:before {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.42);
}
.material input[type="radio"]:disabled + label {
    color: rgba(0, 0, 0, 0.42);
}
.material input[type="radio"]:disabled:not(:checked) + label:before {
    border-color: rgba(0, 0, 0, 0.42);
}
.material input[type="radio"]:disabled:checked + label:after {
    background-color: rgba(0, 0, 0, 0.42);
    border-color: #949494;
}