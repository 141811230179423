@require 'includes/*'

.advantage
  &__icon
    display block
    margin 0 auto 10px auto

    &__payment
      sprite($advantage_payment)

    &__happiness
      sprite($advantage_happiness)

    &__delivery
      sprite($advantage_delivery)

    &__quality
      sprite($advantage_quality)

.footer
  &__advantages
    padding-top 10px
    padding-bottom 10px

  .advantage
    text-align center
    border-right 1px solid $highlight-border
    margin-bottom 20px

    @media screen and (max-width: $medium)
      padding-bottom 5px

    &:nth-child(2)
      @media screen and (max-width: $medium)
        border none

    &:last-child
      border none

    &__headline
      text-transform uppercase
      font-size 15px
      color $witt-sale-background
      line-height 18px

    &__text
      line-height 18px
      font-size 12px

      &--mobile
        display none

        @media screen and (max-width: $medium)
          display block

      &--desktop
        @media screen and (max-width: $medium)
          display none

  &__navigation
    .container
      @media screen and (max-width: $small)
        padding-right 0px
        padding-left 0px

    &__inner
      background-color $witt-footer-background
      padding 0 25px

      @media screen and (max-width: $medium)
        background-color transparent
        padding 0px

      .firstRow

        .footer__navigation__element
          min-height 200px

          @media screen and (max-width: $medium)
            min-height 0

          &__content
            ul
              li
                padding-bottom 8px

    &__element
      padding 0 10px 5px 0
      margin-top 20px

      @media screen and (max-width: $medium)
        border-bottom 1px solid $highlight-border
        padding 0px
        margin-top 0px

      &__headline
        text-transform uppercase
        font-size 15px
        line-height 15px
        padding-bottom 5px
        padding-top 5px

        @media screen and (max-width: $medium)
          padding 15px
          transition all 0.3s ease-in
          position relative

          &:after
            display block
            content ''
            sprite($icon_arrow_down)
            position absolute
            top 17px
            right 15px

        &.active
          background-color $witt-blue
          color $witt-white

          a
            color $witt-white

          &:after
            sprite($icon_arrow_up)

        &.color--red
          @media screen and (max-width: $medium)
            color $witt-black

            &.active
              color $witt-white

      &.bordered
        border-right 1px solid $witt-light-grey

        @media screen and (max-width: $medium)
          border-right none

      &__content

        @media screen and (max-width: $medium)
          display none
          padding 5px 15px 17px 15px
          font-size 13px

        ul
          list-style-type none
          margin 1em 0
          padding 0px

          li
            font-size 14px
            line-height 16px
            margin-bottom 6px

            &.small
              font-size 12px

            a,span.link
              font-size 13px
              line-height 18px

      &--highlight
        @media screen and (max-width: $medium)
          background-color $witt-footer-background
          border none

          .footer__navigation__element__headline
            border none
            padding 30px 20px 0 20px

            &:after
              display none

          .footer__navigation__element__content
            display block
            padding-bottom 10px

  .line
    border-bottom 1px solid $witt-light-grey
    margin-top 20px

  .callUs
    sprite($metanav_contact)
    display block

  .facebook
    sprite($icon_facebook)
    display block

  .blog
    sprite($icon_blog)
    display block

  .creditCards
    sprite($credit_cards)
    display block
    margin-top 10px

.subfooter
  text-align center
  font-size 12px
  padding-top 60px
  padding-bottom 20px

  .links
    margin-bottom 8px

    a,span
      margin-right 8px

      &:after
        content '|'
        margin-left 8px
      &:hover
        text-decoration: underline
