@require 'includes/*'

.mobile-navigation

  &__open
    height 36px
    width 44px
    position absolute
    left 0px
    cursor pointer
    display none
    text-align center
    background-color transparent
    border none
    outline none

    @media screen and (max-width: $large)
      display flex
      flex-direction column
      align-items center
      justify-content space-between

    &--burger
      flex 0 0 24px
      height 24px
      width 24px
      mask: url(../img/app/rwd/icons/burger.svg) no-repeat center;
      -webkit-mask: url(../img/app/rwd/icons/burger.svg) no-repeat center;
      background-position center center
      background-repeat no-repeat
      background-color #6C7275

    &--text
      font-size 11px
      line-height 13px
      color #6c7275
				
		