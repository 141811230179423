@require 'includes/*'

.dropdown
  border 1px solid $witt-light-grey3
  border-radius 5px
  width auto
  background-color $witt-white
  cursor pointer
  position unset

  &--opened
    border 1px solid $witt-black
    box-shadow 0 0 20px rgba(0, 0, 0, 0.2)
    @media up_to_M
      box-shadow none

  &__header
    display flex
    align-items center
    padding 8px 15px 8px 12px
    position relative
    background-color $witt-white
    border-radius 5px

    @media up_to_M
      border-radius 0px

    &--opened
      z-index 2

  &__title-wrapper
    display flex
    justify-content space-between
    align-items center
    width calc(100% - 16px)

  &__title
    font-size 14px
    color $witt-black
    &--invisible
      display none
  &__title-opened
    display none
    font-size 14px
    color $witt-black
    &--visible
      display block

  &__icon, &__info
    display inline-block

  &__icon
    sprite($icon_arrow_down_small)
    transition all 0.3s
    &--opened
      transform rotate(180deg)
    @media up_to_M
      &--mobile-opened
        sprite($icon_arrow_up_small)

  &__info
    color $witt-green
    padding 0 10px
    &--icon
      background-color $witt-highlight-border
      border-radius 20px
      color $witt-white
      margin 0 10px
      padding 5px 7px
      font-size 14px
      min-width 30px
      text-align center
      .dropdown__info-braket
        display none

  &__content
    display none
    position absolute
    left 0px
    margin -4px 15px 0 15px
    width calc(100% - 30px)
    border 1px solid $witt-black
    border-radius 0 0 5px 5px
    background-color #fff
    z-index 1
    padding 30px 15px 70px 12px
    box-shadow 0 0 20px rgba(0, 0, 0, 0.2)

    @media up_to_M
      box-shadow none
      margin 0px

    &--visible
      display block

    @media up_to_M
      width 100%
      margin-top 0px

      &--mobile-visible
        display block