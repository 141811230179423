@require 'includes/*'
.pager
  padding 0

  li
    display inline-block
    list-style none

  &--bottom
    text-align center
    margin-bottom 20px

  &--top
    position absolute
    bottom 14px
    right 10px
    @media M_and_up
      bottom 20px

.pager__page--previous
.pager__page--next
  display inline-block
  padding 5px
  vertical-align middle
.pager__icon--previous
  sprite($icon_arrow_left_small)
.pager__icon--next
  sprite($icon_arrow_right_small)

.pager__page
  display inline-block
  width 30px
  height 30px
  padding-top 5px
  vertical-align middle
  text-align center
  border 1px solid $witt-highlight-border
  border-radius 50%
  margin 5px

  &--current
    background-color $witt-darked-grey
    border 1px solid $witt-darked-grey
    color $witt-white