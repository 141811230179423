@require 'includes/*'

.customer-printdata
  display none
  
  &__information
    list-style none
    margin 0 0 15px 0
    padding 0

  .account__address-box
    list-style none
    margin 0 0 15px 0
    min-height 172px

@media print
  .cookie
  .pageheader
  .navigation
  #promotion-banner
  .bread-crumb
  .account__subheadline
  .account__navigation
  footer
  #settingspanel
  div[id*="tsbadge"]
  .btn-print
  .account__left-column
  .skyscraper skyscraper__left
  .skyscraper skyscraper__right
  .account__orders_help
  .account__orders_btnsection
  .account__download-print
  .account__content h1
  .account__orders_return_help
  .account__order_parcel_button
  .account__content__bordered
    display none !important

  .customer-printdata
    display block
    flex 0 0 100%
    max-width 100%
    
  .account
    &__content
      width 100%
      flex 0 0 100%
      max-width 100%
      
    &__orders
      display block
      border none
      max-width 100%
      
      &__table
        .show_details
          opacity 0