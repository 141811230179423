@require 'includes/*'

.channel__products

  .custom-dropdown-wrapper
    text-align: right

    .custom-dropdown-label
      display: inline-block
      padding: 4px 8px

    .custom-dropdown
      display: inline-block
      max-width: 140px
