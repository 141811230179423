columns( count, gap )
  -moz-column-count count
  -moz-column-gap gap
  -webkit-column-count count
  -webkit-column-gap gap
  column-count count
  column-gap gap
inline-block()
  display inline-block

reset-button-style()
  -webkit-appearance none !important
  cursor pointer
  border none
  background-color transparent
  padding 0
  color inherit

.stay-connected-checkbox-container

    &__text
      cursor pointer

.stay-connected-text-container
  margin-top -12px
  margin-down 25px
  background-color #E5ECFF
  padding 8px 16px

  &.inactive
    display none

.mixin-checkbox-container
  display flex
  justify-content space-between
  align-items: center
  margin-top 24px
  margin-bottom 20px

.checkbox-with-label-container
  display flex
  justify-content space-between
  align-items center
  margin-top 0

  label
    margin-bottom 0
    cursor pointer

  input
    opacity 0
    position absolute
    cursor pointer

    &[type=checkbox]
      & + .box
        border 1px solid $helline-dark-blue
        width 18px
        height 18px
        display block
        cursor pointer
        margin-bottom 0
        margin-right 5px

      &:checked
        & + .box
          background url('/img/app/rwd/helline/adress_radio_button.jpg') center center no-repeat !important
          background-color $helline-dark-blue

