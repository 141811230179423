@require 'includes/*'
@require 'products-column-switch.styl'

.product-traceability-layer-content
  @media M_and_up
    margin-top 8px

  &__inner
    display flex
    column-gap 20px

    @media up_to_M
      flex-direction column

  &__image-and-info
    @media M_and_up
      flex 0 0 230px

  &__line
    margin 24px 0
    border-top 1px solid rgba(227, 227, 227, 1)

    &--mobile
      @media M_and_up
        display none

  &__article-picture
    @media up_to_M
      display none

    img.responsive.productImage
      max-width 100%
      height auto
      margin-bottom 8px

  &__article-name
    font-weight 800
    font-size 14px
    line-height 18.2px

  &__article-brand
    font-weight 400
    font-size 14px
    line-height 18.2px

  &__article-reference
    font-weight 400
    font-size 12px
    line-height 14.4px
    color $helline-grey

  &__details
    flex 1 1 auto

    &-list
      padding-left 25px

    &-headline
      strong
        font-weight 700
        font-size 16px

      ul, p
        font-weight 400
        font-size 14px
        line-height 18.2px
        margin 0
        color $helline-dark-blue !important

      ul li
        &:not(:last-child)
          padding-bottom 4px

    &-recycling-image__container
      padding 10px 20px
      margin-top 16px
      width 152px
      height 66px
      border solid 1px $helline-grey-light
      border-radius 8px
      display flex
      align-items center

    &-recycling-image
      background-image url(../img/app/rwd/traceability-recycle-icon.svg)
      display inline-block
      width 112px
      height 56px
      background-repeat no-repeat
      background-size auto 100%

      a
        display inline-block
        width 112px
        height 56px

  .address-box
    margin-top 16px
    border-radius 8px
    padding 16px 24px
    background-color $helline-grey-light2

    &__name
      text-transform uppercase
      font-weight 800
      font-size 16px
      line-height 20.8px

    &__detail
      text-transform uppercase
      font-size 14px
      line-height 18.2px

    &__mail
      margin-top 8px
      a
        font-size 14px
        color $helline-dark-blue
        text-decoration underline

        &:hover
          text-decoration none

#productTraceabilityResult
  display none
