@require '../includes/*'

#helline
  .toast
    color $helline-dark-blue
    box-shadow 0 0 16px 0 rgba($helline-dark-blue, .16)
    border-radius 0

    &:hover
      color $helline-dark-blue

    &__icon
      &-check
        background-image url(../img/app/rwd/helline/check-bold.svg)

    &__links
      &__close
        color $helline-grey

      &__action
        color $helline-dark-blue