@require 'includes/*'

.radiobutton
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: none;
  border: 1px solid #919191;

.active

  .radiobutton
    border 3px solid $witt-white
    box-shadow 0 0 1px 1px #333333
    background-color #333333



    // helline
//.payment__tab__checkbox
//border 4px solid white
//box-shadow 0 0 1px 1px $helline-dark-blue
//background-color $helline-dark-blue