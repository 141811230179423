@require '../../../styles/includes/*'

.linkbox
    &__header
        &--text
            font-size 19px
            color $witt-black
            padding 5px 20px
    &__content
        margin 0 0 10px 0
        border 1px solid $highlight-border

.linkbox__container
    border-bottom 1px solid $witt-darked-grey
    padding 0 0 25px 0
    margin-bottom 30px
    .linkbox
        &__header
            font-size 13px
            color $witt-black
            padding 0 5px
        &__content
            margin 0
            border none
            overflow hidden
            max-height 50px
        &__entry
            margin-right 10px
            &:last-child
                margin-right 0

    @media M_and_up
        border-top 1px solid $witt-darked-grey
        padding 28px 0 25px 0