@require 'includes/*'

.icon
  &__size
    &--XS
      transform scale(.67)
      width 24px
      height 24px

    &--S
      transform scale(1)
      width 24px
      height 24px

    &--M
      transform scale(1.5)
      width 33px
      height 33px

    &--L
      transform scale(2)
      width 48px
      height 48px

  &__color--inverted
    background-color #fff !important

  &__panier
    mask: url(../img/app/rwd/icons/basket-header.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/basket-header.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color #6C7275

  &__basket
    mask: url(../img/app/rwd/icons/basket.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/basket.svg) no-repeat center;

  // START -- replace this block after merging the CSS branch and may use the icon there and delete this one
  &__attention--tbd
    mask: url(../img/app/rwd/icons/attention1.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/attention1.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color black
  &__branch-offices--tbd
    mask: url(../img/app/rwd/icons/branch_offices1.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/branch_offices1.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color black
  &__clock-tbd
    mask: url(../img/app/rwd/icons/clock1.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/clock1.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color black
  &__color--error--tbd
    background-color #e90037
  // END --

  &__compte
    mask: url(../img/app/rwd/icons/customer.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/customer.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color #6C7275

  &__compte
    &--logged-in
      background-image url(../img/app/rwd/icons/customer.svg)
      width 32px
      height 31px
      background-repeat no-repeat
      background-size auto 100%

      &:after
        content ''
        display block
        width 14px
        height 14px
        background-color $witt-green
        border-radius 50%
        position absolute
        right -3px
        top 2px
        background-image url(/img/app/rwd/check-white.svg)
        background-size 65% auto
        background-position center center
        background-repeat no-repeat

  &__commande_directe
    mask: url(../img/app/rwd/icons/order_commande.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/order_commande.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color #6C7275

  &__logout
    background-image url(../img/app/rwd/icons/logout.svg)
    width 19px
    height 19px
    background-repeat no-repeat

  &__search
    background-image url(../img/app/rwd/icons/glass.svg)
    background-position center
    background-repeat no-repeat
    background-size contain
    width 28px
    height 28px

  &__info
    background-image url(../img/app/rwd/icons/info.svg)
    background-position center
    background-repeat no-repeat
    background-size contain
    width 20px
    height 20px
    cursor pointer

  &__check-circle
    background-image url(../img/app/rwd/icons/check-circle.svg)
    background-position center
    background-repeat no-repeat
    background-size contain
    width 18px
    height 18px

  &__question
    background-image url(../img/app/rwd/icon-question.svg)
    background-position center
    background-repeat no-repeat
    background-size contain
    width 23px
    height 23px

  &__wishlist
    display inline-block
    vertical-align middle
    background-image url(/img/app/rwd/icon-add-wishlist.svg)
    background-position center
    background-size contain
    background-repeat no-repeat
    width 32px
    height 32px
    margin-right 12px
    
    &.active, &:hover
      background-image url(../img/app/rwd/icon-add-wishlist-active.svg)
    
  &__wishlist-header
    mask: url(../img/app/rwd/icons/heart.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/icons/heart.svg) no-repeat center;
    background-position center center
    background-repeat no-repeat
    background-color #6C7275

  &__arrow-left
    sprite($icon_arrow_left)

  &__arrow-right--white
    sprite($icon_arrow_right_white)
    margin-left 10px
    margin-top 9px

  &__arrow-left--white
    sprite($icon_arrow_left_white)
    margin-right 10px
    margin-top 9px

  &__premium-flag     //  get rid of premium flag after merging with styleguide as it is duplicated
    background-image url(../img/app/rwd/helline/premium/premium-flag.svg)
    background-color unset !important
    height 24px
    width 81px
    background-size contain
    background-repeat no-repeat

  &__colissimo
    inline-block()
    vertical-align middle
    margin-left 8px
    margin-top -3px
    width 16px
    height 16px
    background-image url(../img/app/rwd/icon-shipping-colissimo.svg)
    background-size contain
    background-repeat no-repeat

  &__mondial-relay
    inline-block()
    vertical-align middle
    margin-left 8px
    margin-top -3px
    width 16px
    height 16px
    background-image url(../img/app/rwd/icon-shipping-relay.svg)
    background-size contain
    background-repeat no-repeat

  &__la-poste
    inline-block()
    vertical-align middle
    margin-left 8px
    width 16px
    height 16px
    background-image url(../img/app/rwd/icon-shipping-domicile.svg)
    background-size contain
    background-repeat no-repeat

  // START -- replace this block after merging the CSS branch and may use the icon there and delete this one
  &__premium-logo
    background-image url(../img/app/rwd/helline/premium/premium-logo.svg)
    background-color transparent
    height 24px
    width 188px
    background-size contain
    background-repeat no-repeat

  &__premium-logo-stacked
    background-image url(../img/app/rwd/helline/premium/premium-logo-stacked.svg)
    background-color transparent
    height 25px
    width 62px
    background-size contain
    background-repeat no-repeat

  &__premium-crown-big
    background-image url(../img/app/rwd/helline/premium/premium-crown-big.svg)
    background-color transparent
    height 100px
    width 200px
    background-size contain
    background-repeat no-repeat

  &__premium-crown
    mask: url(../img/app/rwd/helline/premium/premium-crown.svg) no-repeat center;
    -webkit-mask: url(../img/app/rwd/helline/premium/premium-crown.svg) no-repeat center;

  // END

.icon--block
  display block
  position relative

.icon--inline-block
  display inline-block
  position relative
  vertical-align top

.icon--center
  margin auto

i.addtocart
  &--icon
    width 35px
    height 35px
    background-image url(../img/app/rwd/helline/add-to-basket.svg)
    display inline-block
    background-repeat no-repeat
    background-position center center

  &--small
    sprite($icon_addtocart_small)
    inline-block()
    vertical-align middle
    margin-right 10px

.add-to-basket-button__icon
  margin-right 10px

.header-icon-with-number
  display flex
  position relative