@require 'includes/*'

.recommendations
  ul
    list-style-type none
    margin 0px
    padding 0px
    width 100%

  &__item
    width 25%
    margin 0px
    inline-block()
    vertical-align top
    text-align center
    padding 0px 5px

    &:first-child
      padding-left 0px

    &:last-child
      padding-right 0px

    &__details
      border-top 1px solid $witt-light-grey
      padding-top 3px

    &__name
      font-weight bold
      color $witt-black
      font-size 12px
      line-height 14px

    &__price-message
      font-size 10px
      color $witt-darked-grey

      strong
        font-size 12px
        font-weight 600

    a
      cursor pointer