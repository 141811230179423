@require 'includes/*'

.newsletter
  margin-bottom 50px
  @media L_and_up
    min-height 200px

  &__header
    margin-bottom 20px

  &__header,
  &__unsubscribe-content,
  &__indent
    margin-left 15px
    margin-right 15px

  .newsletter-option
    margin-top 15px

    label
      cursor pointer

    span.afterCheckmark
      vertical-align text-bottom

    input[type="checkbox"]
      margin-right 0
      opacity 0

    .checkmark
      border 1px solid $witt-black
      height 15px
      width 15px
      margin-top 3px
      margin-right 10px
      display inline-block

    .checkmark:after
      content ""
      display none
      margin-left 5px
      width 6px
      margin-top -4px
      height 12px
      border solid $witt-black
      border-width 0 2px 2px 0
      -webkit-transform rotate(45deg)
      -ms-transform rotate(45deg)
      transform rotate(45deg)

    input:checked ~ .checkmark:after
      display block

  &__text
    font-size 14px
    margin-bottom 20px
    &--unsubscribe
      margin-top 20px

  &__radio-buttons
    margin-bottom 20px
    
  &__form
    height 420px
    margin-top -400px
    
    @media screen and (max-width: $medium)
      height 200px
      margin-top -180px
    
  &__button
    margin-top: 40px




.newsletter-flyout-container, .homepage-intro-layer-container
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  background-color rgba($witt-black, 0.7)
  display none

  &.open
    display flex
    justify-content center
    align-items center

.newsletter-flyout-confirm
  display none

.newsletter-flyout-container

  @media screen and (max-width: $medium)
    display none!important

.homepage-intro-layer-container
  z-index 999999999

  &.open
    display block
