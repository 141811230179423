@require 'includes/*'

.color-selection
  text-align left
  margin 24px 0

  &__selected
    font-size 16px

  ul
    list-style-type none
    margin 0 -10px
    padding 0
    display flex
    flex-wrap wrap
    align-items center
    min-height 58px

    li
      margin 10px 5px
      position relative
      display inline-block
      cursor pointer
      padding 5px
      border 1px solid transparent
      transition all 0.1s ease-in-out
      border-radius 50%

      @media screen and (max-width: $medium)
        margin 10px 5px

      &.selected
        border-color $witt-very-dark-grey

        .color-tile
          > span
            transform scale(1.167)

      &:hover
        border-color $witt-very-dark-grey

        .color-tile
          > span
            transform scale(1.167)

      &[data-text]
        &:hover
          &:after
            content attr(data-text)
            position absolute
            left 0
            border 1px solid $witt-black
            top 40px
            padding 6px 8px
            font-size 12px
            line-height 14px
            display inline-block
            z-index 999
            background-color $witt-white
            margin-left -5px


  .color-tile
    > span
      display block
      border 1px solid $highlight-border
      border-radius 50%
      height 24px
      width 24px
      transition all 0.1s ease-in-out

      @media screen and (max-width: $medium)
        height 22px
        width 22px

  @media screen and (min-width: $medium)
    &__color
      display none !important

    &__showMore
      cursor pointer
      font-size 14px
      font-weight 700
      text-decoration underline
      color $helline-dark-blue
      display none

    &__show
      display block !important

    &__hide
      display none !important


  @media screen and (max-width: $medium)
    &__showMore
      display none