@require 'includes/*'

.navigation
  background-color $witt-white
  height 37px // related to the top value of .navigation__list__flyout
  width 100%
  overflow hidden
  color $witt-very-dark-grey
  margin-top 32px
  border-bottom 1px solid $witt-lighter-grey

  @media screen and (max-width: $large)
    width 300px
    height 100%
    box-shadow 0px 2px 6px 0px rgba(0, 0, 0, 0.27)
    position fixed
    z-index 9999
    left -320px
    top 0px
    transition left 0.4s ease-in
    overflow hidden
    overflow-y auto
    margin-top 0px
    
    &:after
      display block
      content ''
      width 300px
      height 40px
      background linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8561799719887955) 53%, rgba(255,255,255,0) 100%)
      position fixed
      bottom 0px
      z-index 99999
      


  &.active
    left 0px

  &__inner
    @media screen and (max-width: $large)
      width 100%
      transition all 0.3s ease-in
      position relative
      padding-top 90px

      &.selected
        margin-left -300px

  &__list
    margin 0
    padding 0
    position relative

    &--right
      position relative
      text-align right
      z-index 3
      right 15px
      @media screen and (min-width: $large)
        position absolute

      .navigation__list__flyout
        @media screen and (min-width: $large)
          left calc(-100vw + 162.633px)
        @media screen and (min-width: $xlarge)
          left calc(-1140px + 132.633px)

      @media screen and (max-width: $large)
        text-align left
        position unset

        .navigation__list__item--mainCategory
          &:before
            content ''
            display block
            width calc(100% - 40px)
            margin-left 20px
            border-top 1px solid $witt-lighter-grey

          a
            font-weight 700
          > a:hover
            color: var(--custom-navigation-hover-color) !important

      li
        text-align left

    &--left
      left 15px
      position absolute
      z-index 3

      @media L_and_up
        display flex
        column-gap 16px

      @media up_to_L
        position unset
        left auto

    &__close
      display none
      @media screen and (max-width: $large)
        display none
        height 90px
        width 100%
        transition left 0.4s ease-in
        position fixed
        left 0px
        top 0px

        &:after
          display block
          content ''
          background-image url(../img/app/rwd/icons/close-x.svg)
          background-size 18px auto
          background-position center left
          background-repeat no-repeat
          width 50px
          height 50px
          position absolute
          left 20px
          top 20px
          cursor pointer
          
        &.active
          display block

    &__item
      text-align center
      inline-block()

      &-text
        @media L_and_up
          padding-bottom 8px
          &.is-active
            padding-bottom 6px
            border 2px solid
            border-image-source radial-gradient(71.31% 395.12% at 91.25% 32.93%, #E4003C 0%, #E31C5F 35%, #D70466 70.5%, #BE2766 100%)
            border-image-slice 1
            border-top 0
            border-left 0
            border-right 0

      &--mainCategory
        inline-block()
        z-index 999

        @media screen and (max-width: $large)
          width 100%
          
          &:after
            content ''
            display block
            width calc(100% - 40px)
            margin-left 20px
            border-bottom 1px solid $witt-lighter-grey
        
        &:hover
          text-decoration none
          > span, > a
            text-decoration none
            color $witt-darked-grey

            @media L_and_up
              color $witt-black
              background #EFF0F0
              border-radius 4px

        &:last-child
          @media screen and (max-width: $large)
            &:after
              border-bottom none

        a:not([href]):not([tabindex])
          color $witt-darked-grey
          @media L_and_up
            color $witt-black
        a, span
          display block
          padding 8px 16px 0
          font-size 16px
          line-height 20.8px
          color $witt-black

          @media screen and (max-width: $large)
            position relative
            font-size 14px
            line-height 16px
            padding 15px 20px

            .arrow
              position absolute
              top 0px
              right 0px
              margin 17px 20px 12px 15px
              display block
              content ''
              background-image url(../img/app/rwd/arrow-right-big.png)
              background-size contain
              background-repeat no-repeat
              width 12px
              height 15px
              
          &.current
            &:hover
              text-decoration none
              
            &:after
              display block
              content ''
              width 40px
              border-bottom 2px solid $witt-red2
              position absolute
              bottom 1px
              left 50%
              margin-left -20px
              
              @media screen and (max-width: $large)
                display none

          &.scheme1
            background $witt-red2
            color $witt-white
            &:hover
              background $witt-red2
              color $witt-white
            @media screen and (max-width: $large)
              background $witt-white
              color inherit
              font-weight 400
              &:hover
                background-color $witt-white
                color inherit


          &.scheme2
            background-color $witt-darked-grey
            color $witt-white

            @media screen and (max-width: $large)
              background $witt-lighter-grey
              color $witt-blue
              &:hover
                background-color $witt-blue
                color $witt-white
        a:hover
          color: var(--custom-navigation-hover-color, $witt-black) !important

      &--mobileMetanav
        display none

        @media screen and (max-width: $large)
          display block
          width 100%
          background-color $witt-white
          background-repeat no-repeat
          
          &:before
            content ''
            display block
            width calc(100% - 40px)
            margin-left 20px
            border-bottom 2px solid $witt-lighter-grey
             
          &:after
            content ''
            display block
            width calc(100% - 40px)
            margin-left 20px
            border-bottom 2px solid $witt-lighter-grey
              
          a
            display block
            padding 16px 20px 16px 60px
            font-size 13px
            line-height 16px
            color $witt-darked-grey
            position relative

            &:hover
              text-decoration none
            &:after
              display block
              content ''
              background-image url(../img/app/rwd/arrow-right-big.png)
              background-size contain
              background-repeat no-repeat
              width 12px
              height 15px
              position absolute
              top 17px
              right 20px
              
        &--noborder
          @media screen and (max-width: $large)
              
            &:before
              display none
              
            &:after
              display none
              
            a
              padding-left 20px

        &.compte
          background-image url(../img/app/rwd/icons/customer.svg)
          background-size 24px auto
          background-position 24px center
          margin-bottom 15px

        &.commande
          background-image url(../img/app/rwd/icons/order_commande.svg)
          background-size 24px auto
          background-position 24px center

    .mobile-compte-icon
      &--logged-in
        @media screen and (max-width: $large)
          &:before
            content ''
            width 15px
            height 15px
            border-radius 50%
            display block
            background-color $witt-lighter-grey
            position absolute
            top 16px
            left 39px
            background-image url(../img/app/rwd/check.svg)
            background-position center center
            background-repeat no-repeat
            background-size 11px auto

    &__flyout
      position absolute
      top 37px
      left 0px
      width calc(1170px - 30px)
      z-index 1000
      background-color $witt-white
      display none
      box-shadow 0px 2px 6px 0px rgba(0, 0, 0, 0.27)
      padding 12px 20px 30px 20px
      margin-top 0px

      @media screen and (max-width: $large)
        width 100% !important
        top 80px
        left 300px
        background-color $witt-white
        box-shadow none
        padding 0 0 30px 0
        display none !important

        &.selected
          display block !important
          min-height calc(100vh - 90px)

      &__inner
        @media screen and (max-width: $large)
          width 300px

      .backLink
        display none

        @media screen and (max-width: $large)
          padding 13px 15px 13px 36px
          font-size 16px
          line-height 24px
          display block
          cursor pointer
          position relative

          &:after
            display block
            content ''
            background-image url(../img/app/rwd/arrow-left-big.png)
            background-size contain
            background-repeat no-repeat
            width 12px
            height 15px
            position absolute
            left 15px
            top 19px

      ul
        list-style-type none
        padding 0px
        margin 0px

      &--categoryName
        display none

        @media screen and (max-width: $large)
          display block
          margin-bottom 0px
          background-color $witt-red2
          color $witt-white
          padding 17px 20px
          font-size 13px
          line-height 16px
          font-weight 400
          position relative

      &--childCategory

        margin-bottom 8px

        @media screen and (max-width: $large)
          margin-bottom 0px
             
          &:after
            content ''
            display block
            width calc(100% - 40px)
            margin-left 20px
            border-bottom 2px solid $witt-lighter-grey
            
        &:first-child
          @media screen and (max-width: $large)
            &:before
              content ''
              display block
              width calc(100% - 40px)
              margin-left 20px
              border-bottom 2px solid $witt-lighter-grey
              
        &:last-child
          @media screen and (max-width: $large)
            &:after
              border-bottom none

        a, span
          display inline
          height auto
          padding 0px
          color $witt-navigation-color
          font-size 14px
          font-weight 700
          line-height 22px

          @media screen and (max-width: $large)
            display block
            color $witt-darked-grey
            padding 17px 20px
            font-size 13px
            line-height 16px

            &:after
              display none

            .arrow
              background-image url(../img/app/rwd/arrow-down.svg)
              background-repeat no-repeat
              background-size contain
              width 20px
              height 20px

          &:hover
            text-decoration none

          &.hasChildren
            .arrow
              @media screen and (max-width: $large)
                transition transform 1s
                transform rotate(0deg)
          &.active.hasChildren
            .arrow
              @media screen and (max-width: $large)
                transition transform 1s
                transform rotate(180deg)

        ul
          @media screen and (max-width: $large)
            display none

      &--subCategory
        line-height 20px

        a, span
          font-weight 400
          font-size 13px
          line-height 20px

        @media screen and (max-width: $large)
          background-color $witt-white

          a, span
            padding 12px 20px
            color $witt-navigation-color
            font-size 14px
            line-height 22px
            background-color $witt-white
          

      .teaserColumns
        color $witt-red
        min-height 360px
          
        p
          font-weight bold
          font-size 14px
          line-height 22px
          margin 0px
          
        ul
          margin 0px 0px 8px 0px
          
          li
            font-size 13px
            line-height 19px
            font-weight 400

        a
          color $witt-red
          font-size 13px
          padding 0px
          line-height 16px
          font-weight 400

        .promo
          position absolute
          top 3px
          right 10px
          
  &__close
    display none

    @media screen and (max-width: $large)
      position absolute
      right 20px
      bottom 15px
      padding-left 18px
      background-image url(../img/app/rwd/navigation-close.png)
      background-repeat no-repeat
      color $witt-darked-grey
      font-size 13px
      cursor pointer

.darkOverlay
  position fixed
  left 0px
  top 0px
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.7)
  z-index 999
  display none

  &.active
    display block


.flyout
  &__header
    height 36px
    font-size 20px
    line-height 36px
    font-weight 700
    margin-top -5px
    margin-bottom 10px
    font-style bold

    &--teaser
      width 48%

.js-link
  cursor pointer