@require 'includes/*'

.confirmation
  margin-bottom 60px

  @media screen and (max-width: $medium)
    margin-bottom 20px
    
  &__top
    display flex
    align-items flex-start
    margin-top 40px

  &__headline
    font-size 38px
    line-height 40px
    font-weight 700
    color $witt-very-dark-grey
    
  &__subheadline
    font-size 24px
    line-height 28px
    margin 30px 0 40px 0
    font-weight 700
    padding-left 50px
    background-image url(../img/app/rwd/basket-check.svg)
    background-repeat no-repeat
    background-position left center
    background-size 30px auto
    color $witt-very-dark-grey
    
    @media screen and (max-width: $small)
      font-size 21px
      line-height 24px
    
  &__text
    margin-bottom 20px
    font-size 14px
    
    .highlight
      font-weight 700
      
  &__shipping
    border-top 1px solid $witt-highlight-border
    padding-top 16px

    &-headline
      font-weight 800
      font-size 18px
      line-height 20px
      margin-bottom 8px

    &--wrapper
      display flex
      column-gap 24px
      row-gap 24px
      flex-wrap wrap
      margin-top 16px

    &-status
      font-size 14px
      line-height 18.2px

    &-address, &-status
      width calc(50% - 24px)
      @media up_to_S
        width 100%

  &__buttons
    margin-top 32px
    margin-bottom 16px

  &__legal-note
    color #66657B  //replace after styleguideTicket merge
    margin-bottom 40px

    @media up_to_M
      margin-bottom 20px
