@require 'includes/*'

html
  scroll-padding-top 20px

body
  font-size 12px
  line-height 1.42857143
  color $witt-darked-grey
  font-family $font
  font-weight 400
  background-color $witt-white

  @media screen and (max-width: $medium)
    background-color $witt-white

  &.pswp-active
    overflow hidden

h3
  font-size 18px
  font-weight bold
  line-height 130%
  letter-spacing 0
  margin-top 0
  margin-bottom 0

  @media (min-width: 768px)
    font-size 20px

.shoplogo
    float left
    width 108px
    height 54px
    background-image url(/img/app/rwd/logo-inverted.svg)
    background-repeat no-repeat
    background-position center center
    background-size contain
    margin 20px 10px 0

    a
      display block
      width 100%
      height 100%

    @media up_to_L
      width 70px
      height 48px
      margin 0 10px 0 48px

    @media up_to_M
      margin 0 10px 0 48px

    &--general
      @media L_and_up
        margin 0 10px 0

    &--25
      background-image url(/img/app/rwd/logo-25th.svg)
      width 135px
      height 45px
      @media M_to_L
        width 100px
        height 40px
      @media up_to_M
        width 82px
        height 44px

      .checkout-header &
        height 50px

.pagecontent
  background-color $witt-white

  @media screen and (min-width: $xlarge)
    max-width 1170px
    position relative

a,span.link
  color $witt-black

  &:hover
    color $witt-black
    cursor pointer

  &.underline
    text-decoration underline

span.link:hover
  text-decoration underline

.smallCaps
  text-transform none

.color
  &--red
    color $witt-red
  &--green
    color $witt-green
  &--yellow
    color $witt-yellow
  &--blue
    color $witt-blue
    
.availability
  &--green
    color $availability-green
  &--orange
    color $availability-orange
  &--red
    color $availability-red

.space--20
  margin-bottom 20px

.col-mobile-no-padding
  @media screen and (max-width: $medium)
    padding-left 0px
    padding-right 0px

.align-bottom
  margin-top auto
  margin-bottom 0px

// remove this later on as the users need to see an outline when an element changes its state (accessibility issue 2.4.7)
button, input
  &:focus
    outline none

#loading-overlay
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(255, 255, 255, 0.9)
  z-index 10000

.loading-circle
  width 90px
  height 90px
  position absolute
  top 50%
  left 50%
  margin-left -45px
  margin-top -45px
  background-image url(../img/app/rwd/chargement-en-cours.gif)
  background-size contain

.globalError, .error
  color $witt-red
  &-message-b2c
    color $witt-red
    display block
    background $witt-light-red
    margin 0px 15px 15px 15px
    padding 15px 15px
    border $witt-red solid 2px
    border-radius 5px

.success
  color $witt-green
  font-size 16px
  margin-bottom 20px
  
.info
  border 2px solid $witt-black
  color $witt-very-dark-grey
  padding 10px 15px
  font-size 14px
  margin-bottom 20px
  
  &--margin-right
    margin-right 20px

// Combine it later with .info
.info__warning
  padding 8px 16px
  font-size 14px
  border none
  background-color $color-info-background-warning

.page
  &__header
    h1
      font-size 18px
      color $witt-black
    &--red
      h1
        color $witt-reduction-red
      
  img
    max-width 100%
    
.text-center
  text-align center
  
.margin-top
  margin-top 10px
  
.margin-bottom
  margin-bottom 10px
  
.layoutFragment
  img
    max-width 100%

.to-top
  reset-button-style()
  position fixed
  bottom 30px
  right 30px
  text-align center
  z-index 999
  opacity 0
  transition all 0.2s

  &.visible
    opacity 1
    
    @media screen and (max-width: $medium)
      opacity 0
      display none
    
    .to-top__inner
      cursor pointer
      pointer-events auto

  &__inner
    background-color $witt-light-grey
    color $witt-white
    font-size 11px
    line-height 13px
    padding 26px 5px 0 5px
    width 80px
    height 80px
    text-align center
    border-radius 50%
    position relative
    display inline-block
    cursor default
    pointer-events none

#promotion-banner, #homepage_pdp_promo_banner
  @media screen and (min-width: $medium)
    margin-bottom 15px
  //@media screen and (max-width: $medium)
  //  padding-top 95px

#preloadedModalContent
  display none

#settingspanel
  position fixed
  top 70%
  left -190px
  border 2px solid $witt-red
  border-left none
  &.hover
    left 0

  z-index 397
  width 200px
  background $witt-white
  li
    padding 7px 10px
  ul
    list-style none
    margin 0
    padding 0
    border none

.skyscraper
  position absolute
  z-index -1
  &__right
    right 0
    margin-right 15px
  &__left
    left 0
    margin-left 15px
  @media screen and (max-width: $xlarge)
    display none

/* REMOVE AFTER RE-ACTIVATING BOOTSTRAP	RESPONSIVE */
.d-md-none
  display none
  
.modal-header
  .close
    z-index 2



			
						
	



