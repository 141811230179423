@require 'includes/*'

.account-navigation-layer
  display none
  position absolute
  z-index 100
  width 250px
  padding-top 15px
  top 58px
  left -78%

  @media up_to_L
    display none !important

  &__inner
    width 100%
    background-color $witt-white
    padding 20px
    font-size 14px
    box-shadow 0 0 20px rgba(0, 0, 0, 0.2)
    position relative

    &:before
    &:after
      content ''
      width 0
      height 0
      position absolute
      @media up_to_M
        right 73px
      @media M_and_up
        left calc(50% - 12px)

    &:before
      top -11px
      border-left 12px solid transparent
      border-right 12px solid transparent

    &:after
      top -10px
      border-left 12px solid transparent
      border-right 12px solid transparent
      border-bottom 10px solid $witt-white

  &__text
    font-size 13px
    line-height 16px
    color $witt-very-dark-grey
    margin-bottom 20px

  &--visible
    display block

  &__entry
    padding-bottom 20px

  ul
    list-style-type none
    margin 0
    padding 0

  &__btn
    .btn-standard
      background-color $witt-red2
      border-color $witt-red2
      color $witt-white
      text-align left

      &:hover
        background-color $witt-red-highlight
        border-color $witt-red-highlight
        color $witt-white

    .btn-logout
      text-align left
      color $witt-mid-grey
      background-image url(../img/app/rwd/icon-logout.svg)
      background-position left center
      background-repeat no-repeat
      background-size auto 20px
      padding 0
      font-size 16px

      span
        padding-left 34px

      &:hover
        text-decoration underline


  .icon__logout
    margin-left 5px