@require '../includes/*'

.filter--checkbox,
.filter__range
  .filter__label
    margin-bottom 0
    cursor pointer
    position relative
    padding-left 25px
    color $witt-black

  .afterCheckmark
    vertical-align text-bottom

  input[type="checkbox"], input[type="radio"]
    position absolute
    left -999px

    @media up_to_M
      margin-right 15px
    @media M_and_up
      margin-right 7px
      opacity 0
      cursor pointer

    &:checked ~ .checkmark
      border-color $witt-black

  .checkmark
    border 1px solid $witt-light-grey3
    height 20px
    width 20px
    margin-top 0px
    margin-right 5px
    display inline-block
    border-radius 0px
    position absolute
    left 0

  .checkmark:after,
  .checkmark--checked:after,
    content ""
    display none
    height 16px
    width 16px
    margin-top 1px
    margin-left 1px
    position absolute
    background-image url(/img/app/rwd/check.svg)
    background-size contain
    background-repeat no-repeat
    background-position center center

  .checkmark--checked:after
  input:checked ~ .checkmark:after
    display inline-block