@require 'includes/*'

.filter-list

  @media up_to_M
    order 1
    flex-shrink 0

  @media M_and_up
    margin-left -15px
    margin-right -15px
    width 100%

  @media M_to_L
    padding-left 10px
    padding-right 10px

  @media L_and_up
    margin-left -30px
    margin-right -30px
    padding-left 15px
    padding-right 15px

  &__checkbox-label
    font-size 14px

  &__checkbox
    margin-right 5px

  &__mobile-dropdown
    @media up_to_M
      border 1px solid $witt-very-dark-grey
      border-radius 8px
      padding 10px
      width 100%
      background-color $witt-very-dark-grey
      color $witt-white

      &-title
        color $witt-white
        font-size 14px
        display inline-block
        vertical-align top
        text-transform none
        font-weight 700

        &:before
          content ''
          height 19px
          width 30px
          margin-right 6px
          display inline-block
          vertical-align top
          background-image url(../img/app/rwd/filter.svg)
          background-position center center
          background-repeat no-repeat
          background-size 100% auto

      &-count
        font-size 14px
        display inline-block
        vertical-align top
        text-transform none
        font-weight 700
        width 27px

      &-icon
        display none

    @media M_and_up
      display none

  &__title
    display inline-block
    width calc(100% - 16px)
    font-size 14px
    color $witt-black

  &__content
    @media up_to_M
      display none
      position fixed
      top 0
      bottom 0
      left 0
      width 100%
      background-color $witt-white
      z-index 1010
      padding 0 0 10px
      &--visible
        display block

        .filter-list__active-filters
          position relative

        .filter__selected-filter
          display flex
          overflow-x scroll
          touch-action pan-x
          scrollbar-width none
          -ms-overflow-style none

          &::-webkit-scrollbar
            width 0
            height 0

    @media M_and_up
      display flex
      width 100%
      padding 0 8px 5px 15px
      align-items flex-start

    @media M_to_L
      padding 0 0 5px 0

  &__header
    @media M_and_up
      border-bottom 1px solid $witt-highlight-border
      display none

    @media up_to_M
      margin-left 13px

  &__scrollarea
    @media up_to_M
      overflow scroll
      display block
      position relative
      height calc(100% - 145px)
      margin-top 5px

    @media M_and_up
      width: calc(100% - 110px);
      display: flex;
      flex-wrap: wrap;
      max-height: 109px;
      transition: max-height .5s;

  &__scrollarea_smaller
    @media up_to_M
      height calc(100% - 225px)

  &__close
    position relative
    color $witt-black
    font-size 16px
    line-height 38px
    font-weight 700
    padding 13px 40px 13px 0
    &-icon
      position absolute
      top 24px
      right 18px
      cursor pointer
      content ''
      sprite($icon_close_small)

  .dropdown
    cursor unset
    @media up_to_M
      border none
      border-bottom 1px solid $witt-highlight-border
      border-radius 0

      &__title,
      &__title-opened
        font-size 14px
        padding 10px 0 8px 0
        color $witt-very-dark-grey3
        font-weight 700
        width auto !important

      &__content
        position relative
        border none
        padding 0 12px 15px 12px
        margin-top 8px

      &__icon
        sprite($icon_arrow_down_medium)
        &--opened
          sprite($icon_arrow_down_medium)
        &--mobile-opened
          sprite($icon_arrow_down_medium)

      &__title-wrapper
        justify-content flex-start

      &__info
        &--icon
          color $witt-darked-grey
          background-color transparent
          margin 0 5px !important
          padding 0
          text-align left

        &-braket
          display inline-block



    @media M_and_up
      vertical-align top
      &__info-braket
        display inline-block
      &__info--icon
        background-color transparent
        color $witt-darked-grey
        padding 0

    &__header
      cursor pointer

      .dropdown__title-wrapper
        .dropdown__title, .dropdown__title-opened
          width calc(100%)
          text-overflow ellipsis
          overflow hidden
          white-space nowrap
          font-weight bold



  &__sizes
    .filter--checkbox
      .filter__item
        width calc(20% - 30px)

        @media XL_and_up
          width calc(12.5% - 30px)

        @media up_to_M
          width 50%
          display inline-block

  &__items
    width calc(100% - 110px)
    display flex
    flex-wrap wrap
    max-height 54px
    overflow hidden
    transition max-height 0.5s
    margin-bottom -8px

    &.open
      max-height 500px
      transition max-height 2s

    @media up_to_M
      flex-direction column
      width 100%
      max-height unset
      margin-bottom 0

    @media M_to_L
      max-height 49px

  &__item
    @media up_to_M
      width 100%

    @media M_and_up
      width 25%
      padding-right 10px
      padding-bottom 10px
      order 2

      &--invisible
        display none

    &--desktop-invisible
      @media M_and_up
        display none

    @media M_to_L
      padding 0 5px 5px 0

    &-button
      width 100px
      cursor pointer
      margin-top 15px
      font-weight bold

      @media up_to_M
        display none

      .btn-text
        &:hover
          text-decoration underline

      .btn-text-opened
        display none
        text-decoration underline

      &.opened
        .btn-text-opened
          display inline

        .btn-text
          display none

    .dropdown
      &__header
        padding 11px 8px 11px 12px

        @media M_to_L
          padding 11px 4px 11px 10px

      &__info--icon
        margin 0 2px


  &__more-button,
  &__less-button
    .btn-text
      text-align left

  &__button-container
    @media up_to_M
      display block
      width 100%
      background-color $witt-white
      bottom 0
      position relative

  &__buttons
    display flex
    justify-content center
    gap 10px
    padding-top 20px

    &__icon
      margin-top -2px

    &__shadowedge
      @media up_to_M
        &--up, &--down
          width: 100vw;
          height: 16px;
          background-color: #fff;
          box-shadow: 0 -4px 10px -4px #CCC;
          position: fixed;
          z-index: 30;
          left: 0;
        &--down
          box-shadow: 0 5px 10px -5px #ccc
          margin-top: -13px

    &--cancel, &--submit
      width 40%
      padding-top 13px
      padding-bottom 13px
      text-transform unset !important
      @media up_to_M
        min-width 200px

    &--cancel
      border none
      text-decoration underline
      text-transform none
      color $witt-very-dark-grey3
      font-weight 700
