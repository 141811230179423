@require 'includes/*'

#toasts
  position fixed
  top 32px
  right 40px
  z-index 1000

  @media up_to_M
    top 40px
    right 14px
    left 14px

.toast
  align-items center
  border-radius 6px
  display flex
  flex-wrap wrap
  justify-content space-between
  width 290px
  padding 16px
  background-color $witt-white
  box-shadow 0px 0px 16px 0px rgba($witt-very-dark-grey, .16)
  color $witt-very-dark-grey
  transition transform 0.5s
  transform translateX(770px)

  @media up_to_M
    width 100%


  &.active
    transform translateX(0px)

  &:hover
    text-decoration none
    color $witt-very-dark-grey

  &__icon
    &-check
      background-image url(../img/app/rwd/check-bold.svg)
      background-color $helline-green-lighter
      background-repeat no-repeat
      background-position center
      border-radius 50%
      height 20px
      width 20px

    &__container
      align-items center
      display flex
      flex-basis 20px
      flex-grow 0
      flex-shrink 0

  &__text
    padding 0 15px
    font-size 16px
    width calc(100% - 30px)

  &__close
    background-image url(../img/app/rwd/icons/close-x.svg)
    background-repeat no-repeat
    background-position center
    height 10px
    width 10px
    align-self flex-start
    cursor pointer

  &__links
    display flex
    width 100%
    justify-content space-between
    padding-top 10px

    &__close
      text-decoration underline
      color $witt-darked-grey
      cursor pointer
      font-size 13px

    &__action
      text-decoration underline
      color $witt-black
      font-size 13px
      
      &:after
        content '>'
        display inline-block
        position relative
        margin-left 3px

