@require 'includes/*'

.main-pictures
  width 100%
  position relative

  @media screen and (max-width: $medium)
    margin-top 5px

  img.productImage
    max-width 100%
    max-height 482px
    margin auto
    cursor pointer

    @media M_and_up
      max-height 608px

  &__zoom
    sprite( $icon_zoom )
    display block
    position absolute
    top 13px
    right 18px
    cursor pointer

    &--button
      reset-button-style()

  &__stickers
    display flex
    position absolute
    top 10px
    left 10px

  &__link
    border 1px solid $highlight-border
    border-top none
    text-align center
    padding 18px 10px
    font-size 14px
    line-height 18px

  .slick-prev
    width 35px
    height 50px
    background-image  url(../img/app/rwd/slider-arrow-left.svg) !important;
    background-size 20px auto
    background-repeat no-repeat
    background-position center center
    background-color transparent
    border none
    position absolute
    left 0
    top 50%
    margin-top -25px
    padding 0
    z-index 1
    cursor pointer

    @media screen and (max-width: $small)
      background-color white

  .slick-next
    width 35px
    height 50px
    background-image  url(../img/app/rwd/slider-arrow-right.svg) !important;
    background-size 20px auto
    background-repeat no-repeat
    background-position center center
    background-color transparent
    border none
    position absolute
    right 0
    top 50%
    margin-top -25px
    padding 0
    z-index 1
    cursor pointer

    @media screen and (max-width: $small)
      background-color white

.main-picture
  border 1px solid $highlight-border
  display none

  &:first-child
    display block

.main-thumbs
  width 85px

  @media screen and (max-width: $medium)
    width 100%
    margin-top 20px

  &__thumb
    display none

    &:nth-child(-n + 3)
      display block

  .slick-slide
    border 1px solid $highlight-border
    margin-bottom 8px

    @media screen and (max-width: $medium)
      margin 0
      border none
      padding 0 3px

    &.active
      border-color $witt-black

    img
      max-height 168px
      max-width 100%
      width auto
      height auto
      margin auto
      cursor pointer

      @media M_and_up
        max-height 112px

  .slick-prev
    display block
    border none
    margin 0 auto 5px auto
    sprite( $icon_arrow_down )
    transform rotate(180deg)
    background-color transparent
    cursor pointer

    @media screen and (max-width: $medium)
      transform none
      background-image  url(../img/app/rwd/slider-arrow-left.svg) !important;
      background-position center center
      background-size contain
      background-repeat no-repeat
      position absolute
      top 50%
      left 0
      margin -8px 0 0 -16px
      width 16px
      height 16px
      z-index 2

  .slick-next
    display block
    border none
    margin 0 auto 5px auto
    sprite( $icon_arrow_down )
    background-color transparent
    cursor pointer

    @media screen and (max-width: $medium)
      background-image  url(../img/app/rwd/slider-arrow-right.svg) !important;
      background-position center center
      background-size contain
      background-repeat no-repeat
      position absolute
      top 50%
      right 0
      margin -8px -16px 0 0
      width 16px
      height 16px
      z-index 2

.product
  &__main-thumbs
    @media up_to_S
      display none

  &__mobile-back-button
    margin-bottom 20px

  &__brand-image
    height 15px
    margin-bottom 10px

    @media screen and (max-width: $medium)
      text-align left
      margin-bottom 0

    img
      height 100%

  #skeepers_product_widget
    display block !important

    .select-wrapper
      span, ul, input
        display none !important
      select
        height 20px !important

  .skeepers_product__stars
    margin-left 5px

  &__title
    font-size 25px
    line-height 30px
    margin-bottom 16px
    font-weight bold

    @media screen and (max-width: $medium)
      inline-block()
      vertical-align middle
      width calc(100% - 60px)

  &__reference
    margin-bottom 5px
    text-align center

    @media screen and (max-width: $medium)
      text-align left

    &__icon
      margin-left 5px
      display inline-block
      vertical-align top
      margin-top -3px

      .tipr_container_top
        margin-left 33px !important
        margin-top -65px

        .tipr_content
          line-height 1.1em

        .tipr_point_top
          &:before
            left 0
            top 50%
            margin-top -9px
            margin-left -18px
            border-top 9px solid transparent
            border-bottom 9px solid transparent
            border-right 9px solid #dcdcdc

          &:after
            left 0
            top 50%
            margin-top -8px
            border-top 8px solid transparent
            border-bottom 8px solid transparent
            border-right 8px solid white
            margin-left -15px

  &__short-reviews
    margin-left 10px
    display flex

    &__stars
      margin-top -5px
      text-align left

      .netreviews_review_rate_and_stars
        width 70px

    &__link
      margin-left 10px

      a
        text-decoration underline

      &.hide
        display none

    .netreviews_reviews_rate
      display none

  &__logo
    max-width 50px

    img
      width 100%

    @media screen and (max-width: $medium)
      inline-block()
      vertical-align middle
      max-width 35px

  &__review-and-ref
    display flex
    align-items center
    justify-content flex-start

  &__summary
    padding-left 40px

    @media screen and (max-width: $large)
      padding-left 0

    @media up_to_M
      margin-top 14px

    &__reference-reviews
      display flex
      justify-content flex-start

    .product__title
      @media screen and (max-width: $medium)
        margin-top 14px
        text-align left
        width 100%

    .product__reference
      @media screen and (max-width: $medium)
        margin-bottom 20px

    .product &
      .witt-price
        text-align left

        &__top
          justify-content flex-start

      .product__selection
        .disclaimer
          text-align left

      .installment__container
        justify-content flex-start
        text-align left

  &__link-description
    margin-bottom 20px

    @media screen and (max-width: $medium)
      display none

    a
      text-decoration underline
      font-size 12px

  &__selection
    margin-top 10px

    .witt-price
      margin 0
      margin-bottom 16px

    &__singlemodel
      margin-bottom 25px
      font-size 16px
      text-align left

      span
        font-weight 700


    &__label
      text-align left
      margin-bottom 4px
      font-weight 700
      font-size 16px

    .disclaimer
      font-size 11px
      margin-top 8px
      text-align center

      @media screen and (max-width: $small)
        font-size 10px

    .custom-dropdown
      cursor pointer
      text-align left

      .opener, .singleModel, .singleVariation, ul li
        padding-left 16px

      .available
        color $availability-green

      .notAvailable
        color $availability-orange

      .soldOut
        color $availability-red

    .material
      .select-wrapper
        background-color $witt-lighter-grey

        span.caret
          right 13px

      input[type=text]
        &.select-dropdown
          font-size 16px !important
          text-align center
          border none
          padding 24px 0

      .dropdown-content
        background-color $witt-lighter-grey

        li
          &.disabled
            background-color $witt-lighter-grey !important

            &:hover
              background-color $witt-lighter-grey !important

      .input-field
        &.required
          .select-wrapper
            input[type=text]
              border 1px solid $witt-red

    &__error-message, &__error-message--availability
      color $witt-red
      font-size 14px
      margin-bottom 18px
      &.hidden
        display none

    &__quantity
      margin-bottom 18px

      .text
        inline-block()
        vertical-align middle
        color $witt-darked-grey
        margin-right 10px

      .input
        inline-block()
        vertical-align middle

      input
        width 28px
        height 28px
        border 1px solid $highlight-border
        text-align center
        appearance textfield
        margin-top 0
        -moz-appearance textfield

        &::-webkit-inner-spin-button
          -webkit-appearance none

      button
        width 28px
        height 28px
        border 1px solid $highlight-border
        margin-top 1px
        padding 0
        inline-block()
        color $highlight-border
        cursor pointer

        &.substractQuantity
          border-right none

        &.addQuantity
          border-left none

    &__sizes-link
      margin-top 6px
      margin-bottom 20px

      @media screen and (max-width: $medium)
        margin -5px 5px 20px 0
        text-align right

      a
        text-decoration underline

    &__buttons
      text-align center
      display flex

      &--add-to-basket
        width 100%

    &__add-to-basket
      display flex
      align-items center
      justify-content center
      font-size 16px
      line-height 32px
      font-weight bold
      padding 8px 12px
      border-radius 5px
      color $witt-white
      background-color $witt-darked-grey3
      border 1px solid $witt-darked-grey3
      cursor pointer
      width calc(100% - 80px)

      &:hover
        color $witt-darked-grey3
        background-color $witt-beige
        border-color $witt-beige

        .addtocart--icon
          background-image url(../img/app/rwd/helline/add-to-basket-active.svg)

      &:focus
        color $witt-darked-grey3
        background-color $witt-beige
        border-color $witt-darked-grey3

        .addtocart--icon
          background-image url(../img/app/rwd/helline/add-to-basket-active.svg)

      &:active
        color $witt-darked-grey3
        background-color $witt-beige
        border-color $witt-darked-grey3

        .addtocart--icon
          background-image url(../img/app/rwd/helline/add-to-basket-active.svg)

      &:disabled, .btn-primary-softdisabled
        background-color $witt-light-grey !important
        border-color $witt-light-grey !important
        color $witt-darked-grey !important

        &:hover
          color $witt-darked-grey !important

  &__description-features
    margin 20px 15% 30px
    line-height 24px
    color $witt-very-dark-grey
    font-size 14px

    @media screen and (max-width: $medium)
      font-size 14px
      line-height 20px
      margin 15px 0 0

    &__inner--show
      display block !important

    h2.active .switch
      transform: rotate(180deg);

    h2
      font-size 24px
      line-height 28px
      font-weight 700
      padding 8px 0
      border-bottom 2px solid $witt-light-grey3
      margin-bottom 16px
      margin-top 10px
      position relative
      cursor default
      pointer-events none

      @media screen and (max-width: $medium)
        display flex
        align-items center
        justify-content space-between
        font-size 16px
        font-weight 400
        padding 0 0 8px 0
        border-bottom 2px solid $witt-light-grey3
        margin-top 0
        margin-bottom 16px
        cursor pointer
        pointer-events unset

      .switch
        width 22px
        height 22px
        background-image url(../img/app/rwd/arrow-down-big.png)
        background-position center right
        background-size 20px auto
        background-repeat no-repeat
        display none

        @media screen and (max-width: $medium)
          display block

          &.active
            background-image url(../img/app/rwd/arrow-up-big.png)

    &__inner
        @media screen and (max-width: $medium)
          display none
          margin-top -8px
          margin-bottom 16px
  &__traceability
    display none
    &-open
      reset-button-style()
      text-align left
      text-decoration underline
      font-weight 700

      &:hover
        text-decoration none

  &__features

    dl
      padding-bottom 8px
      margin-bottom 16px
      border-bottom 1px solid $witt-light-grey3

      @media screen and (max-width: $medium)
        padding-bottom 6px
        margin-bottom 8px
        border-bottom 1px solid $witt-light-grey3

    dt
      display inline-block
      vertical-align top
      margin-right 10px

      @media screen and (max-width: $medium)
        width 45%


    dd
      display inline-block
      vertical-align top
      margin-bottom 0

  &__additional-content
    margin-top 20px
    display flex
    flex-direction column

    &__accordionContainer
      @media screen and (max-width: $medium)
        margin-bottom 0

  &__recommendations-ajax
    @media up_to_M
      margin-top 24px

  &__additional-box
    border 1px solid $highlight-border
    width 100%
    margin-bottom 10px

    &.no-border
      border none
      margin-bottom 40px
      
      .product__additional-box__header
        font-size 28px
        text-align center
        margin-bottom 30px
        font-weight 700
        background-color transparent
        border-bottom none
        padding 0
        color $witt-darked-grey
    
    &__header
      background-color $witt-lighter-grey
      border-bottom 1px solid $highlight-border
      font-size 19px
      color $witt-black
      padding 12px 20px

    &__mobile_arrow
      display none
      @media screen and (max-width: $medium)
        display inline
        width 20px
        margin-left 20px
        vertical-align middle
        position relative
        top -2px
        transform: rotate(90deg);

    &__content
      padding 0 20px 0 20px
      font-size 17px

      &--mobile-no-padding
        @media screen and (max-width: $medium)
          padding-left 0
          padding-right 0

    table
      &.bordered
        width 100%

        @media screen and (max-width: $medium)
          margin-top 30px

        tr
          border-bottom 1px solid $highlight-border

          &:first-child
            td
              padding-top 0

          &:last-child
            border none

            td
              padding-bottom 0

        td
          width 50%
          padding 6px 10px
          vertical-align top

          @media screen and (max-width: $medium)
            padding 6px 0

    .up
      transform: rotate(270deg);

    .mobile-hide
      @media screen and (max-width: $small)
        display none

  &__carousel
    
    .slick-slide
      text-align center
      position relative
      
      img
        margin 0 auto
        max-width 100%
        
      a
        display block
        width 100%
        height 100%
        position absolute
        top 0
        left 0
        cursor pointer
        
    .slick-prev
      sprite( $icon_arrow_left )
      background-repeat no-repeat
      border none
      position absolute
      left 0
      top 50%
      margin-top -5px
      padding 0
      z-index 99
      
    .slick-next
      sprite( $icon_arrow_right )
      background-repeat no-repeat
      border none
      position absolute
      right 0
      top 50%
      margin-top -5px
      padding 0
      z-index 99
      
    &__name
      padding-right 10px
      font-size 16px
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
      
    &__price
      font-size 16px
      font-weight bold
      
      span
        font-size 14px
        display block
        color $witt-darked-grey
        font-weight normal
        
    &__wishlist
      position absolute
      right 10px
      top 10px
      width 34px
      height 34px
      border 1px solid $witt-very-light-grey
      background-color rgba(255,255,255, 0.9)
      border-radius 50%
      background-image url(../img/app/rwd/icon-add-wishlist.svg)
      background-repeat no-repeat
      background-position center center
      background-size 60% auto
      cursor pointer
      z-index 2
      
      &.active
        background-image url(../img/app/rwd/icon-add-wishlist-active.svg)
        
  &__similar-items
    margin 0

    &__content
      margin-bottom 40px

    @media screen and (max-width: $xlarge)
      padding 0 20px

    @media screen and (max-width: $medium)
      padding 0

    h2
      font-size 28px
      text-align center
      font-weight 700
      margin-bottom 30px

    .slick-slide
      position relative
      margin 0 5px

      img
        margin 0 auto
        max-width 100%

    .slick-prev
      background-color white
      position absolute
      left 0
      margin-left -30px
      top 0
      height 100%
      width 30px
      padding 0
      z-index 99
      cursor pointer
      border none
      outline none

      &.slick-disabled
        display none !important

      &:before
        content ''
        display block
        position absolute
        margin-left -3px
        top 50%
        margin-top -15px
        width 30px
        height 30px
        background-color white
          url(../img/app/rwd/slider-arrow-left.svg) !important;
        background-repeat no-repeat
        background-position center center
        background-size 26px auto

    .slick-next
      background-color white
      position absolute
      right 0
      top 0
      height 100%
      width 30px
      margin-right -30px
      padding 0
      z-index 99
      cursor pointer
      border none
      outline none

      &.slick-disabled
        display none !important

      &:before
        content ''
        display block
        position absolute
        margin-right -3px
        top 50%
        margin-top -15px
        width 30px
        height 30px
        background-color white
        background-image  url(../img/app/rwd/slider-arrow-right.svg) !important;
        background-repeat no-repeat
        background-position center center
        background-size 26px auto

    &__item
      position relative
      text-align center

    &__picture
      min-height 366px
      display flex
      position relative
      text-align center
      justify-content center
      width 100%

      @media screen and (max-width: $large)
        min-height 248px

      @media screen and (max-width: $medium)
        min-height 233px

    &__colors
      height 16px
      position absolute
      bottom 0
      background-color $witt-white
      padding 1px 3px

      &__count
        vertical-align top
        margin-top -1px
        margin-left 3px
        display none

        &:last-child
          display inline-block

    &__color
      display inline-block
      vertical-align top
      margin 0 2px
      cursor pointer
      &:focus
        outline none

      span
        display inline-block
        width 11px
        height 11px
        border-radius 50%

    &__stickers
      display flex
      position absolute
      top 10px
      left 10px

    &__wishlist
      position absolute
      right 10px
      top 10px
      width 32px
      height 32px
      background-image url(../img/app/rwd/icon-add-wishlist.svg)
      background-repeat no-repeat
      background-position center center
      background-size 100% auto
      cursor pointer

      &.active, &:hover
        @media screen and (min-width: $medium)
          background-image url(../img/app/rwd/icon-add-wishlist-active.svg)

    &__name
      margin-top 2px
      font-size 16px
      width: 100%
      text-overflow ellipsis
      white-space nowrap
      overflow hidden

    &__brand
      margin-bottom 3px
      margin-top -5px
      font-size 14px

    &__prices
      display flex
      align-items center
      justify-content center

    &__oldprice
      font-size 14px
      text-decoration line-through
      margin-right 10px

    &__price
      font-size 14px

      &--highlight
        font-size 16px
        font-weight bold
        color $witt-red

  &__back-button
    margin-top 20px

  &__reassurance
    margin-top 15px
    display flex

    @media M_and_up
      order -1

    @media up_to_M
      margin-top 0
      padding-bottom 16px

    &__seperator
      border-top 1px solid $highlight-border
      width 200px
      padding 3px

    &__icon
      width 32px
      height 25px
      background-position 7px 1px
      background-repeat no-repeat

      &__payment
        background-image url(../img/app/rwd/advantage-payment.png)
        background-size 15px auto

      &__happiness
        background-image url(../img/app/rwd/advantage-happiness.png)
        background-size 20px auto

      &__delivery
        background-image url(../img/app/rwd/advantage-delivery.png)
        background-size 20px auto

    &__trusted
      background-size 50px auto
      background-repeat no-repeat
      background-image url(../img/app/rwd/trusted_shop.png)
      height 50px
      width 50px
      margin-top 10px

  &__wishlist-add
    margin-left 16px
    padding 8px 12px
    position relative

    &:not(.active)

      &[data-text-add]
        @media screen and (min-width: $medium)
          &:hover
            &:after
              content attr(data-text-add)
              position absolute
              left 0
              border 1px solid $witt-black
              top 50px
              padding 6px 8px
              font-size 12px
              line-height 14px
              display inline-block
              z-index 999
              background-color $witt-white
              margin-left -10px
              margin-right -10px

    .icon__wishlist
      margin 0

    button
      .remove
        display none

    &.active
      .icon__wishlist
        background-image url(/img/app/rwd/icon-add-wishlist-active.svg)

      &[data-text-remove]
        @media screen and (min-width: $medium)
          &:hover
            &:after
              content attr(data-text-remove)
              position absolute
              left 0
              border 1px solid $witt-black
              top 50px
              padding 6px 8px
              font-size 12px
              line-height 14px
              display inline-block
              z-index 999
              background-color $witt-white
              margin-left -10px
              margin-right -10px

  &__installment
    margin-top 20px

  #customer-reviews-container
    @media screen and (min-width: $medium)
      margin 0 15%
      cursor pointer
    @media screen and (min-width: $large)
      margin 0

    .product__short-reviews
      display none

  &__customer-reviews
    margin-top 40px
    margin-bottom 40px

    @media screen and (max-width: $medium)
      margin-top 20px
      margin-bottom 20px

    &.hide
      display none

    &__inner
      margin-bottom 60px
      @media screen and (max-width: $medium)
        margin-bottom 20px


    #netreviews_reviews_tab
      font-family unset

    .select-wrapper
      .caret
        display none

    .select-dropdown
      display none
