@require '/includes/*'

.noUi-target,
.noUi-target *
  -webkit-touch-callout: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -webkit-user-select: none
  -ms-touch-action: none
  touch-action: none
  -ms-user-select: none
  -moz-user-select: none
  user-select: none
  -moz-box-sizing: border-box
  box-sizing: border-box

.noUi-target
  position: relative
  margin: 0 40px

  @media up_to_M
    margin 0
    justify-self center

.noUi-base,
.noUi-connects
  width: 100%
  height: 100%
  position: relative
  z-index: 1

.noUi-connects
  overflow: hidden
  z-index: 0

.noUi-connect,
.noUi-origin
  will-change: transform
  position: absolute
  z-index: 1
  top: 0
  right: 0
  height: 100%
  width: 100%
  -ms-transform-origin: 0 0
  -webkit-transform-origin: 0 0
  -webkit-transform-style: preserve-3d
  transform-origin: 0 0
  transform-style: flat

  @media up_to_M
    right 5px

.noUi-horizontal .noUi-origin
  height: 0

.noUi-touch-area
  height: 100%
  width: 100%

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin
  -webkit-transition: transform 0.3s
  transition: transform 0.3s

.noUi-state-drag *
  cursor: inherit !important

.noUi-horizontal
  height: 4px

.noUi-horizontal .noUi-handle
  width: 24px
  height: 24px
  right: -17px
  top: -10px

.noUi-target
  background: #d9dadb //$witt-light-grey3
  border-radius: 4px

.noUi-connects
  border-radius: 3px

.noUi-connect
  background: #000

.noUi-handle
  border-radius: 30px
  background: #000
  -webkit-backface-visibility hidden
  backface-visibility: hidden
  position: absolute
  cursor pointer

.noUi-handle:after
  left: 17px

