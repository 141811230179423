@require 'includes/*'

.pswp
  &__top-bar
    background-color $witt-white !important
    background-image url(/img/app/rwd/logo-inverted.svg)
    background-size contain
    background-repeat no-repeat
    
  &__img
    &--placeholder
      &--blank
        background-color $witt-white !important
        
  &__button
    &--arrow
      &--left
        opacity 1 !important
        background-image  url(../img/app/rwd/slider-arrow-left.svg) !important;
        background-position center center!important
        background-repeat no-repeat!important
        background-size contain!important
        &:before
          background-color transparent !important
      &--right
        opacity 1 !important
        background-image  url(../img/app/rwd/slider-arrow-right.svg) !important;
        background-position center center!important
        background-repeat no-repeat!important
        background-size contain!important
        &:before
          background-color transparent !important
    &--close
      background-image url(../img/app/rwd/icon-close-big.png) !important
      background-position center center
      background-size 60% auto
          
  .icon-arrow-right
    display block
    position absolute
    left 0
    top 50%
    sprite( $icon_arrow_right )
    
  .icon-arrow-left
    display block
    position absolute
    right 0
    top 50%
    sprite( $icon_arrow_left )
    
  &__button, &__button--arrow--left:before, &__button--arrow--right:before
    background-image none
